import QedaAxis from './qeda-axis'
import { QedaStage } from './qeda-stage'
import { QedaGraphicsFactory, QedaGraphic, QedaGraphicFillType, QedaLibrary, QedaCell, QedaReference, getLabelBox, getSlope } from './qeda-graphics'
import { QedaGraphicType, sliceLines } from './qeda-graphics'
import { get45rotatePoint, isAdsorb } from '../pixi/qeda-pixi-data'
import bus from '@/components/common/bus'
import store from '@/store/index'
import { Message } from 'element-ui'
import {
  saveCellFile_api, //保存文件
  getCellFile_api, //获取文件
  lockCell_api, //锁文件
} from '@/api/file/file.js'
import { getPDKInfo_api } from '@/api/objectStorage/objectStorage.js'
import { getObjectDataFn } from '@/components/homes/fileList/fileUtils.js'
import { messages } from '../../common/lang/zh'
import { Circle } from 'pixi.js'
import { deepClone } from '../utils'
import { decompressPropStr } from '../../utils/getcell'
import i18n from '../../common/lang/i18n.js'
import router from '@/router'
window.QedaGraphics = new QedaGraphicsFactory()

const directionMap = {
  中心: 'o',
  上: 'n',
  下: 's',
  左: 'w',
  右: 'e',
  左上: 'nw',
  右上: 'ne',
  左下: 'sw',
  右下: 'se',
}
const AutoTool = require('../tools/index')

const urlAlphabet = 'useandom-26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict'
let QEDA_ID = 0
export class QedaLayout {
  constructor(fileInfo, cellInfo) {
    this.autotool = new AutoTool()

    this.fileInfo = fileInfo
    this.cellInfo = cellInfo
    this.file_snow_id = fileInfo.fileId
    //容器
    this.containerId = fileInfo.fileId + cellInfo.cellId
    //画布
    this.canvasId = this.containerId + '_canvas'
    //坐标系统
    this.axis = new QedaAxis(800, 800, 1.0e-6, 1.0e-9)
    //舞台
    this.stage = null
    this.stageRenderCompleted = false
    //绘图类型
    this.drawType = 'selectBox' //store.state.func
    //正在绘制的对象
    this.drawingObject = null
    this.drawPoints = [] //鼠标起始点和终点 [start,end]
    //鼠标左右键长按
    this.leftPress = false
    this.rightPress = false
    //被复制数据
    this.copyData = null
    //被复制数据中心坐标
    this.copyDataCenter = null
    this.pre_copy_data = null
    //标签关键点连续绘制数据
    this.copyLabelData = null
    //文本图形连续绘制数据
    this.copyCharacterData = null
    //剪切
    this.isCut = false
    this.gdsFile = null
    this.cellLayers = [] //cell 图层
    this.cellHistory = [] //编辑cell 顺序
    this.preAddObj = null
    this.last_mouse_pos = null
    this.press_shift = false
    this.lockFileTimer
    this.active_status = true
    setTimeout(() => {
      this.canvas = document.getElementById(this.canvasId)
      this.initStage()
      this.mouseMoveListener = this.mouseMove.bind(this)
      this.mouseDownListener = this.mouseDown.bind(this)
      this.mouseUpListener = this.mouseUp.bind(this)
      this.mouseWheelListener = this.onMouseWheel.bind(this)
      this.keyBoardListener = this.keyDown.bind(this)
      this.keyUpBoardListener = this.keyUp.bind(this)
      this.addEventListener()
      // this.stage.check_able = false
    })
  }

  //激活画板
  active() {
    this.active_status = true
    setTimeout(() => {
      this.drawType = 'selectBox' //store.state.func
      this.stage.check_able = this.drawType === 'selectBox'
      if (!this.stage.cell) {
        this.getCell()
      }
      // this.stage.redraw()
      // this.stage.render()
      window.STAGE = this.stage
      window.AXIS = this.stage.myaxis
      this.addEventListener()
      this.stage.addEventListener()
      this.stage.setPaints()
      this.syncLayerList()
      this.setToolState()
      //更新cell盒子
      Object.values(this.stage.cells).forEach(cell => {
        cell.bounding_box()
      })
      this.stage.cell.references.forEach(ref => this.stage.updateCellRefBox(ref))
      this.stage.render()
    })
  }

  //待命
  inActive() {
    if (!this.active_status) return //已经禁用
    this.active_status = false
    window.clearInterval(this.lockFileTimer)
    this.lockFileTimer = null
    let str = this.fileInfo.fileId + '/' + this.cellInfo.cellId
    bus.$emit('updateFileTree', str)
    this.removeEventListeners()
    this.stage.removeEventListeners()
  }

  delete() {
    bus.$off('resetName')
    window.clearInterval(this.lockFileTimer)
    this.lockFileTimer = null
    this.inActive()
    // this.canvas = null
    // this.stage.canvas = null
    // this.stage.surface = null
    // this.stage = null
    this.stage.editHistory.clear()
  }

  //添加监听
  addEventListener() {
    this.resizeLayer()
    bus.$on('initLayerList', () => {
      this.skiaLoad()

      if (!this.stage.qedaLib) {
        return
      }
      if (!this.stage.cell) {
        this.stage.cell = this.stage.qedaLib.cells[this.cellInfo.name]
      }
      this.syncLayerList()
    })
    //页面缩放
    bus.$on('resizeLayer', data => {
      this.resizeLayer()
    })

    if (this.fileInfo?.teamRule === '只读') {
      return
    } else {
      // alert(123)
      if (!this.lockFileTimer) {
        let that = this
        this.lockFileTimer = setInterval(() => {
          that.lockFile(that.fileInfo)
        }, 60000) //每60秒一次
        this.lockFile(this.fileInfo)
      }
    }
    this.canvas.addEventListener('mousewheel', this.mouseWheelListener)
    this.canvas.addEventListener('mousemove', this.mouseMoveListener)
    this.canvas.addEventListener('mouseup', this.mouseUpListener)
    this.canvas.addEventListener('mousedown', this.mouseDownListener)
    document.addEventListener('keydown', this.keyBoardListener)
    document.addEventListener('keyup', this.keyUpBoardListener)
    //订阅外部事件
    //切换工具
    bus.$on('func', data => {
      if (this.stage.viewMode) return

      this.switchDrawFunc(data)
    })

    //复制
    bus.$on('save', data => {
      if (this.stage.viewMode) return
      this.save()
    })
    //选择新图层
    bus.$on('changeLayerNum', data => {
      this.stage.currentLayer = data
      Object.values(this.stage.qedaLib.cells).forEach(cell => {
        cell.showHideCell(this.stage.layerDatas)
      })
    })
    //图层数据修改
    bus.$on('layerChange', data => {
      this.stage.setLayerPaint(data)
      this.generateFillImg(data.layerNumber)
      this.stage.currentLayer = data
      this.showHideLayer()
      this.fileChange()
    })
    //图层编号修改
    bus.$on('changeLayerNumber', data => {
      this.stage.currentLayer = data.layerData
      this.stage.setPaints()
      this.stage.qedaLib.changeLayerNumber([data.oldNumber], data.newNumber)
    })
    //新建
    bus.$on('newLayer', data => {
      this.stage.setPaints()
      this.stage.setLayerPaint(data)
      this.generateFillImg(data.layerNumber)
      this.fileChange()
    })
    //隐藏
    bus.$on('hideClick', data => {
      this.stage.currentLayer = data
      this.showHideLayer()
      this.lockLayer()
      this.fileChange()
    })
    //锁定
    bus.$on('lockClick', data => {
      this.stage.currentLayer = data
      this.lockLayer()
      this.fileChange()
    })
    //隐藏全部
    bus.$on('allHide', data => {
      this.hideAllLayer(data)
      // this.lockAllLayer(data)
      this.fileChange()
    })
    //锁定全部
    bus.$on('allLock', data => {
      this.lockAllLayer(data)
      this.fileChange()
    })
    //删除图层
    bus.$on('delLayer', data => {
      this.deleteLayerData(data)
      this.updateCellRefBox(this.stage.cell)
      this.stage.setPaints()
      this.stage.render()
      let layerDatas = store.state.layerDatas.filter(item => this.fileInfo.fileId === item.file_snow_id)[0].layerDatas
      if (!layerDatas?.length) {
        this.stage.currentLayer = null
        return
      }
      this.generateFillImg()
      this.fileChange()
    })
    //合并图层
    bus.$on('mergeLayer', data => {
      this.mergeLayers(data[0], data[1])

      this.stage.setPaints()
      // this.generateFillImg()
      this.stage.render()
      this.fileChange()
    })
    //批量修改图层
    bus.$on('batchChangeLayers', data => {
      //data --> {targets, layerNum} targets修改对象 layerNum修改到的图层
      this.batchChangeLayers(data.targets, data.layerNum)
    })

    //整体选择
    bus.$on('overallSelection', data => {
      if (this.stage.viewMode) return
      if (this.stage.checkMode === 1) {
        this.stage.checked_objs.forEach(obj => {
          obj.switchOverallSelection()
        })
      }
      this.switchDrawFunc('selectBox')
      this.stage.checkMode = 0
      this.stage.resetStatus()
      this.stage.render()
    })
    //部分选择
    bus.$on('partialSelection', data => {
      if (this.stage.viewMode) return
      if (this.stage.checkMode === 0) {
        this.stage.checked_objs.forEach(obj => {
          obj.switchPartialSelection()
        })
      }
      this.switchDrawFunc('selectBox')
      this.stage.checkMode = 1
      this.stage.resetStatus()
      this.stage.render()
    })
    //剪切
    bus.$on('cut', data => {
      if (this.stage.viewMode) return
      if (!this.stage.check_able) return

      this.copyData = this.stage.checked_objs
      this.copyDataCenter = this.stage.checkedGraphicsAABB_center
      this.isCut = true
    })
    //复制
    bus.$on('copy', data => {
      if (this.stage.viewMode) return
      if (!this.stage.check_able) return
      this.copyData = this.stage.checked_objs
      this.copyDataCenter = this.stage.checkedGraphicsAABB_center
      this.isCut = false
    })
    //粘贴
    bus.$on('paste', data => {
      if (this.stage.viewMode) return
      if (!this.stage.check_able) return
      this.pasteData()
    })
    //撤销
    bus.$on('undo', data => {
      if (this.stage.viewMode) return
      this.undo()
    })
    //恢复
    bus.$on('redo', data => {
      if (this.stage.viewMode) return
      this.redo()
    })
    //移动
    bus.$on('move', data => {
      if (this.stage.viewMode) return
      if (this.stage.checkMode === 0) {
        if (this.stage.checked_objs_count) {
          this.stage.movePosition = []
          this.stage.moveMod = 1
        }
      } else {
        // bus.$emit('cancelSidebarSelect')
      }

      this.switchDrawFunc('selectBox')
    })
    //旋转
    bus.$on('rotate', data => {
      if (this.stage.viewMode) return
      if (this.stage.checked_objs_count) {
        this.rotateObjs()
      }
    })
    //拉伸
    bus.$on('stretch', data => {
      if (this.stage.viewMode) return
      if (this.stage.checkMode === 1) {
        if (this.stage.checked_objs_count) {
          this.stage.movePosition = []
          this.stage.moveMod = 1
        }
      } else {
        // bus.$emit('cancelSidebarSelect')
      }

      this.switchDrawFunc('selectBox')
    })
    //水平翻转
    bus.$on('horizontalFlip', data => {
      if (this.stage.viewMode) return
      this.switchDrawFunc('selectBox')

      if (this.stage.checkMode !== 0) return //整体选择模式才能翻转
      this.flipX(parseFloat(data))
    })
    //垂直翻转
    bus.$on('verticalFlip', data => {
      if (this.stage.viewMode) return
      this.switchDrawFunc('selectBox')
      if (this.stage.checkMode !== 0) return //整体选择模式才能翻转
      this.flipY(parseFloat(data))
    })
    //点对齐
    bus.$on('pointAlignment', data => {
      if (this.stage.viewMode) return
      if (this.stage.editReference) return
      if (!this.stage.checked_objs_count) {
        //整体 部分选择必须先有物体选择
        return
      }
      const offset = data.map(d => parseFloat(d))
      this.stage.alignmentOffset = offset

      if (this.stage.checkMode !== 8) {
        this.stage.checkMode_bk = this.stage.checkMode
        this.stage.checkMode = 8
      }

      return
    })
    //线对齐
    bus.$on('lineAlignment', data => {
      if (this.stage.viewMode) return
      if (this.stage.editReference) return
      if (!this.stage.checked_objs_count) {
        //整体 部分选择必须先有物体选择
        return
      }
      const offset = data.map(d => parseFloat(d))
      this.stage.alignmentOffset = offset

      if (this.stage.checkMode !== 9) {
        this.stage.checkMode_bk = this.stage.checkMode
        this.stage.checkMode = 9
      }

      return
    })

    //阵列
    bus.$on('matrix', data => {
      if (this.stage.viewMode) return
      this.arrayObjs(data)
    })
    //对齐
    bus.$on('alignment', data => {
      if (this.stage.viewMode) return

      if (this.stage.checkMode === 0) this.alignmentObjs(data)
    })
    //切割
    bus.$on('cutting', data => {
      if (this.stage.viewMode) return
      this.cutting()
    })
    //区域切除
    bus.$on('areaExcision', data => {
      if (this.stage.viewMode) return
      this.areaExcision()
    })
    //合并
    bus.$on('merge', data => {
      if (this.stage.viewMode) return
      this.boolCheckedObjs()
    })
    //布尔运算
    bus.$on('boolean', data => {
      if (this.stage.viewMode) return
      this.boolCheckedObjs(data)
    })
    //进入下层
    bus.$on('enterlowerLevel', data => {
      if (this.stage.viewMode) return
      if (this.stage.checked_objs_count === 1 && this.stage.checked_objs[0]?.reference) {
        this.stage.checked_objs[0].resetStatus()
        let history = {
          dx: this.stage.myaxis.dx,
          dy: this.stage.myaxis.dy,
          scale: this.stage.myaxis.scale,
          cellName: this.stage.cell.name,
        }
        this.cellHistory.push(history) //添加记录
        this.stage.cell = this.stage.cells[this.stage.checked_objs[0].reference.cell.name]
        this.stage.setCurrentEditCell()
        this.stage.checked_objs = []
        let adaptAABB = this.stage.cell.bounding_box()
        this.stage.pre_checked_obj = null
        if (adaptAABB) {
          //自适应渲染
          this.renderCell()
        } else {
          //默认缩放平移到原点
          this.stage.myaxis.setCenter()
          this.stage.updateAABB()
          this.stage.setPaints()
          this.stage.render()
        }
      }
    })
    //编辑下层
    bus.$on('editLowerLevel', data => {
      if (this.stage.viewMode) return
      if (this.stage.checked_objs_count === 1 && this.stage.checked_objs[0]?.reference && !this.stage.editReference) {
        this.stage.checked_objs[0].resetStatus()
        this.stage.editReference = this.stage.checked_objs[0].reference
        this.stage.setCurrentEditCell()
        this.stage.checked_objs = []
        this.stage.render()
      }
    })
    //返回上层
    bus.$on('backUpperLevel', data => {
      if (this.stage.viewMode) return
      if (this.stage.editReference) {
        this.stage.editReference = null
        this.stage.setCurrentEditCell()
        this.updateCellRefBox(this.stage.cell)
        this.stage.pre_checked_obj = null
        this.stage.render()
        return
      }
      if (this.cellHistory.length) {
        let history = this.cellHistory.pop() //读取记录
        this.stage.resetCellStatus()
        this.stage.cell = this.stage.cells[history.cellName]
        this.stage.setCurrentEditCell()
        this.updateCellRefBox(this.stage.cell)
        this.stage.myaxis.scale = history.scale
        this.stage.myaxis.dx = history.dx
        this.stage.myaxis.dy = history.dy
        this.stage.myaxis.render()
        this.stage.checked_objs = []
        this.stage.setPaints()
        this.stage.drawRulerX()
        this.stage.drawRulerY()
        this.stage.updateAABB()
        this.stage.pre_checked_obj = null
        this.stage.render()
      }
    })

    //返回最上层
    bus.$on('backTop', data => {
      if (this.stage.viewMode) return
      this.stage.editReference = null
      if (this.cellHistory.length) {
        let history = this.cellHistory[0] //读取第一条记录
        this.stage.resetCellStatus()
        this.stage.cell = this.stage.cells[history.cellName]
        this.cellHistory.forEach(history => this.updateCellRefBox(this.stage.cells[history.cellName]))
        this.updateCellRefBox()
        this.stage.cell = this.stage.cell
        this.stage.setCurrentEditCell()
        this.stage.myaxis.scale = history.scale
        this.stage.myaxis.dx = history.dx
        this.stage.myaxis.dy = history.dy
        this.cellHistory = []
        this.stage.myaxis.render()
        this.stage.checked_objs = []
        this.stage.setPaints()
        this.stage.drawRulerX()
        this.stage.drawRulerY()
        this.stage.updateAABB()
        this.stage.pre_checked_obj = null
        this.stage.render()
      }
    })

    //另存为单元
    bus.$on('saveAsCell', data => {
      if (this.stage.viewMode) return
      if (this.stage.checked_objs.length) {
        let center = this.stage.checkedGraphicsAABB_center
        let objs = this.stage.checked_objs
        let len = objs.length
        let name = data[1]
        // let newCell = new QGdstk.Cell(name)
        let newCell = new QedaCell(name)
        for (let j = 0; j < len; j++) {
          const obj = objs[j]
          const p_new = [obj.position[0] - center[0], obj.position[1] - center[1]]
          const copy = QedaGraphics.copy(objs[j], p_new)
          newCell.add(copy)
          //debug
          // copy.checked = true
          // this.addGraphic(copy)
          // this.stage.render()
        }
        let cells = newCell.QCELL.dependencies(true)
        let Qlib = new QGdstk.Library(name, 0.000001, 1e-9)
        Qlib.add(newCell.QCELL)
        Qlib.add(cells)

        bus.$emit('getBoxData', Qlib)
      } else {
        bus.$emit('getBoxData', null)
      }
    })

    //打撒
    bus.$on('breakUp', data => {
      if (this.stage.viewMode) return

      let depth = data[0] === '2' //打散所有
      let keep_params_device = data[1] === '1' //保留参数化器件
      let refBoxs = this.stage.checked_objs.filter(obj => obj.isRefBox)
      let ref_len = refBoxs.length
      if (!ref_len) return
      let breakResult = [] //打散后的图形
      let record1 = []
      let record2 = []
      for (let i = 0; i < ref_len; i++) {
        //判断参数化器件
        let is_parametric_device = refBoxs[i].reference.cell.QCELL.labels.filter(lable => {
          let params = lable.get_gds_property(2)
          if (params) {
            let label_attr = decompressPropStr(params)
            return label_attr.is_params_device
          }
          return false
        }).length
        if (keep_params_device && is_parametric_device) {
          continue
        }
        record1.push(refBoxs[i])
        this.deleteGraphic(refBoxs[i])
        let polygons = refBoxs[i].reference.getPolygons(depth, keep_params_device)

        breakResult.push(...polygons)
      }

      let break_len = breakResult.length
      for (let i = 0; i < break_len; i++) {
        record2.push(breakResult[i])
        this.addGraphic(breakResult[i])
      }
      this.updateFile()
      let action1 = { action: 'delete', cellName: this.stage.getCurrentCellName(), objs: record1 }
      let action2 = { action: 'add', cellName: this.stage.getCurrentCellName(), objs: record2 }
      this.stage.editHistory.record([action1, action2])
      this.stage.render()
    })

    // 按图层拆分版图
    bus.$on('TOOL_SPLIT_CELL_RUN', data => {
      if (this.stage.viewMode) return

      this.autotool.load(this.stage.qedaLib.QLIB, this.stage.cell.QCELL)
      this.autotool
        .gdsSplit(data)
        .then(res => {
          // Message.success('版图拆分成功~')
          bus.$emit('splitCell', res)
        })
        .catch(err => {
          Message.error(`${i18n.t('messages.layoutSplitSuccess')}:${err}~`)
        })
    })

    // 共面波导
    bus.$on('TOOL_WAVEGUIDE_RUN', data => {
      if (this.stage.viewMode) return
      this.autotool.load(this.stage.qedaLib.QLIB, this.stage.cell.QCELL)
      this.autotool
        .waveGuide(data)
        .then(res => {
          let addObjs = this.stage.cell.addGdsData(res.add_polygons)
          let deleteObjs = this.stage.cell.removeGdsData(res.remove_paths)
          let action1 = { action: 'add', cellName: this.stage.cell.name, objs: addObjs }
          let action2 = { action: 'delete', cellName: this.stage.cell.name, objs: deleteObjs }
          this.stage.editHistory.record([action1, action2])
          this.compareFileLayers(this.stage.qedaLib.QLIB.cells)
          this.stage.render()
          Message.success(`${i18n.t('messages.cwGeneratedSuccess')}`)
        })
        .catch(err => {
          Message.error(`${i18n.t('messages.cwdGeneratedFaile')}:${err}~`)
        })
    })
    // WBPad
    bus.$on('TOOL_WBPAD_RUN', data => {
      if (this.stage.viewMode) return

      this.autotool.load(this.stage.qedaLib.QLIB, this.stage.cell.QCELL)
      this.autotool.wbpad(data).then(res => {
        for (let i = 0; i < res.add_texts.length; i++) {
          let text = res.add_texts[i]
          let cell = this.drawCharacter(false, `${text.text}`, text.layer, text.anchor, text.fontSize, text.rotation)
          let ref = new QGdstk.Reference(cell, text.origin, (text.rotation * Math.PI) / 180, 1, false, 1, 1, null)
          res.add_cells.push(cell)
          res.add_references.push(ref)
        }
        res.add_cells.forEach(cell => (cell.name = this.generateUniqueCellName(cell.name)))
        this.compareFileLayers(res.add_cells)
        bus.$emit(
          'updateFileTree',
          res.add_cells.map(cell => cell.name)
        )
        let deleteObjs = this.stage.cell.removeGdsData(res.remove_paths)
        let addObjs = this.stage.cell.addGdsData(res.add_paths)
        let refboxs = this.stage.qedaLib.addReferencesToCell(this.stage.cell.name, res.add_cells, res.add_references)
        let action1 = { action: 'addCell', objs: [...res.add_cells] }
        let action3 = { action: 'delete', cellName: this.stage.cell.name, objs: deleteObjs }
        let action2 = { action: 'add', cellName: this.stage.cell.name, objs: [...refboxs, ...addObjs] }
        this.stage.editHistory.record([action1, action2, action3])
        this.stage.render()
        bus.$emit('TOOL_WBPAD_LOG', { numbers: res.add_references.length })
        Message.success(`${i18n.t('messages.wbPadPlacedSuccess')}`)
      })
      // .catch(err => {
      //   Message.error(`${i18n.t('messages.wbPadPlacedFaile')}:${err}~`)
      // })
    })
    // InLinePad
    bus.$on('TOOL_INLINEPAD_RUN', data => {
      if (this.stage.viewMode) return
      this.autotool.load(this.stage.qedaLib.QLIB, this.stage.cell.QCELL)
      this.autotool
        .inlinepad(data)
        .then(res => {
          res.add_cells.forEach(cell => (cell.name = this.generateUniqueCellName(cell.name)))
          this.compareFileLayers(res.add_cells)
          bus.$emit(
            'updateFileTree',
            res.add_cells.map(cell => cell.name)
          )
          let deleteObjs = this.stage.cell.removeGdsData(res.remove_paths)
          let addObjs = this.stage.cell.addGdsData(res.add_paths)
          let refboxs = this.stage.qedaLib.addReferencesToCell(this.stage.cell.name, res.add_cells, res.add_references)
          let action1 = { action: 'addCell', objs: [...res.add_cells] }
          let action2 = { action: 'delete', cellName: this.stage.cell.name, objs: deleteObjs }
          let action3 = { action: 'add', cellName: this.stage.cell.name, objs: [...refboxs, ...addObjs] }
          this.stage.editHistory.record([action1, action2, action3])
          this.stage.render()
          bus.$emit('TOOL_INLINEPAD_LOG', { numbers: res.add_references.length })
          Message.success(`${i18n.t('messages.inLinePadPlacedSuccess')}`)
        })
        .catch(err => {
          Message.error(`${i18n.t('messages.inLinePadPlacedFaile')}:${err}~`)
        })
    })
    // AirBridge
    bus.$on('TOOL_AIRBRIDGE_RUN', data => {
      if (this.stage.viewMode) return
      this.autotool.load(this.stage.qedaLib.QLIB, this.stage.cell.QCELL)
      this.autotool
        .airBridge(data)
        .then(res => {
          res.add_cells.forEach(cell => (cell.name = this.generateUniqueCellName(cell.name)))
          this.compareFileLayers(res.add_cells)
          bus.$emit(
            'updateFileTree',
            res.add_cells.map(cell => cell.name)
          )
          let refboxs = this.stage.qedaLib.addReferencesToCell(this.stage.cell.name, res.add_cells, res.add_references)
          let action1 = { action: 'addCell', objs: [...res.add_cells] }
          let action2 = { action: 'add', cellName: this.stage.cell.name, objs: [...refboxs] }
          this.stage.editHistory.record([action1, action2])
          this.stage.render()
          bus.$emit('TOOL_AIRBRIDGE_LOG', { numbers: res.add_references.length })
          Message.success(`${i18n.t('messages.airBridgePlacedSuccess')}`)
        })
        .catch(err => {
          Message.error(`${i18n.t('messages.airBridgePlacedFaile')}:${err}~`)
        })
    })
    // TiNPad&InPillar
    bus.$on('TOOL_TINPAD_INPILLAR_RUN', data => {
      if (this.stage.viewMode) return
      this.autotool.load(this.stage.qedaLib.QLIB, this.stage.cell.QCELL)
      this.autotool
        .tinpad(data)
        .then(res => {
          res.add_cells.forEach(cell => (cell.name = this.generateUniqueCellName(cell.name)))
          this.compareFileLayers(res.add_cells)
          bus.$emit(
            'updateFileTree',
            res.add_cells.map(cell => cell.name)
          )
          let refboxs = this.stage.qedaLib.addReferencesToCell(this.stage.cell.name, res.add_cells, res.add_references)
          let action1 = { action: 'addCell', objs: [...res.add_cells] }
          let action2 = { action: 'add', cellName: this.stage.cell.name, objs: [...refboxs] }
          this.stage.editHistory.record([action1, action2])
          this.stage.render()
          bus.$emit('TOOL_TINPAD_INPILLAR_LOG', res.log)
          Message.success(`${i18n.t('messages.tpPlacedSuccess')}`)
        })
        .catch(err => {
          Message.error(`${i18n.t('messages.tpPlacedFaile')}:${err}~`)
        })
    })

    // Squid Zline
    bus.$on('TOOL_SQUID_ZLINE_RUN', data => {
      if (this.stage.viewMode) return

      let res = data
      if (res) {
        res.add_cells.forEach(cell => (cell.name = this.generateUniqueCellName(cell.name)))
        this.compareFileLayers(res.add_cells)
        bus.$emit(
          'updateFileTree',
          res.add_cells.map(cell => cell.name)
        )
        let refboxs = this.stage.qedaLib.addReferencesToCell(this.stage.cell.name, res.add_cells, res.add_references)
        let action1 = { action: 'addCell', objs: [...res.add_cells] }
        let action2 = { action: 'add', cellName: this.stage.cell.name, objs: [...refboxs] }
        this.stage.editHistory.record([action1, action2])

        this.stage.render()
      }
    })

    // Squid Zline
    bus.$on('TOOL_CAVITY_RUN', data => {
      if (this.stage.viewMode) return

      let res = data
      if (res) {
        res.add_cells.forEach(cell => (cell.name = this.generateUniqueCellName(cell.name)))
        this.compareFileLayers(res.add_cells)
        bus.$emit(
          'updateFileTree',
          res.add_cells.map(cell => cell.name)
        )
        let refboxs = this.stage.qedaLib.addReferencesToCell(this.stage.cell.name, res.add_cells, res.add_references)
        let action1 = { action: 'addCell', objs: [...res.add_cells] }
        let action2 = { action: 'add', cellName: this.stage.cell.name, objs: [...refboxs] }
        this.stage.editHistory.record([action1, action2])

        this.stage.render()
      }
    })

    // Check Line
    bus.$on('TOOL_CHECK_LINE_RUN', data => {
      if (this.stage.viewMode) return

      this.autotool.load(this.stage.qedaLib.QLIB, this.stage.cell.QCELL)
      this.autotool.checkLine(data).then(res => {
        bus.$emit('TOOL_CHECK_LINE_LOG', res)
        Message.success(`${i18n.t('messages.testLineSuccess')}`)
      })
      // .catch(err => {
      //   console.error(err)
      //   Message.error(`检测线失败:${err}~`)
      // })
    })

    // Connect Line
    bus.$on('TOOL_CONNECT_LINE_RUN', data => {
      if (this.stage.viewMode) return

      this.autotool.load(this.stage.qedaLib.QLIB, this.stage.cell.QCELL)
      this.autotool
        .connectLine(data)
        .then(res => {
          let deleteObjs = this.stage.cell.removeGdsData(res.remove_paths)
          let addObjs = this.stage.cell.addGdsData(res.add_paths)
          addObjs.forEach(obj => (obj.hide = false))
          let action1 = { action: 'add', cellName: this.stage.cell.name, objs: addObjs }
          let action2 = { action: 'delete', cellName: this.stage.cell.name, objs: deleteObjs }
          this.stage.editHistory.record([action1, action2])
          this.stage.render()
          Message.success(`${i18n.t('messages.theConnectionLineSuccess')}`)
        })
        .catch(err => {
          console.error(err)
          Message.error(`${i18n.t('messages.theConnectionLineFailed')}:${err}~`)
        })
    })

    // 添加器件
    bus.$on('addDevice', res => {
      if (this.stage.viewMode) return
      this.switchDrawFunc('selectBox')

      let data = res.Library
      let rotation = res.rotation
      //添加器件删除上一个器件
      this.deletePreAddCell()

      let targetCell = data.cells.filter(cell => cell.name === data.name)[0]
      let cells = data.cells
      let allCells = [targetCell, ...cells]
      //强制重命名
      allCells.forEach(cell => (cell.name = this.generateUniqueCellName(cell.name)))
      this.compareFileLayers(allCells)

      let refbox = this.stage.qedaLib.addDevice(targetCell, cells, rotation)
      // //判断cell名字重复进行重命名
      // // if(this.getCellNameSuffix(data.cells)){}
      // let names = []
      // for (let i = 0; i < cells.length; i++) {
      //   names.push(cells[i].name)
      //   this.stage.qedaLib.addExistCell(cells[i])
      // }
      // this.stage.qedaLib.updateCellsReferences(names)

      // const new_ref = new QedaReference()
      // new_ref.name = targetCell.name
      // new_ref.cell = this.stage.qedaLib.cells[targetCell.name]
      // new_ref.QREF = new QGdstk.Reference(targetCell, [0, 0], 0, 1, false, 1, 1, null)
      // let points = targetCell.bounding_box()
      // let rect = new QGdstk.rectangle(points[0], points[1], 0, 0)
      // let box = QedaGraphics.drawPolygon([[0, 0]])
      // new_ref.box = box
      // box.isRefBox = true
      // box.reference = new_ref
      // box.setRefBoxPoints(rect.get_points())
      // box.updateShape()
      // this.stage.cell.add(new_ref.box)
      // box.preAdd = true
      // this.stage.preAddCell = box
      this.stage.cell.add(refbox)
      this.stage.preAddCell = refbox
    })

    //固定器件更新
    bus.$on('update_static_cell', res => {
      if (this.stage.viewMode) return

      let new_position = res.position
      let new_rotation = res.rotation
      let ref = res.reference
      let refBox = ref.box
      let from = []
      let to = []
      from.push(refBox.copyTransform())
      to.push(null)
      let action1 = { action: 'transform', cellName: this.stage.cell.name, objs: [refBox], from: from, to: to }
      refBox.position = new_position
      refBox.rotation = new_rotation
      refBox.updateShape()

      this.stage.editHistory.record([action1])
      this.stage.render()
    })

    //参数化器件更新
    bus.$on('update_cell', res => {
      if (this.stage.viewMode) return

      let new_position = res.position
      let new_rotation = res.rotation
      let ref = res.reference
      let refBox = ref.box
      let data = res.Library
      let cellName = data.name
      let newCell = data.cells.filter(cell => cell.name === cellName)[0]
      let action1 = { action: 'transform', cellName: cellName, objs: [refBox], from: [refBox.copyTransform()], to: [null] }
      let updateCell = this.stage.qedaLib.cells[cellName]

      let deleteObjs = updateCell.clear()

      let addObjs = updateCell.addGdsData([...newCell.paths, ...newCell.polygons, ...newCell.labels])
      // refBox.position = new_pos
      // refBox.rotation = new_rotation
      ref.origin = new_position
      ref.rotation = new_rotation
      let points = updateCell.bounding_box()
      let rect = new QGdstk.rectangle(points[0], points[1], 0, 0)
      refBox.setRefBoxPoints(rect.get_points())
      // refBox.updateShape()

      let action2 = { action: 'delete', cellName: cellName, objs: deleteObjs }
      let action3 = { action: 'add', cellName: cellName, objs: addObjs }
      this.stage.editHistory.record([action1, action2, action3])
      this.stage.render()
    })
    // bus.$on('exportGDS', data => {

    // if (this.stage.viewMode) return
    // let lib = this.stage.qedaLib.QLIB

    // this.exportGds(lib, `${lib.name}.gds`)
    // })

    bus.$on('batchExportGDS', data => {
      if (this.stage.viewMode) return
      // let lib = this.stage.qedaLib.QLIB

      this.exportGds(data, `${data.name}.gds`)
    })

    //吸附
    bus.$on('adsorption', data => {
      this.stage.myaxis.adsorpPoint = data
    })

    //任意角度吸附
    bus.$on('anyAngle', data => {
      this.stage.myaxis.angledAdsorption = false
    })
    //斜角吸附
    bus.$on('angledAdsorption', data => {
      this.stage.myaxis.angledAdsorption = true
      // this.stage.myaxis.adsorp = true
    })

    //角度锁定
    bus.$on('lockStretch', data => {
      this.stage.isStretch = true
    })
    //任意拉伸
    bus.$on('anyStretch', data => {
      this.stage.isStretch = false
    })
    //画板放大
    bus.$on('boardAmplification', data => {
      let e = { deltaY: -1 }
      this.stage.onMouseWheel(e, true)
    })
    //画板缩小
    bus.$on('boardReduction', data => {
      let e = { deltaY: 1 }
      this.stage.onMouseWheel(e, true)
    })
    //画板自适应
    bus.$on('boardAdaptation', data => {
      let e = { key: 'f' }
      this.stage.onKeyDown(e)
    })
    //自适应
    bus.$on('objectAdaptation', data => {
      let e = { key: 'f' }
      this.stage.onKeyDown(e)
    })
    //区域放大
    bus.$on('regionalAmplification', data => {
      this.switchDrawFunc('adaptRect')
    })
    //绘制尺子
    bus.$on('ruler', data => {
      if (this.stage.viewMode) return
      this.switchDrawFunc('ruler')
    })
    //绘制标签
    bus.$on('labelInfo', data => {
      if (this.stage.viewMode) return

      this.switchDrawFunc('label')
      let layer = this.stage.currentLayer?.layerNumber
      let direction = directionMap[data[1]]
      let txt = data[2]
      let font_size = data[3]
      if (data.length == 6) {
        layer = this.getLayerNumByName(data[1])
        direction = directionMap[data[2]]
        txt = data[3]
        font_size = data[4]
      }
      // alert(layer)
      this.generateLabel(false, layer, txt, font_size, direction)
    })
    //关键点
    bus.$on('keyPointInfo', data => {
      if (this.stage.viewMode) return

      this.switchDrawFunc('keypoint')
      if (!this.stage.layerDatas?.length) return

      let layer = 0
      if (!this.stage.layerDatas?.filter(layer => layer.layerNumber === 0).length) {
        layer = this.stage.currentLayer.layerNumber
      }
      let txt = data[0]
      let createLabel = data[1]
      let font_size = data[2] ? data[2] : 30
      this.generateLabel(true, layer, txt, font_size, 's', createLabel)
    })
    //文本图形
    bus.$on('textGraphicsInfo', data => {
      if (this.stage.viewMode) return

      if (this.stage.editReference) return
      let txt = data[0]
      let layer = this.getLayerNumByName(data[1])
      let direction = data[2]
      let font_size = parseFloat(data[3])
      let rotation = data[4]
      if (font_size <= 0) {
        return
      }
      this.switchDrawFunc('character')
      let ref_box = this.drawCharacter(true, txt, layer, direction, font_size, rotation)
      this.copyCharacterData = QedaGraphics.copy(ref_box, ref_box.position)
    })
    //更新文本图形
    bus.$on('updateTextCell', data => {
      if (this.stage.viewMode) return

      let ref = data.reference
      let refBox = ref.box
      let cell_name = data.name
      let txt = data.text
      let direction = data.anchor
      let font_size = data.font_size
      let new_position = data.position
      let new_rotation = data.rotation
      let layer = data.layer
      // alert(refBox.copyTransform().position)
      // alert(refBox.copyTransform().rotation)
      let action1 = { action: 'transform', cellName: this.stage.getCurrentCellName(), objs: [refBox], from: [refBox.copyTransform()], to: [null] }
      // refBox.position = new_position
      // refBox.rotation = new_rotation
      ref.origin = new_position
      ref.rotation = new_rotation
      let new_cell = this.drawCharacter(false, txt, layer, direction, font_size, 0)
      let updateCell = this.stage.cells[cell_name]
      let deleteObjs = updateCell.clear()
      // deleteObjs.push(updateCell.labels)

      let addObjs = updateCell.addGdsData([...new_cell.polygons, ...new_cell.labels])
      let points = updateCell.bounding_box()
      let rect = new QGdstk.rectangle(points[0], points[1], 0, 0)
      refBox.setRefBoxPoints(rect.get_points())

      let action2 = { action: 'delete', cellName: cell_name, objs: deleteObjs }
      let action3 = { action: 'add', cellName: cell_name, objs: addObjs }
      this.stage.editHistory.record([action1, action2, action3])
      this.stage.render()
    })
    //重命名cell
    bus.$on('resetName', data => {
      if (this.stage.viewMode) return
      let from = data[0]
      let to = data[1]
      let cell_id = data[2]
      let file_id = data[4]
      if (file_id === this.fileInfo.fileId) {
        this.stage.qedaLib.reNameCell(from, to)
        if (this.active_status) {
          this.save()
        } else {
          let id = this.stage.qedaLib.fileID
          let qedaLib = this.stage.qedaLib
          let lib = qedaLib.QLIB
          lib.write_gds(id)
        }
      }
    })
    //删除cell
    bus.$on('deleteCell', name => {
      if (this.stage.viewMode) return
      this.stage.qedaLib.deleteCell(name)
      this.save()
    })

    //进入TOOL_SQUID_ZLINE选择线模式
    bus.$on('TOOL_SQUID_ZLINE', data => {
      if (this.stage.viewMode) return
      // this.stage.checkMode_bk = this.stage.checkMode
      this.stage.checkMode = 10
      Message.success(`${i18n.t('messages.plsSelectSquidEdge')}`)
    })

    //触发谐振腔功能
    bus.$on('TOOL_CAVITY', data => {
      if (this.stage.viewMode) return
      let checkedObjs = this.stage.checked_objs
      if (checkedObjs.length === 3) {
        let onePolygon = checkedObjs.filter(obj => {
          return obj.type === QedaGraphicType.POLYGON || obj.type === QedaGraphicType.RECT || obj.type === QedaGraphicType.CIRCLE
        })
        let towKeyPoints = checkedObjs.filter(obj => {
          return obj.type === QedaGraphicType.LABEL && obj.layer === 0
        })

        if (onePolygon?.length === 1 && towKeyPoints?.length === 2) {
          let points = []
          towKeyPoints.forEach(element => {
            points.push(element.position)
          })
          let polygon = onePolygon[0].shape
          let result = { polygon: polygon, points: points }
          bus.$emit('TOOL_CAVITY_SELECT', result)
        } else {
          Message.warning(`${i18n.t('messages.plsSelect1P2K')}`)
        }
      } else {
        Message.warning(`${i18n.t('messages.plsSelect1P2K')}`)
      }
    })

    //打开画板设计
    bus.$on('boardOption', data => {
      let obj = {
        backgroundColor: this.stage.backgroundColor,
        gridColor: this.stage.gridColor,
        showgrid: this.stage.showGrid,
        adsorption: this.stage.myaxis.adsorp,
        adsorp_precision: this.stage.myaxis.adsorp_precision,
      }
      bus.$emit('openBoardOption', obj)
    })

    //接收画板设计参数
    bus.$on('boardDesign', data => {
      if (data[0]) {
        this.stage.backgroundColor = data[0]
      }
      if (data[1]) {
        this.stage.gridColor = data[1]
      }
      this.stage.showGrid = data[2]
      this.stage.myaxis.adsorp = data[3]
      if (!data[3]) {
        // data[4] = 0.001
      } else {
        let p = parseFloat(data[4])
        if (p == 0) p = 0.01
        this.stage.myaxis.adsorp_precision = data[4]
      }

      this.stage.render()
    })

    //获取吸附精度
    bus.$on('getPrecision', data => {
      this.stage.myaxis.adsorp_precision = parseFloat(data)
    })
    //调用自身器件
    bus.$on('deviceCallName', cellName => {
      if (this.stage.viewMode) return

      this.switchDrawFunc('selectBox')
      let cell = this.stage.qedaLib.cells[cellName]
      let targetCell = cell.QCELL
      let dependencies = targetCell.dependencies(true)
      if (cellName == this.stage.cell.name || dependencies.filter(cell => cell.name == this.stage.cell.name).length) {
        Message.error(`${i18n.t('messages.deviceCircularReference')}`)
        return
      }
      let refbox = this.stage.qedaLib.addDevice(targetCell, [targetCell, ...dependencies], 0, true)
      refbox.preAdd = false
      refbox.selfDevice = true
      this.stage.cell.add(refbox)
      this.stage.preAddCell = refbox
    })

    //图层切换
    bus.$on('changeLayerList', data => {
      this.syncLayerList()
    })

    //drc检测图形
    bus.$on('drcClicked', data => {
      function pathGenerate(path, points) {
        for (let i = 0; i < points.length; i++) {
          let p = points[i]
          if (!i) {
            path.moveTo(p[0], p[1])
          } else {
            path.lineTo(p[0], p[1])
          }
        }
      }
      function boxGenerate(path, point) {
        let p1, p2, p3, p4
        let w = 0.5
        p1 = [point[0] - w, point[1] - w]
        p2 = [point[0] - w, point[1] + w]
        p3 = [point[0] + w, point[1] + w]
        p4 = [point[0] + w, point[1] - w]
        let points = [p1, p2, p3, p4]
        for (let i = 0; i < points.length; i++) {
          let p = points[i]
          if (!i) {
            path.moveTo(p[0], p[1])
          } else {
            path.lineTo(p[0], p[1])
          }
        }
        return points
      }
      let path = new CanvasKit.Path()
      let aabb = null
      let temp

      if (data.type == 'polygons') {
        pathGenerate(path, data.data)
        data.path = path
        temp = new QGdstk.Polygon(data.data)
      } else if (data.type == 'edge_pairs') {
        pathGenerate(path, data.data[0])
        pathGenerate(path, data.data[1])
        data.path = path
        temp = new QGdstk.Polygon([...data.data[0], ...data.data[1]])
      } else if (data.type == 'edges') {
        pathGenerate(path, data.data)
        data.path = path
        temp = new QGdstk.Polygon([...data.data])
      } else if (data.type == 'points') {
        let points = boxGenerate(path, data.data)
        data.path = path
        temp = new QGdstk.Polygon(points)
      }
      aabb = temp?.bounding_box()

      if (aabb) {
        this.stage.adaptAABB(aabb)
        this.stage.myaxis.render()
        this.stage.drawRulerX()
        this.stage.drawRulerY()
        this.stage.render()
      }
      this.stage.drcGraphics = [data]
    })

    //3D剖面区域
    bus.$on('draw3DCutBox', data => {
      this.switchDrawFunc('3dCutBox')
    })
    //3D区域
    bus.$on('draw3DArea', data => {
      this.switchDrawFunc('3dArea')
    })

    //3D模型
    bus.$on('3DCellView', data => {
      this.route3DView()
    })
  }

  //判断cell名字是否添加后缀
  getCellNameSuffix() {}

  //取消监听
  removeEventListeners() {
    this.canvas.removeEventListener('mousewheel', this.mouseWheelListener)
    this.canvas.removeEventListener('mousemove', this.mouseMoveListener)
    this.canvas.removeEventListener('mouseup', this.mouseUpListener)
    this.canvas.removeEventListener('mousedown', this.mouseDownListener)
    document.removeEventListener('keydown', this.keyBoardListener)
    document.removeEventListener('keyup', this.keyUpBoardListener)
    bus.$off('save')
    bus.$off('func')
    //订阅外部事件
    bus.$off('resizeLayer')
    bus.$off('initLayerList')
    //选择新图层
    bus.$off('changeLayerNum')
    //图层数据修改
    bus.$off('layerChange')
    //图层编号修改
    bus.$off('changeLayerNumber')
    //新建
    bus.$off('newLayer')
    //隐藏
    bus.$off('hideClick')
    //锁定
    bus.$off('lockClick')
    //隐藏全部
    bus.$off('allHide')
    //锁定全部
    bus.$off('allLock')
    //删除图层
    bus.$off('delLayer')
    //合并图层
    bus.$off('mergeLayer')
    //批量修改图层
    bus.$off('batchChangeLayers')
    //整体选择
    bus.$off('overallSelection')
    //部分选择
    bus.$off('partialSelection')
    //剪切
    bus.$off('cut')
    //复制
    bus.$off('copy')
    //粘贴
    bus.$off('paste')
    //移动
    bus.$off('move')
    //旋转
    bus.$off('rotate')
    //拉伸
    bus.$off('stretch')
    //水平翻转
    bus.$off('horizontalFlip')
    //垂直翻转
    bus.$off('verticalFlip')
    //点对齐
    bus.$off('pointAlignment')
    //线对齐
    bus.$off('lineAlignment')
    //阵列
    bus.$off('matrix')
    //对齐
    bus.$off('alignment')
    //切割
    bus.$off('cutting')
    //区域切除
    bus.$off('areaExcision')
    //合并
    bus.$off('merge')
    //布尔运算
    bus.$off('boolean')
    //进入下层
    bus.$off('enterlowerLevel')
    //编辑下层
    bus.$off('editLowerLevel')
    //返回上层
    bus.$off('backUpperLevel')
    //返回最上层
    bus.$off('backTop')
    //另存为单元
    bus.$off('saveAsCell')
    //打撒
    bus.$off('breakUp')
    //工具
    bus.$off('toolWaveGuide')
    bus.$off('TOOL_WBPAD_RUN')
    bus.$off('TOOL_INLINEPAD_RUN')
    bus.$off('TOOL_AIRBRIDGE_RUN')
    bus.$off('TOOL_TINPAD_INPILLAR_RUN')
    bus.$off('TOOL_CHECK_LINE_RUN')
    bus.$off('TOOL_CONNECT_LINE_RUN')
    bus.$off('TOOL_SQUID_ZLINE_RUN')
    bus.$off('TOOL_SQUID_ZLINE')
    bus.$off('TOOL_CAVITY')
    bus.$off('TOOL_CAVITY_RUN')
    bus.$off('TOOL_SPLIT_CELL_RUN')
    bus.$off('TOOL_WAVEGUIDE_RUN')
    bus.$off('addDevice')
    bus.$off('exportGDS')
    bus.$off('adsorption')
    bus.$off('anyAngle')
    bus.$off('angledAdsorption')
    bus.$off('lockStretch')
    bus.$off('anyStretch')
    //画板放大
    bus.$off('boardAmplification')
    //画板缩小
    bus.$off('boardReduction')
    //画板自适应
    bus.$off('boardAdaptation')
    //自适应
    bus.$off('objectAdaptation')
    //区域放大
    bus.$off('regionalAmplification')
    bus.$off('ruler')
    //绘制标签
    bus.$off('labelInfo')
    //关键点
    bus.$off('keyPointInfo')
    //文本图形
    bus.$off('textGraphicsInfo')
    //更新文本图形
    bus.$off('updateTextCell')
    //重命名cell
    // bus.$off('resetName')
    //删除cell
    bus.$off('deleteCell')
    //删除cell
    bus.$off('boardOption')
    bus.$off('boardDesign')
    bus.$off('getPrecision')
    //调用自身器件
    bus.$off('deviceCallName')
    bus.$off('update_static_cell')
    bus.$off('update_cell')
    bus.$off('undo')
    bus.$off('redo')
    bus.$off('changeLayerList')
    bus.$off('drcClicked')
    bus.$off('3DCellView')
  }

  //删除画板
  remove() {
    this.file_snow_idtive()
  }

  skiaLoad() {
    if (!this.stage) {
      this.stage = new QedaStage(this.axis, this.fileInfo, this.containerId)
      this.stage.file_snow_id = this.canvasId
    }
    this.stage.setPaints()
    window.STAGE = this.stage
    this.showHideLayer()
    this.lockLayer()
    this.generateFillImg()
    this.setToolState()
    setTimeout(() => this.stage.render())
  }

  generateFillImg(layerNumber = 'All') {
    const result = []
    const rect_border = CanvasKit.LTRBRect(0, 0, 8.3, -5)
    const rect_fill = CanvasKit.LTRBRect(0, 0, 250, -150)
    if (!store.state.layerDatas) return
    if (!store.state.layerDatas.filter(item => this.fileInfo.fileId === item.file_snow_id)?.length) return
    let data = store.state.layerDatas.filter(item => this.fileInfo.fileId === item.file_snow_id)[0].layerDatas

    if (!data?.length) return
    if (layerNumber !== 'All') {
      data = data.filter(layer => layer.layerNumber === layerNumber)
    }
    const surface = CanvasKit.MakeCanvasSurface('fill_canvas')
    const canvas = surface.getCanvas()
    data.forEach((layer, i) => {
      // if(layer.color !== ''){
      canvas.save()
      canvas.scale(1, -1)
      canvas.translate(0, 0)
      canvas.clear(CanvasKit.WHITE)
      const index = layer.layerNumber
      const outline_pt = this.stage.layer_paints_img[index]
      const fill_pt = this.stage.layer_fill_paints_img[index]
      canvas.save()
      canvas.scale(3, 3)
      canvas.drawRect(rect_fill, fill_pt)
      canvas.restore()
      canvas.save()
      canvas.scale(30, 30)
      canvas.drawRect(rect_border, outline_pt)
      canvas.restore()
      canvas.restore()
      // surface.flush()
      // const img = document.getElementById('fill_img')
      // const img2 = document.getElementById('fill_img2')
      let data = surface.makeImageSnapshot().encodeToBytes(CanvasKit.ImageFormat.JPEG, 0)
      let blob = new Blob([data], { type: 'image/jpeg' })
      data = null // 要置null，否则存在内存泄漏风险
      let url = URL.createObjectURL(blob)
      blob = null
      // img.src = url
      // img2.src = url
      result.push(url)
    })
    // bus.$emit('layerImgChange', { layerNumber, imgs: result })
  }
  getRandomValue() {
    return Math.floor(Math.random() * 100000 + 1)
  }
  // 初始化坐标系统，舞台
  initStage() {
    this.axis.rulerX.height = 22
    this.axis.rulerY.width = 22
    var canvas = document.getElementById(this.canvasId)
    var stage = document.getElementById('stage-container')

    this.axis.width = stage.offsetWidth - 25
    this.axis.height = stage.offsetHeight - 25
    this.axis.dx = this.axis.width / 2
    this.axis.dy = this.axis.height / 2
    this.axis.rulerX.width = this.axis.width
    this.axis.rulerY.height = this.axis.height

    this.axis.rulerX.height = 25
    this.axis.rulerY.width = 25
    // this.updateRulerDom()
    this.axis.render()
    canvas.width = this.axis.width
    canvas.height = this.axis.height
    this.skiaLoad()
    bus.$emit('updateLayout', this)
  }

  //重新计算画板宽度
  resizeLayer() {
    setTimeout(() => {
      var canvas = document.getElementById(this.canvasId)
      if (!canvas) return
      var stage = document.getElementById('stage-container')

      this.axis.width = stage.offsetWidth - 25
      this.axis.height = stage.offsetHeight - 25
      canvas.width = this.axis.width
      canvas.height = this.axis.height
      this.stage.updateSize()
      this.axis.rulerX.width = this.axis.width
      this.axis.rulerY.height = this.axis.height
      // this.updateRulerDom()
      this.axis.render()
      bus.$emit('updateLayout', this)
      setTimeout(() => {
        this.stage.drawRulerX()
        this.stage.drawRulerY()
      })
    })
  }
  //更新尺子canvas
  updateRulerDom() {
    let rulerX = document.getElementById(this.containerId + 'ruler-x')
    let rulerY = document.getElementById(this.containerId + 'ruler-y')
    rulerX.style.width = this.axis.rulerX.width + 'px'
    rulerX.style.height = this.axis.rulerX.height + 'px'
    rulerY.style.width = this.axis.rulerY.width + 'px'
    rulerY.style.height = this.axis.rulerY.height + 'px'
  }

  //更新坐标系统属性
  updateAxis() {
    this.axis.pickEnabled = this.drawType === null
  }

  //返回鼠标在画板坐标
  getMousePostion() {
    let ref = this.stage.editReference
    let myaxis = this.stage.myaxis
    // myaxis.angled_dsorp_point = null
    // setTimeout(() => (myaxis.angled_dsorp_point = null))

    myaxis.angled_dsorp_special_point = null
    let draw_circle = (this.drawType == 'circle' && this.press_shift) || (myaxis.angledAdsorption && (this.drawType == 'circle' || this.drawType == 'rectangle')) //绘制正圆 正方形
    if (ref) {
      //如果编辑引用对象鼠标位置转换到引用的局部坐标
      let mouse_pos = this.stage.myaxis.mouse_point
      if (myaxis.adsorpPoint && myaxis.hit_point) {
        mouse_pos = { x: myaxis.hit_point[0], y: myaxis.hit_point[1] }
      }

      if ((myaxis.angledAdsorption || draw_circle) && this.last_mouse_pos) {
        let four_directions = false //四方向吸附
        if (draw_circle || (myaxis.angledAdsorption && (this.drawType == 'circle' || this.drawType == 'rectangle'))) {
          four_directions = true
        }
        let result = isAdsorb(this.last_mouse_pos, myaxis.mouse_point, true, 'um', four_directions)
        if (result.rotatePoint) {
          let res = myaxis.getGridAdsorpPoint2(result.rotatePoint)
          let p = [res.x, res.y]
          if (draw_circle) {
            myaxis.angled_dsorp_special_point = p
          } else {
            myaxis.angled_dsorp_point = p
          }

          mouse_pos = res
        }
      }
      let temp = new QGdstk.Polygon([[mouse_pos.x, mouse_pos.y]])
      temp.translate([-ref.origin[0], -ref.origin[1]])
      temp.rotate((-ref.rotation * Math.PI) / 180)
      temp.scale(1 * ref.magnification, ref.x_reflection * ref.magnification)
      let trans_p = temp.get_points()[0]
      return { x: trans_p[0], y: trans_p[1] }
    } else {
      //斜角吸附
      if ((myaxis.angledAdsorption || draw_circle) && this.last_mouse_pos) {
        let mouse_pos = myaxis.mouse_point
        let four_directions = false //四方向吸附
        if (draw_circle || (myaxis.angledAdsorption && (this.drawType == 'circle' || this.drawType == 'rectangle'))) {
          four_directions = true
        }

        let result = isAdsorb(this.last_mouse_pos, mouse_pos, true, 'um', four_directions)

        if (result.rotatePoint) {
          let res = result.rotatePoint //myaxis.getGridAdsorpPoint2(result.rotatePoint) //bug928 斜角吸附优先于格点吸附
          let p = [res.x, res.y] //let p = [result.rotatePoint.x, result.rotatePoint.y]

          //bug934 斜角吸附和吸附点同时打开 同时满足以上吸附的点优先吸附点
          if (myaxis.adsorpPoint && myaxis.hit_point) {
            let lastPosArr = [this.last_mouse_pos.x, this.last_mouse_pos.y]
            if (getSlope(p, lastPosArr) == getSlope(myaxis.hit_point, lastPosArr)) {
              p = deepClone(myaxis.hit_point)
              res.x = p[0]
              res.y = p[1]
            }
          }
          if (draw_circle) {
            myaxis.angled_dsorp_special_point = p
          } else {
            myaxis.angled_dsorp_point = p
          }

          if (this.drawType === 'ruler') {
            if (this.stage.adsorptionLines.length) {
              let cross = this.stage.getRulerMouseAdropPos(res)

              myaxis.angled_dsorp_special_point = cross
              return { x: cross[0], y: cross[1] }
            } else if (myaxis.hit_point) {
              myaxis.angled_dsorp_special_point = myaxis.hit_point
              return { x: myaxis.hit_point[0], y: myaxis.hit_point[1] }
            }
          }
          return res
        }
      }

      //物体吸附
      if (myaxis.adsorpPoint && myaxis.hit_point) {
        return { x: myaxis.hit_point[0], y: myaxis.hit_point[1] }
      }
      return myaxis.mouse_point //[this.stage.myaxis.mouse_point.x, this.stage.myaxis.mouse_point.y]//鼠标在画板的坐标
    }
  }

  //获取最后一个点击点的全局坐标
  getLastMouseGlobalPos(mouse_pos) {
    let ref = this.stage.editReference
    if (ref) {
      let temp = new QGdstk.Polygon([[mouse_pos.x, mouse_pos.y]])
      temp.scale(1 * ref.magnification, ref.x_reflection * ref.magnification)
      temp.rotate((ref.rotation * Math.PI) / 180)
      temp.translate([ref.origin[0], ref.origin[1]])
      let trans_p = temp.get_points()[0]
      return { x: trans_p[0], y: trans_p[1] }
    }

    return mouse_pos
  }
  //完成图形绘制
  completeDraw() {
    this.last_mouse_pos = null
    if (this.drawingObject) {
      if (this.drawAdaptRect || this.drawType === 'adaptRect') {
        this.axis.needAdsorption = true
        //框选自适应绘制完成
        if (JSON.stringify(this.drawingObject.aabb[0]) !== JSON.stringify(this.drawingObject.aabb[1])) {
          this.stage.adaptAABB(this.drawingObject.aabb)
        }
        this.stage.clearTools()
        this.drawingObject = null
        this.drawAdaptRect = false
        this.stage.freeze = false
        this.stage.myaxis.render()
        this.stage.drawRulerX()
        this.stage.drawRulerY()
        this.stage.render()
        if (this.drawType === 'adaptRect') {
          this.switchDrawFunc('selectBox')
        }
      } else if (this.drawType === 'selectBox') {
        this.axis.needAdsorption = true
        this.drawPoints.push(this.getMousePostion())
        let flag = true //包围盒全选模式
        if (this.drawPoints[0].x > this.drawPoints[1].x) {
          //包围盒半选模式
          flag = false
        }
        this.stage.updateSelectObjByAABB(this.drawingObject.aabb, flag)
        this.stage.clearTools()
        this.drawingObject = null
        this.drawPoints = []
      } else if (this.drawType === 'cutBox') {
        this.axis.needAdsorption = true
        this.boolObjsWithAABB()
        this.stage.clearTools()
        this.drawingObject = null
        this.drawPoints = []
      } else {
        let invalidGraphics = this.drawingObject.completeDraw()
        let isVailid = true
        //删除不合法图形
        if (invalidGraphics.length) {
          isVailid = false
          // Message.error('删除错误图形')
        }
        invalidGraphics.forEach(g => this.stage.currentEditCell.delete(g))
        if (this.drawingObject.type === QedaGraphicType.RECT || this.drawingObject.type === QedaGraphicType.CIRCLE) {
          if (this.drawingObject.aabb_area === 0) {
            // Message.error('删除错误图形')
            isVailid = false
            this.stage.currentEditCell.delete(this.drawingObject)
          }
        }
        if (isVailid) {
          this.stage.editHistory.record([{ action: 'add', cellName: this.stage.getCurrentCellName(), objs: [this.drawingObject] }])

          //删除上一个图形
          if (this.drawType === '3dCutBox' && this.stage.cell.cell3DCutBox.length > 1) {
            let before = this.stage.cell.cell3DCutBox[0]
            this.deleteGraphic(before)
            this.stage.editHistory.undoStack.at(-1).push({ action: 'delete', cellName: this.stage.getCurrentCellName(), objs: [before] })
          }
          if (this.drawType === '3dArea' && this.stage.cell.cell3DArea.length > 1) {
            let before = this.stage.cell.cell3DArea[0]
            this.deleteGraphic(before)
            this.stage.editHistory.undoStack.at(-1).push({ action: 'delete', cellName: this.stage.getCurrentCellName(), objs: [before] })
          }
        }

        this.drawingObject = null
      }
    }
  }
  //选择框切除图形
  boolObjsWithAABB() {
    const objs = this.stage.checked_objs.filter(obj => !obj.isRefBox && obj.type !== QedaGraphicType.PATH && obj.type !== QedaGraphicType.LABEL && obj.type !== QedaGraphicType.KEYPOINT && obj.type !== QedaGraphicType.RULER) //过滤线
    const length = objs.length
    let record1 = [],
      record2 = []
    if (length) {
      const rect = new QGdstk.rectangle(this.drawingObject.aabb[0], this.drawingObject.aabb[1])
      for (let i = 0; i < length; i++) {
        const obj = objs[i]
        const result = new QGdstk.boolean(obj.getGdsData(), rect, 'not')
        // obj.deleted = true
        this.deleteGraphic(obj)
        record1.push(obj)
        const size = result.length
        for (let j = 0; j < size; j++) {
          const polygon = QedaGraphics.drawPolygon(result[j].get_points())
          polygon.layer = obj.layer
          polygon.checked = true
          polygon.updateShape()
          this.addGraphic(polygon)
          this.stage.render()
          record2.push(polygon)
        }
      }
    }
    let action1 = { action: 'delete', cellName: this.stage.getCurrentCellName(), objs: record1 }
    let action2 = { action: 'add', cellName: this.stage.getCurrentCellName(), objs: record2 }
    this.stage.editHistory.record([action1, action2])
    // this.stage.checkMode = 0
    this.stage.checkMode = this.getCurrentCheckMode()
    this.drawType = 'selectBox'
    bus.$emit('cancelSidebarSelect')
  }
  //选中图形布尔运算
  boolCheckedObjs(params) {
    if (this.stage.editReference) return
    let operation = 'or'
    let layer = null
    let delet_origin = true
    if (params) {
      layer = params[0]
      operation = params[1]
      delet_origin = params[2] === '2'
    }
    if (this.stage.checkMode !== 0) return //整体选择模式才能bool
    const objs = this.stage.checked_objs.filter(obj => !obj.isRefBox && obj.type !== QedaGraphicType.PATH && obj.type !== QedaGraphicType.LABEL && obj.type !== QedaGraphicType.RULER && obj.type !== QedaGraphicType.KEYPOINT) //过滤线
    const length = objs.length
    if (length < 2) return
    //快速合并
    if (layer == null && operation === 'or') {
      let layers = Array.from(new Set(objs.map(obj => obj.layer)))
      layers = layers.map(layer => {
        return { layer: layer, objs: [] }
      })

      layers.forEach(layer => {
        layer.objs = objs.filter(obj => obj.layer == layer.layer)
      })

      let action1 = { action: 'delete', cellName: this.stage.getCurrentCellName(), objs: [] }
      let action2 = { action: 'add', cellName: this.stage.getCurrentCellName(), objs: [] }
      layers.forEach(layer => {
        let res = this.quickBoolObjs(layer.layer, layer.objs, operation)
        action1.objs.push(...res[0])
        action2.objs.push(...res[1])
      })
      this.stage.editHistory.record([action1, action2])
      this.stage.render()
      return
    }
    const operand1 = []
    const operand2 = []
    let record1 = []
    for (let i = 0; i < length; i++) {
      const obj = objs[i]
      if (operand1.length === 0) {
        operand1.push(obj.getGdsData())
      } else {
        operand2.push(obj.getGdsData())
      }
      if (delet_origin) {
        this.deleteGraphic(obj)
        record1.push(obj)
      }
    }
    let action1 = { action: 'delete', cellName: this.stage.getCurrentCellName(), objs: record1 }
    const result = new QGdstk.boolean(operand1, operand2, operation)
    const size = result.length
    let record2 = []
    for (let j = 0; j < size; j++) {
      const polygon = QedaGraphics.drawPolygon(result[j].get_points())
      polygon.checked = true
      polygon.layer = layer
      this.addGraphic(polygon)
      record2.push(polygon)
    }
    let action2 = { action: 'add', cellName: this.stage.getCurrentCellName(), objs: record2 }
    this.stage.editHistory.record([action1, action2])
    this.stage.render()
  }

  quickBoolObjs(layer, objs, operation) {
    let res = [[], []]
    let delet_origin = true
    const length = objs.length
    if (length < 2) res
    const operand1 = []
    const operand2 = []
    let record1 = []
    for (let i = 0; i < length; i++) {
      const obj = objs[i]
      if (operand1.length === 0) {
        operand1.push(obj.getGdsData())
      } else {
        operand2.push(obj.getGdsData())
      }
      if (delet_origin) {
        this.deleteGraphic(obj)
        record1.push(obj)
      }
    }
    res[0] = record1

    const result = new QGdstk.boolean(operand1, operand2, operation)
    const size = result.length
    let record2 = []
    for (let j = 0; j < size; j++) {
      const polygon = QedaGraphics.drawPolygon(result[j].get_points())
      polygon.checked = true
      polygon.layer = layer
      this.addGraphic(polygon)
      record2.push(polygon)
    }
    res[1] = record2
    return res
  }
  //切割物体
  sliceCheckedObjs() {
    if (this.stage.editReference) return
    const length = this.stage.checked_objs.length
    if (!length) return
    let axis, val
    const mouse_pos = this.getMousePostion()
    if (this.stage.checkMode === 3) {
      axis = 'x'
      val = mouse_pos.x
    } else {
      axis = 'y'
      val = mouse_pos.y
    }
    const a = []
    const pologons = this.stage.checked_objs.filter(obj => !obj.isRefBox && obj.type !== QedaGraphicType.PATH && obj.type !== QedaGraphicType.LABEL && obj.type !== QedaGraphicType.KEYPOINT && obj.type !== QedaGraphicType.RULER) //过滤无宽度线
    const pologons_size = pologons.length
    let record1 = []
    for (let i = 0; i < pologons_size; i++) {
      const obj = pologons[i]
      a.push(obj.getGdsData())
      this.deleteGraphic(obj)
      record1.push(obj)
    }
    let action1 = { action: 'delete', cellName: this.stage.getCurrentCellName(), objs: record1 }

    let record2 = []
    const result = new QGdstk.slice(a, [val], axis, 0.0000001)
    const size = result.length
    for (let j = 0; j < size; j++) {
      const total = result[j].length
      for (let k = 0; k < total; k++) {
        const polygon = QedaGraphics.drawPolygon(result[j][k].get_points())
        polygon.layer = result[j][k].layer
        polygon.updateShape()
        polygon.checked = true
        this.addGraphic(polygon)
        record2.push(polygon)
      }
    }

    const lines = this.stage.checked_objs.filter(obj => obj.type === QedaGraphicType.PATH) //过滤无宽度线
    lines.forEach(line => this.deleteGraphic(line))
    action1.objs.push(...lines)
    const result_lines = sliceLines(lines, val, axis)

    const size_lines = result_lines.length
    for (let j = 0; j < size_lines; j++) {
      const total = result_lines[j].result.length
      for (let k = 0; k < total; k++) {
        const path = QedaGraphics.drawPath(result_lines[j].result[k])
        path.layer = result_lines[j].layer
        path.width = result_lines[j].width
        path.radius = result_lines[j].radius
        path.updateShape()
        path.checked = true
        path.hide = false
        this.addGraphic(path)
        record2.push(path)
      }
    }

    let action2 = { action: 'add', cellName: this.stage.getCurrentCellName(), objs: record2 }
    this.stage.editHistory.record([action1, action2])
    this.stage.render()
  }
  //阵列
  arrayObjs(params) {
    const data_size = this.stage.checked_objs.length
    if (!data_size) return
    const data = this.stage.checked_objs
    const start = this.stage.checkedGraphicsAABB_center //起始点
    const start_x = start[0]
    const start_y = start[1]
    const offset = [] //保存偏移量
    for (let i = 0; i < data_size; i++) {
      const p = data[i].position
      offset.push([start_x - p[0], start_y - p[1]])
    }
    //输入参数
    const row = params[0]
    const clo = params[1]
    const row_vector = { x: params[2], y: params[3] }
    const col_vector = { x: params[4], y: params[5] }

    const positions = []
    for (let i = 0; i < row; i++) {
      for (let j = 0; j < clo; j++) {
        let x = start_x + j * col_vector.x + i * row_vector.x
        let y = start_y + i * row_vector.y + j * col_vector.y
        positions.push([x, y])
      }
    }
    positions.shift() //删除被复制项
    const size = positions.length
    let record_data = []
    for (let i = 0; i < size; i++) {
      for (let j = 0; j < data_size; j++) {
        const p_new = [positions[i][0] - offset[j][0], positions[i][1] - offset[j][1]]
        const copy = QedaGraphics.copy(data[j], p_new)
        copy.checked = true
        this.addGraphic(copy)
        record_data.push(copy)
      }
    }
    this.stage.editHistory.record([{ action: 'add', cellName: this.stage.getCurrentCellName(), objs: record_data }])
    this.stage.render()
  }
  //粘贴
  pasteData() {
    if (!this.stage.check_able) return
    if (this.copyData) {
      if (this.pre_copy_data) {
        return
      }
      this.resetCheckStatus(this.isCut)
      this.prePasteData()
      this.stage.checkMode = 6
    }
  }
  //预粘贴
  prePasteData() {
    const data = this.copyData
    const data_size = data.length
    const mouse_pos = this.getMousePostion()
    const start = this.copyDataCenter //起始点
    if (!start) return
    const start_x = start[0]
    const start_y = start[1]
    const offset = [] //保存偏移量
    for (let i = 0; i < data_size; i++) {
      const p = data[i].position
      offset.push([start_x - p[0], start_y - p[1]])
    }
    const pre_copy_data = []
    for (let j = 0; j < data_size; j++) {
      const p_new = [mouse_pos.x - offset[j][0], mouse_pos.y - offset[j][1]]
      const copy = QedaGraphics.copy(data[j], p_new)
      copy.updateMouseOffset(mouse_pos)
      copy.preAdd = true
      copy.checked = true
      this.addGraphic(copy)
      this.stage.render()
      pre_copy_data.push(copy)
    }
    this.stage.editHistory.record([{ action: 'add', cellName: this.stage.getCurrentCellName(), objs: pre_copy_data }])
    // this.copyData = pre_copy_data
    this.pre_copy_data = pre_copy_data
  }
  //旋转
  rotateObjs() {
    if (this.stage.checked_objs_count && this.stage.checkMode == 0) {
      this.stage.moveMod = 2
      this.stage.movePosition = []
      this.stage.rotat_center = this.stage.checked_objs_count > 1 ? this.stage.checkedGraphicsAABB_center : this.stage.checked_objs[0].position
    } else {
      this.switchDrawFunc('selectBox')
    }
  }
  //拉伸
  stretch() {
    // this.stage.isStretch = !this.stage.isStretch
    this.stage.isStretch = !this.stage.isStretch
  }
  mouseDown(e) {
    if (this.stage.viewMode) return
    // e.preventDefault()
    if (!this.stage.myaxis.mouse_point) return //舞台初始化完成
    if (e.button === 0) {
      //左键
      if (this.stage.checkMode === 3 || this.stage.checkMode === 4) {
        //XY切割物体
        this.sliceCheckedObjs()
        return
      }
      if (this.stage.checkMode === 6) {
        //粘贴
        //重置状态
        const data_size = this.pre_copy_data?.length
        if (this.isCut) {
          this.deleteCutObjs()
        }
        for (let i = 0; i < data_size; i++) {
          this.pre_copy_data[i].resetStatus()
        }
        this.pre_copy_data = null
        // this.copyData = null
        // this.copyDataCenter = null
        // this.isCut = false
        // return
      }
      if (this.preAddObj) {
        this.preAddObj.preAdd = false
        //放置后记录
        this.stage.editHistory.record([{ action: 'add', cellName: this.stage.getCurrentCellName(), objs: [this.preAddObj] }])
        // //文本图形放置
        // if (this.preAddObj.isRefBox) {
        //   let cell_name = this.preAddObj.reference.cell.name
        //   if (!this.stage.cells[cell_name]) {
        //     this.stage.qedaLib.write()
        //     bus.$emit('updateFileTree', [this.preAddObj.reference.cell.name])
        //     bus.$emit('fileChange', this.fileInfo.fileId)
        //   }
        // }
        // setTimeout(() => {
        this.preAddObj.checked = false
        this.preAddObj = null
        // })
        // return
      }
      this.leftPress = true
      if (this.drawingObject) {
        //正在绘制
        //两点描述的工具图形
        if (this.drawType === 'adaptRect' || this.drawType === 'selectBox' || this.drawType === 'cutBox') {
          this.completeDraw()
          return
        }
        //两点描述的图形
        if (this.drawType === 'line' || this.drawType === 'circle' || this.drawType === 'rectangle' || this.drawType === 'ruler' || this.drawType === '3dCutBox') {
          //圆和矩形只需要两个点描述
          this.completeDraw()
          return
        }
        //多点描述的图形
        let mouse_pos = this.getMousePostion()
        this.drawingObject.drawPoint(mouse_pos, true)
        this.last_mouse_pos = this.getLastMouseGlobalPos(mouse_pos)
      } else if (this.drawType) {
        //开始绘制
        if (this.drawType === 'selectBox') {
          //-----
        } else if (this.drawType === 'adaptRect' || this.drawType === 'cutBox') {
          //绘制自适应框 区域切除盒子
          let newGraph = QedaGraphics.newQedaGraphic(this.drawType, this.getMousePostion())
          newGraph.isTool = true
          newGraph.layer = 3
          this.drawingObject = newGraph
          this.stage.addTool([newGraph])
          if (this.drawType === 'adaptRect') this.drawAdaptRect = true
        } else if (this.drawType === 'label' || this.drawType === 'keypoint') {
          // alert('添加标签')
          if (!this.stage.currentLayer || this.stage.currentLayer.hide) return //当前图层隐藏禁止绘制
          if (!this.copyLabelData) return
          let mouse_pos = this.getMousePostion()
          let pos = [mouse_pos.x, mouse_pos.y]
          let label = QedaGraphics.copy(this.copyLabelData, pos)
          label.preAdd = true
          this.preAddObj = label
          this.addGraphic(label)
          // this.stage.editHistory.record([{ action: 'add', cellName: this.stage.getCurrentCellName(), objs: [label] }])
        } else if (this.drawType === 'character') {
          if (!this.stage.currentLayer) return //当前图层隐藏禁止绘制
          if (!this.copyCharacterData) return
          let mouse_pos = this.getMousePostion()
          let pos = [mouse_pos.x, mouse_pos.y]
          let ref_box = QedaGraphics.copy(this.copyCharacterData, pos)
          this.preAddObj = ref_box
          this.preAddObj.preAdd = true
          this.stage.cell.add(ref_box)
          this.stage.render()
        } else {
          if ((this.drawType !== 'ruler' || this.drawType !== '3dArea' || this.drawType !== '3dCutBox') && (!this.stage.currentLayer || this.stage.currentLayer.hide)) return //当前图层隐藏禁止绘制
          let mouse_pos = this.getMousePostion()
          let newGraph = QedaGraphics.newQedaGraphic(this.drawType, mouse_pos)
          this.last_mouse_pos = this.getLastMouseGlobalPos(mouse_pos)
          this.drawingObject = newGraph
          this.addGraphic(this.drawingObject)
          // this.stage.editHistory.record([{ action: 'add', cellName: this.stage.getCurrentCellName(), objs: [this.drawingObject] }])
        }
      }
    }

    if (e.button === 2) {
      //右键
      this.rightPress = true
      this.completeDraw()
    }
  }

  mouseMove(e) {
    if (this.stage.viewMode) return

    e.preventDefault()

    let mouse_pos = this.getMousePostion()
    // if (!this.stageRenderCompleted) return
    if (this.preAddObj) {
      this.preAddObj.movePosByMouse(mouse_pos)
    }
    if (this.stage.checkMode === 6) {
      //预粘贴
      if (!this.pre_copy_data) return
      const data_size = this.pre_copy_data.length
      for (let i = 0; i < data_size; i++) {
        this.pre_copy_data[i].movePosByMouse(mouse_pos)
      }
      return
    }
    if (this.drawingObject) {
      //正在绘制
      if (this.drawType === 'selectBox' || this.drawType === 'cutBox') {
        this.axis.needAdsorption = false
        //根据绘制点不需要坐标转换
        this.drawingObject.drawPoint(this.stage.myaxis.mouse_point)
      } else {
        let perfect = false
        if (this.press_shift || this.stage.myaxis.angledAdsorption) {
          perfect = true
        }

        this.drawingObject.drawPoint(this.getMousePostion(), false, perfect)
      }
      this.stage.render()
      return
    }
    if (this.stage.checkMode === 0 && this.stage.checked_objs_hited) {
      //整体选择 如果落点选中物体禁止绘制选择框
      this.completeDraw()
      return
    }
    if (this.stage.checkMode === 1 && this.stage.checked_objs_hited) {
      //部分选择 选中物体禁止绘制选择框
      this.completeDraw()
      return
    }

    if (this.rightPress) {
      //右键拖动绘制自适应框
      if (!this.drawAdaptRect) {
        this.axis.setDrag(false, null)
        // this.stage.freeze = true
        let newGraph = QedaGraphics.newQedaGraphic('adaptRect', this.getMousePostion())
        this.drawingObject = newGraph
        newGraph.isTool = true
        newGraph.layer = 3
        this.stage.addTool([newGraph])
      }
      this.drawAdaptRect = true
      return
    }
    if (this.drawType === 'selectBox') {
      //左键拖动绘制选择框
      if (this.stage.moveMod) return
      if (!this.leftPress) return
      if (this.stage.checkMode === 2) return
      const mouse_pos = this.stage.myaxis.mouse_point //this.getMousePostion()
      this.drawPoints.push(mouse_pos)
      let newGraph = QedaGraphics.newQedaGraphic(this.drawType, mouse_pos)
      newGraph.isTool = true
      newGraph.layer = 3
      this.drawingObject = newGraph
      this.stage.addTool([newGraph])
    }
  }

  mouseUp(e) {
    if (this.stage.viewMode) return
    this.stage.myaxis.angled_dsorp_point = null
    this.leftPress = false
    this.rightPress = false
    if (this.drawType === 'selectBox' || this.drawAdaptRect) {
      setTimeout(() => this.completeDraw())
    }
    const mode = this.stage.checkMode
    if (e.button === 0) {
      // if (this.preAddObj) {
      //   this.preAddObj.preAdd = false
      //   //放置后记录
      //   this.stage.editHistory.record([{ action: 'add', cellName: this.stage.getCurrentCellName(), objs: [this.preAddObj] }])
      //   setTimeout(() => {
      //     this.preAddObj.checked = false
      //     this.preAddObj = null
      //   })
      //   return
      // }
      //左键松开
      if (mode === 3 || mode === 4 || mode === 5 || mode === 6) {
        //XY切割物体 阵列 预粘贴返回整体选择状态
        setTimeout(() => {
          this.stage.checkMode = this.getCurrentCheckMode()
          // bus.$emit('cancelSidebarSelect')
        })
      }
    }
  }

  onMouseWheel(e) {}

  keyUp(e) {
    if (this.stage.viewMode) return
    this.press_shift = false
  }
  keyDown(e) {
    if (this.stage.viewMode) return
    const key = e.key.toLowerCase()

    if (key === 'k') {
      //绘制尺子
      this.switchDrawFunc('ruler')
      return
    }
    // if (this.stage.currentLayer.lock || this.stage.currentLayer.hide) return
    this.press_shift = e.shiftKey
    // if (key === 'tab') {
    //   //切换模式
    //   this.stage.checkMode = 1
    //   return
    // }
    if (e.ctrlKey && key === 's') {
      e.preventDefault()
      //保存
      bus.$emit('saveFile', true)
      // this.save()
      return
    }
    if (e.ctrlKey && key === 'a') {
      e.preventDefault()
      //
      this.stage.check_all = true
      return
    }
    if (e.ctrlKey && (key === 'c' || key === 'x')) {
      if (!this.stage.check_able) {
        return
      }
      //复制 剪切

      this.copyData = this.copyObjs(this.stage.checked_objs)

      this.copyDataCenter = this.stage.checkedGraphicsAABB_center
      this.isCut = key === 'x'
      return
    }
    if (e.ctrlKey && key === 'v') {
      //粘贴
      this.pasteData()
      return
    }
    //区域放大
    // if (key === 'v') {
    //   this.switchDrawFunc('adaptRect')
    // }
    if (e.ctrlKey && key === 'z') {
      //撤销
      this.undo()
    }
    if (e.ctrlKey && key === 'y') {
      //恢复
      this.redo()
    }
    if (key === 'd' && this.stage.checked_objs_count) {
      //切换移动模式
      this.stage.movePosition = []
      if (this.stage.checkMode === 0) {
        this.stage.moveMod = 1
      } else {
        // bus.$emit('cancelSidebarSelect')
      }
      return
    }
    if (key === 's' && this.stage.checked_objs_count) {
      //切换拉伸模式
      this.stage.movePosition = []
      if (this.stage.checkMode === 1) {
        this.stage.moveMod = 1
      } else {
        // bus.$emit('cancelSidebarSelect')
      }
      return
    }
    if (key === 'r' && e.shiftKey && e.altKey) {
      //切换旋转模式
      this.rotateObjs()
      return
    }
    // if (key === 'x') { //x切割
    //     this.flipX()
    //     // if (this.stage.checkMode !== 0 && this.stage.checkMode !== 4) return
    //     // this.stage.checkMode = 3
    //     return
    // }
    // if (key === 'y') { //y切割
    //     if (this.stage.checkMode !== 0 && this.stage.checkMode !== 3) return
    //     this.stage.checkMode = 4
    //     return
    // }
    //x y 切割
    if (e.ctrlKey && key === 'q') {
      if (this.stage.checkMode === 4) {
        this.stage.checkMode = 3
        return
      } else if (this.stage.checkMode === 3) {
        this.stage.checkMode = 4
        return
      } else {
        this.stage.checkMode = 3
      }
      return
    }
    if (e.ctrlKey && key === 'q') {
      //切割
      this.cutting()
      return
    }
    if (e.shiftKey && key === 'c') {
      //框选区域切割
      this.areaExcision()
      return
    }
    if (e.shiftKey && key === 'm') {
      //布尔运算
      this.boolCheckedObjs()
      return
    }
    // if (key === 'a'){ //阵列
    //   if(this.stage.checkMode === 0){
    //     this.stage.checkMode = 5
    //   }
    //   return
    // }
    if (key === 'delete') {
      //删除
      this.deleteCheckedObjs()
      return
    }
    // if (e.shiftKey && key === 's') {
    //   //切换拉伸模式
    //   this.stretch()
    //   return
    // }
    // if (e.shiftKey && key === 'f') {
    //   //整体和部分选择切换
    //   this.stage.checkMode = this.stage.checkMode ? 0 : 1
    //   this.stage.resetStatus()
    //   return
    // }
    if (e.shiftKey && key === 'r') {
      //斜角吸附/任意角度
      return
    }

    // if (key === 'l') {
    //   //绘制标签
    //   this.switchDrawFunc('label')
    //   return
    // }
    // if (key === 'p') {
    //   //绘制关键点
    //   this.switchDrawFunc('keyPoint')
    //   return
    // }
    // if (key === 'c') {
    //   //文本图形
    //   this.drawCharacter(true, 'text', 0, [0, 0], false, 0, 1)
    //   return
    // }
    // if (key === 'j') {
    //   this.generateWaveGuide()
    //   return
    // }
    // if (key === 't') {
    //   bus.$emit('test', true)
    //   return
    // }

    // if (key === 'e') {//debug
    //   let lib = this.stage.qedaLib.QLIB

    //   this.exportGds(lib, 'export.gds')
    // }
    // if (key === 'n') {
    //   bus.$emit('saveAsCell', true)
    // }
    // //删除cell debug
    // if (key === 'i') {
    //   this.stage.qedaLib.deleteCell('wbpad_l0d0_3')
    //   this.stage.qedaLib.deleteCell('air_bridge_l0d0_4')
    // }
    //完成绘制
    if (key === 'enter') {
      if (this.drawType === 'dogleg' || this.drawType === 'polygon') {
        this.completeDraw()
      }
      return
    }
    if (key === 'escape') {
      // this.resetBoardStatus()
      this.stage.clearTools()
      if (this.drawingObject) {
        this.deleteGraphic(this.drawingObject)
      }
      this.stage.myaxis.angled_dsorp_special_point = null
      this.stage.myaxis.angled_dsorp_point = null
      this.drawingObject = null
      this.drawPoints = []
      this.copyLabelData = null
      this.copyCharacterData = null
      this.deletePreAddCell()
      return
    }

    //标签关键点弹窗
    if (key === 'q' && e.shiftKey) {
      if (this.stage.checkMode === 8) {
        bus.$emit('pointAlignmentDialog', true)
      } else if (this.stage.checkMode === 9) {
        bus.$emit('lineAlignmentDialog', true)
      } else if (this.drawType == 'label') {
        bus.$emit('label', true)
      } else if (this.drawType == 'keypoint') {
        bus.$emit('keyPoint', true)
      } else if (this.copyCharacterData) {
        bus.$emit('textGraphics', true)
      }
      return
    }
    //切割空格切换
    if (key === ' ') {
      if (this.stage.checkMode === 4) {
        e.preventDefault()
        this.stage.checkMode = 3
        return
      } else if (this.stage.checkMode === 3) {
        e.preventDefault()
        this.stage.checkMode = 4
        return
      }
    }
  }

  keyUp(e) {
    this.press_shift = false
  }
  //重置选中状态
  resetCheckStatus(cut) {
    const data_size = this.stage.checked_objs.length
    this.stage.preCutObjs = []
    for (let i = 0; i < data_size; i++) {
      const obj = this.stage.checked_objs[i]
      obj.resetStatus()
      if (cut) {
        obj.preCut = true
        this.stage.preCutObjs.push(obj)
      }
    }
    this.stage.render()
  }

  //删除剪切数据
  deleteCutObjs() {
    if (!this.pre_copy_data?.length) {
      //中途撤销
      this.stage.preCutObjs.forEach(obj => (obj.preCut = false))
      return
    }
    const data_size = this.stage.preCutObjs.length
    let record = []
    for (let i = 0; i < data_size; i++) {
      let obj = this.stage.preCutObjs[i]
      this.deleteGraphic(obj)
      obj.preCut = false
      record.push(obj)
    }
    this.stage.editHistory.undoStack.at(-1).push({ action: 'delete', cellName: this.stage.getCurrentCellName(), objs: record })
    // this.stage.editHistory.record([{ action: 'delete', cellName: this.stage.getCurrentCellName(), objs: record }])
    this.stage.render()
  }
  //删除选中图形
  deleteCheckedObjs() {
    const data_size = this.stage.checked_objs.length

    let editRef = false
    for (let i = 0; i < data_size; i++) {
      let deleteObj = this.stage.checked_objs[i]
      if (deleteObj.isRefBox) {
        editRef = true
      }
      this.stage.cell.delete(deleteObj)
    }

    this.stage.editHistory.record([{ action: 'delete', cellName: this.stage.getCurrentCellName(), objs: this.stage.checked_objs }])
    this.stage.render()
    this.stage.qedaLib.write()
    if (editRef) {
      bus.$emit('updateFileTree', true)
    }
    this.syncLayerList()
  }

  //显示隐藏对象
  showHideLayer() {
    if (!this.stage.currentLayer) return
    if (!this.stage.cell) return
    const layerNum = this.stage.currentLayer.layerNumber
    const hide = this.stage.currentLayer.hide
    function hideLayer(obj) {
      if (obj.layer === layerNum) {
        obj.hide = hide
        if (hide) {
          obj.resetStatus()
        }
      }
    }
    Object.values(this.stage.qedaLib.cells).forEach(cell => cell.loopCellGraphics(hideLayer))

    Object.values(this.stage.qedaLib.cells).forEach(cell => {
      cell.showHideCell(this.stage.layerDatas)
    })
    this.stage.render()
  }
  //隐藏所有对象
  hideAllLayer(hide) {
    if (!this.stage.currentLayer) return
    if (!this.stage.cell) return
    const num = this.stage.currentLayer.layerNumber
    function hideAll(obj) {
      if (obj.layer !== num) {
        obj.hide = hide
        if (hide) {
          obj.resetStatus()
        }
      }
    }
    Object.values(this.stage.qedaLib.cells).forEach(cell => cell.loopCellGraphics(hideAll))
    Object.values(this.stage.qedaLib.cells).forEach(cell => {
      cell.showHideCell(this.stage.layerDatas)
    })
    this.stage.render()
  }

  //锁定对象
  lockLayer() {
    if (!this.stage.currentLayer) return
    if (!this.stage.cell) return
    const layerNum = this.stage.currentLayer.layerNumber
    const lock = this.stage.currentLayer.lock
    function lockLy(obj) {
      if (obj.layer === layerNum) {
        obj.lock = lock
        if (lock) {
          obj.resetStatus()
        }
      }
    }
    Object.values(this.stage.qedaLib.cells).forEach(cell => cell.loopCellGraphics(lockLy))
    Object.values(this.stage.qedaLib.cells).forEach(cell => {
      cell.showHideCell(this.stage.layerDatas)
    })
    this.stage.render()
  }

  //锁定所有对象
  lockAllLayer(lock) {
    function lockAllLy(obj) {
      obj.lock = lock
      if (lock) {
        obj.resetStatus()
      }
    }
    Object.values(this.stage.qedaLib.cells).forEach(cell => cell.loopCellGraphics(lockAllLy))
    Object.values(this.stage.qedaLib.cells).forEach(cell => {
      cell.showHideCell(this.stage.layerDatas)
    })
    this.stage.render()
  }

  //删除图层
  deleteLayerData(data) {
    const layers = data.map(layer => layer.layerNumber)
    Object.values(this.stage.qedaLib.cells).forEach(cell => cell.deleteLayer(layers))
    bus.$emit('fileChange', this.fileInfo.fileId)
  }

  //合并图层
  mergeLayers(from_layer_nums, to_layer_num) {
    let record = { objs: [], from: [], to: [] }
    let res = this.stage.cell.changeLayer(from_layer_nums, to_layer_num)
    record.objs.push(...res.objs)
    record.from.push(...res.from)
    record.to.push(...res.to)
    let refCells = this.stage.cell.QCELL.dependencies(true)
    for (let cell of refCells) {
      let result = this.stage.cells[cell.name].changeLayer(from_layer_nums, to_layer_num)
      record.objs.push(...result.objs)
      record.from.push(...result.from)
      record.to.push(...result.to)
    }
    let action = { action: 'mergeLayers', objs: record.objs, from: record.from, to: record.to }

    this.stage.editHistory.record([action])
  }

  //批量修改图层
  batchChangeLayers(objs, to_layer_num) {
    let record = { objs: objs, from: [], to: [] }
    objs.forEach(obj => {
      record.from.push(obj.layer)
      record.to.push(to_layer_num)
    })
    let action = { action: 'mergeLayers', objs: record.objs, from: record.from, to: record.to }
    this.stage.editHistory.record([action])
  }
  //对齐
  alignmentObjs(params) {
    const data_size = this.stage.checked_objs.length
    if (!data_size) return
    this.stage.recordHistory('transform')
    const data = this.stage.checked_objs
    const center = this.stage.checkedGraphicsAABB_center
    const aabb = this.stage.checkedGraphicsAABB
    const minX = aabb[0][0]
    const minY = aabb[0][1]
    const maxX = aabb[1][0]
    const maxY = aabb[1][1]
    const offset = [0, 0] //[parseFloat(params[4]), parseFloat(params[6])]//对齐偏移
    let alignType = params[0]
    // let layer = params[1]

    let objs_minX = data[0].aabb_center[0]
    let objs_minY = data[0].aabb_center[1]
    if (alignType === '竖向中线对齐' || alignType === '横向中线对齐') {
      for (let i = 0; i < data_size; i++) {
        // if (data[i].layer !== layer) continue
        //查询最小横竖中线
        if (objs_minX > data[i].aabb_center[0]) {
          objs_minX = data[i].aabb_center[0]
        }
        if (objs_minY > data[i].aabb_center[1]) {
          objs_minY = data[i].aabb_center[1]
        }
      }
    }
    for (let i = 0; i < data_size; i++) {
      const obj = data[i]
      if (obj.type === QedaGraphicType.LABEL || obj.type === QedaGraphicType.KEYPOINT) return
      // if (data[i].layer !== layer) continue
      const p = obj.position
      switch (alignType) {
        case '左对齐': //左对齐
          p[0] = minX + p[0] - obj.aabb[0][0] + offset[0]
          break
        case '右对齐': //右对齐
          p[0] = maxX - obj.aabb[1][0] + p[0] + offset[0]
          break
        case '下对齐': //下对齐
          p[1] = minY + p[1] - obj.aabb[0][1] + offset[1]
          break
        case '上对齐': //上对齐
          p[1] = maxY - obj.aabb[1][1] + p[1] + offset[1]
          break
        case '竖向中线对齐': //中垂线对对齐
          p[0] = objs_minX + offset[0]
          break
        case '横向中线对齐': //中水平线对齐
          p[1] = objs_minY + offset[1]
          break
        case 6: //中心对齐
          obj.position = center
          obj.position[0] += offset[0]
          obj.position[1] += offset[1]
          break
      }
      obj.updateShape()
    }
    return
  }

  //水平翻转
  flipX(offSetX = 0) {
    const length = this.stage.checked_objs.length
    if (!length) return
    this.stage.recordHistory('shape')
    const positions = []
    for (let i = 0; i < length; i++) {
      positions.push(this.stage.checked_objs[i].aabb_center)
    }

    let temp = new QGdstk.Polygon(positions)

    const X = this.stage.checkedGraphicsAABB_center[0] + offSetX
    temp.mirror([X, 0], [X, 1])
    let new_pos = temp.get_points()
    for (let i = 0; i < length; i++) {
      const obj = this.stage.checked_objs[i]
      obj.position[0] = new_pos[i][0] + obj.aabb_center[0] - obj.position[0]
      obj.position[1] = new_pos[i][1] - obj.aabb_center[1] + obj.position[1]
      if (obj.isRefBox) {
        obj._scale[1] *= -1
        obj.rotation -= 180
        obj.updateShape()
      } else {
        if (obj.type === QedaGraphicType.LABEL || obj.type === QedaGraphicType.KEYPOINT) continue
        if (obj.type === QedaGraphicType.CIRCLE) {
          // obj.position = new_pos[i]
        } else {
          // obj.position = new_pos[i]
          obj.updateShape()
          let center_x = obj.aabb_center[0]
          obj.gds_data_global.mirror([center_x, 0], [center_x, 1])
          obj.resetTransform()
        }
        obj.setPoints()
        obj.updateSlopes()
      }
    }
    this.stage.render()
  }

  //垂直翻转
  flipY(offSetY = 0) {
    const length = this.stage.checked_objs.length
    if (!length) return
    this.stage.recordHistory('shape')
    const positions = []
    for (let i = 0; i < length; i++) {
      positions.push(this.stage.checked_objs[i].aabb_center)
    }
    let temp = new QGdstk.Polygon(positions)

    const Y = this.stage.checkedGraphicsAABB_center[1] + offSetY
    temp.mirror([0, Y], [1, Y])
    let new_pos = temp.get_points()
    for (let i = 0; i < length; i++) {
      const obj = this.stage.checked_objs[i]
      obj.position[0] = new_pos[i][0] - obj.aabb_center[0] + obj.position[0]
      obj.position[1] = new_pos[i][1] + obj.aabb_center[1] - obj.position[1]
      if (obj.isRefBox) {
        obj._scale[1] *= -1
        obj.updateShape()
      } else {
        if (obj.type === QedaGraphicType.LABEL || obj.type === QedaGraphicType.KEYPOINT) continue
        if (obj.type === QedaGraphicType.CIRCLE) {
          // obj.position = new_pos[i]
        } else {
          // obj.position = new_pos[i]
          obj.updateShape()
          let center_y = obj.aabb_center[1]
          obj.gds_data_global.mirror([0, center_y], [1, center_y])
          obj.resetTransform()
        }
        obj.setPoints()
        obj.updateSlopes()
      }
    }
    this.stage.render()
  }
  //当前cell添加图形
  addGraphic(graphic) {
    if (this.stage.editReference) {
      this.stage.editReference.cell.add(graphic)
    } else {
      this.stage.cell.add(graphic)
    }
  }
  //当前cell删除图形
  deleteGraphic(graphic) {
    if (this.stage.editReference) {
      this.stage.editReference.cell.delete(graphic)
    } else {
      this.stage.cell.delete(graphic)
    }
  }

  //修改文件内容更新并更新文件
  updateFile() {
    this.stage.qedaLib.write()
    bus.$emit('updateFileTree', true)
  }
  //设置画板关联文件和打开器件名
  setFileAndCell(file, cellName) {
    //不存在cell

    if (!file.cells[cellName]) {
      let QLIB = window.QGdstk.read_gds(this.fileInfo.fileId)

      let cell = QLIB.cells.filter(cell => cell.name === cellName)[0]

      //另存为器件
      if (cell) {
        let deps = cell.dependencies(true) //.filter(newCell => !QLIB.cells.filter(existCell => existCell.name === newCell.name).length)
        file.addExistCell(cell)
        //复制到自己，判断调用cell没有转QCELL一起生成
        deps.forEach(dep => {
          if (!file.cells[dep.name]) {
            file.addExistCell(dep)
          }
        })
        this.stage.cells = file.cells
      } else {
        //不存在cell
        file.addCell(cellName)
      }
    }
    setTimeout(() => {
      this.stage.qedaLib = file
      this.stage.cells = this.stage.qedaLib.cells
      this.stage.cell = this.stage.cells[cellName]
      this.stage.setCurrentEditCell()
      // this.stage.setPaints()
      // this.generateFillImg()

      this.syncLayerList()
      bus.$emit('syncCellList', file.fileID)
      this.renderCell()
    })
  }

  //导入解析Qlibrary
  parseQlibrary(cellName) {
    let newFile = new QedaLibrary(this.fileInfo.name, this.fileInfo.fileId, true) //根据Qgds构建新的QedaLib
    this.stage.qedaLib = newFile
    this.stage.cells = this.stage.qedaLib.cells
    this.stage.cell = this.stage.cells[cellName]
    this.stage.setCurrentEditCell()

    this.renderCell()
  }

  //QgdstkCell => QedaCell
  generateCell(cell) {
    const new_cell = new QedaCell()
    new_cell.name = cell.name
    new_cell.aabb = cell.bounding_box()
    // new_cell.file_ref = cell
    //非引用cell
    const path_length = cell.paths.length
    const paths = cell.paths
    const polygons_length = cell.polygons.length
    const polygons = cell.polygons
    for (let i = 0; i < path_length; i++) {
      let path = paths[i]
      const obj = QedaGraphics.drawPath(path.get_points())
      obj.layer = 1 //!path.layer ? 8 : path.layer
      new_cell.paths.push(obj)
    }
    for (let i = 0; i < polygons_length; i++) {
      let polygon = polygons[i]
      const obj = QedaGraphics.drawPolygon(polygon.get_points())
      obj.layer = 1 //!polygon.layer ? 8 : polygon.layer
      new_cell.polygons.push(obj)
    }
    return new_cell
  }

  generateCellRefs(cell) {
    const result = []
    const ref_length = cell.references.length
    const references = cell.references
    for (let i = 0; i < ref_length; i++) {
      result.push(this.generateReferences(references[i]))
    }
    return result
  }

  //QgdstkRef => QedaRef
  generateReferences(ref) {
    const new_ref = new QedaReference()
    new_ref.name = ref.cell.name
    new_ref.origin = ref.origin
    new_ref.magnification = ref.magnification
    new_ref.x_reflection = ref.x_reflection ? -1 : 1
    new_ref.rotation = (ref.rotation * 180) / Math.PI //弧度->角度
    // new_ref.file_ref = ref
    // new_ref.parents = [new_ref]
    let points = ref.cell.bounding_box()
    let rect = new QGdstk.rectangle(points[0], points[1], 0, 0)
    new_ref.box = QedaGraphics.drawPolygon([[0, 0]])
    new_ref.box.hide = false
    new_ref.box.lock = false
    new_ref.box.isRefBox = true
    new_ref.box.reference = new_ref
    new_ref.box.setRefBoxPoints(rect.get_points())
    new_ref.cell = this.stage.cells[ref.cell.name]
    return new_ref
  }

  // generateNewCell() {
  //     this.stage.cell = new QedaCell()
  //     this.stage.setCurrentEditCell()
  //     this.stage.cells[this.stage.cell.name] = this.stage.cell
  //     this.stage.qedaLib = new QedaLibrary()
  //     this.stage.qedaLib.cells = this.stage.cells
  // }

  //服务器获取Cell
  // async getCell() {
  //     if (!this.file_snow_id) {
  //         return
  //     }
  //     let result = getCellFile_api({
  //         file_snow_id: this.file_snow_id,
  //     });
  //     await result.then(data => {
  //         if (data.message === "获取文件失败") {
  //             this.generateNewCell()
  //         } else {
  //             const blob = new Uint8Array(data.split(','))
  //             window.QGdstk.FS.writeFile('cell', blob)
  //             var gds = window.QGdstk.read_gds('cell')
  //             // if (!gds.name){
  //             this.generateNewCell()
  //             return
  //             // }
  //             this.gdsFile = gds
  //             this.cellLayers = []
  //             this.stage.cells = {}
  //             for (let cell of gds.cells) {
  //                 this.stage.cells[cell.name] = this.generateCell(cell)
  //             }
  //             for (let cell of gds.cells) {
  //                 this.stage.cells[cell.name].references = this.generateCellRefs(cell)
  //             }
  //             this.cellLayers = Array.from(new Set(this.cellLayers))

  //             this.stage.cell = this.stage.cells[gds.name]
  //             if (!this.stage.cell) {
  //                 this.generateNewCell()
  //             }
  //         }

  //         this.stage.qedaLib.cells = this.stage.cells
  //         this.renderCell()
  //     })
  // }

  //保存
  save() {
    // this.addExtraCells()
    if (!this.file_snow_id) return
    // const lib = this.generateQgdstkLib()
    let id = this.stage.qedaLib.fileID
    let qedaLib = this.stage.qedaLib
    let lib = qedaLib.QLIB

    // lib = qedaLib.getCellQlib(this.stage.cell.name, 'newFile')

    // this.exportGds(lib, 'test.gds')
    lib.write_gds(id)
    let file = window.QGdstk.FS.readFile(id)
    var formData = new FormData() //新建表单对象
    formData.append('file_snow_id', this.file_snow_id)

    let layers = []
    let layerDatas = store.state.layerDatas.filter(item => this.fileInfo.fileId === item.file_snow_id)[0].layerDatas
    layerDatas.forEach(obj =>
      layers.push({
        border: obj.border,
        borderColor: obj.borderColor,
        color: obj.color,
        isExport: obj.isExport,
        layerName: obj.layerName,
        layerNumber: obj.layerNumber,
        lock: obj.lock,
        hide: obj.hide,
        shape: obj.shape,
      })
    )
    formData.append('layer_infos', JSON.stringify(layers))
    formData.append('file', new Blob([file])) //把文件二进制对象添加到表单对象里
    let save = saveCellFile_api(formData)
    save.then(data => {
      if (data.code === 200000) {
        bus.$emit('saveFileSuccessToFile', true)
        bus.$emit('saveFileSuccess', this.fileInfo.fileId)
      } else {
        Message.error(data.message)
      }
    })
  }

  exportGds(lib, filename) {
    lib.write_gds(filename)
    var export_gds = window.QGdstk.read_gds(filename)
    let remove_layers = this.getNotExportLayerList()
    for (let i = 0; i < export_gds.cells.length; i++) {
      let cell = export_gds.cells[i]
      cell.filter(remove_layers, true, true, true, true)
      const paths = cell.paths
      for (let j = 0; j < paths.length; j++) {
        let path = paths[j]
        //线宽
        if (path.get_gds_property(0)) {
          if (JSON.parse(path.get_gds_property(0))) {
            path.simple_path = false
            //半径
            if (path.get_gds_property(1) && JSON.parse(path.get_gds_property(1))) {
              path.set_joins(['round'])
              path.bend_radius[0] = JSON.parse(path.get_gds_property(1))
            }
          }
        }

        // let path2 = new QGdstk.FlexPath(path.get_points(), 50, 0, 'round', 'flush', 50, null, 1e-2, false, true, 0, 0)
        // let polygons = path2.to_polygons()

        // paths[j] = new QGdstk.FlexPath(path.get_points(), 50, 0, 'round', 'flush', 50, null, 1e-2, false, true, 0, 0)
      }

      const labels = cell.labels
      let delete_labels = []
      for (let j = 0; j < labels.length; j++) {
        const label = labels[j]
        let key_point = label.get_gds_property(1)
        // let params_device = label.get_gds_property(2)
        // let character_device = label.get_gds_property(3)
        if (key_point) {
          //未创建标签的关键点不导出
          if (!JSON.parse(key_point).create_label) {
            delete_labels.push(label)
          }
        }
        // if (params_device || character_device) {
        //   delete_labels.push(label)
        // }
      }

      cell.remove(delete_labels)
    }
    export_gds.write_gds('export_file')
    let mime = 'application/octet-stream'
    let content = QGdstk.FS.readFile('export_file')

    var download_link = document.getElementById('download_gds')
    if (download_link == null) {
      download_link = document.createElement('a')
      download_link.id = 'download_gds'
      download_link.style.display = 'none'
      document.body.appendChild(download_link)
    }
    download_link.download = filename
    download_link.href = URL.createObjectURL(
      new Blob([content], {
        type: mime,
      })
    )
    download_link.click()
  }
  //QedaLib => Qgdstklib 反向生成lib向后端保存文件
  generateQgdstkLib() {
    const qedaLib = this.stage.qedaLib
    let currentCell = this.stage.cell
    if (!currentCell) {
      currentCell = new QedaCell()
    }
    const qedaCells = this.stage.cells

    let gdsLib = new QGdstk.Library(qedaLib.name, qedaLib.unit, qedaLib.precision)
    for (let name in qedaCells) {
      let cell = gdsLib.new_cell(name)
      const path_length = qedaCells[name].paths.length
      const polygons_length = qedaCells[name].polygons.length
      const ellipses_length = qedaCells[name].ellipses.length
      for (let j = 0; j < path_length; j++) {
        let obj = qedaCells[name].paths[j]
        let p = new QGdstk.FlexPath(obj.shape, 1)
        p.layer = obj.layer
        cell.add(p)
      }
      for (let k = 0; k < polygons_length; k++) {
        let obj = qedaCells[name].polygons[k]
        let p = new QGdstk.Polygon(obj.shape)
        p.layer = obj.layer
        cell.add(p)
      }
      for (let l = 0; l < ellipses_length; l++) {
        let obj = qedaCells[name].ellipses[l]
        let e = obj.getGdsData()
        e.layer = obj.layer
        cell.add(e)
      }
    }

    for (let name in qedaCells) {
      const cell = gdsLib.cells.filter(item => item.name === name)[0]
      const ref_length = qedaCells[name].references.length

      for (let k = 0; k < ref_length; k++) {
        let ref = qedaCells[name].references[k] //Qeda Ref
        let ref_cell = gdsLib.cells.filter(item => item.name === ref.cell.name)[0]
        let r = new QGdstk.Reference(ref_cell, [ref.origin[0], ref.origin[1]], (ref.rotation * Math.PI) / 180, ref.magnification, false, 1, 1, null) //构建gdstk ref
        cell.add(r)
      }
    }
    return gdsLib
  }

  //更新cell 引用 box
  updateCellRefBox(cell) {
    const ref_length = this.stage.cell.references.length
    for (let i = 0; i < ref_length; i++) {
      let ref = this.stage.cell.references[i]
      let points = ref.cell.bounding_box()
      let rect = new QGdstk.rectangle(points[0], points[1], 0, 0)
      // ref.box.delete()
      // ref.box = QedaGraphics.drawPolygon([[0, 0]])
      // ref.box.isRefBox = true
      // ref.box.reference = ref
      ref.box.setRefBoxPoints(rect.get_points())
    }
  }

  //根据qedaLib和顶层器件重渲染
  setQedaLib(qedaLib, cellName) {
    this.stage.name = cellName
    this.stage.qedaLib = qedaLib
    this.stage.cells = qedaLib.cells
    this.stage.qedaLib.cells = this.stage.cells
    this.renderCell()
  }

  //渲染cell
  renderCell() {
    // this.stage.cell.QCELL.labels.forEach(label => {

    // })
    let box = this.stage.cell.aabb

    let no_graphics = !box || (box[0][0] == box[0][1] && box[0][0] == box[1][0] && box[0][0] == box[1][1])
    if (!no_graphics) {
      this.stage.adaptAABB(box)
      this.stage.myaxis.render()
      this.stage.drawRulerX()
      this.stage.drawRulerY()
    }
    this.stage.render()
  }

  undo() {
    this.stage.pre_checked_obj = null
    this.drawingObject = null
    this.pre_copy_data = null
    this.copyData = null
    this.copyDataCenter = null
    this.stage.preCutObjs.forEach(obj => (obj.preCut = false))
    let undos = this.stage.editHistory.undo()
    let undo_len = undos?.length
    if (!undo_len) return
    this.fileChange()
    if (undo_len) {
      for (let i = 0; i < undo_len; i++) {
        //有些操作同时带有多个操作
        this.stage.undo(undos[i])
      }
      this.updateCellRefBox(this.stage.cell)
      this.stage.render()
      this.compareFileLayers(this.stage.qedaLib.QLIB.cells)
      this.syncLayerList()
      return
    }
  }

  redo() {
    let redos = this.stage.editHistory.redo()
    let redo_len = redos?.length
    if (!redo_len) return
    this.fileChange()
    if (redo_len) {
      for (let i = 0; i < redo_len; i++) {
        //有些操作同时带有多个操作
        this.stage.redo(redos[i])
      }
      this.updateCellRefBox(this.stage.cell)
      this.stage.render()
      this.compareFileLayers(this.stage.qedaLib.QLIB.cells)
      this.syncLayerList()
      return
    }
    return
  }

  cutting() {
    if (this.stage.editReference) return
    if (this.stage.checkMode !== 0 && this.stage.checkMode !== 4) return
    this.stage.checkMode = 3
  }
  areaExcision() {
    if (this.stage.editReference) return
    if (this.stage.checkMode === 0) {
      this.stage.checkMode = 2
      this.drawType = 'cutBox'
    }
  }

  switchDrawFunc(data) {
    if (this.stage.checked_objs.length) {
      // if (this.stage.rotat_center && this.stage.myaxis.left_drag) {
      if (this.stage.myaxis.left_drag) {
        this.stage.myaxis.left_drag = false
        this.undo()
      } else if (this.stage.moveMod === 1 && this.stage.movePosition.length) {
        this.stage.moveMod = 0
        this.stage.movePosition = []
        this.undo()
      }
    }
    this.resetBoardStatus()
    this.axis.needAdsorption = true
    this.stage.rotation = 0
    this.stage.rotat_center = null
    this.last_mouse_pos = null
    this.drawType = data
    this.stage.check_able = data === 'selectBox'
    if (this.stage.check_able && this.stage.moveMod === 0) {
      // bus.$emit('cancelSidebarSelect')
    }
    if (!this.stage.check_able) {
      //重置所有选中物体状态
      let length = this.stage.checked_objs.length
      for (let i = 0; i < length; i++) {
        this.stage.checked_objs[i].resetStatus()
      }
      this.stage.render()
    }
    if (this.drawingObject) {
      // this.drawingObject.delete()
      this.deleteGraphic(this.drawingObject)
      this.drawingObject = null
      this.stage.render()
    }
    this.updateAxis()
  }

  drawCharacter(addTocell = true, text = 'Created with GDSTK', layer = 0, anchor = '', size = 500, rotation = 0, pos = [0, 0], vertical = false) {
    // rotation = (rotation * Math.PI) / 180 //角度转弧度
    let input_size = size
    size = parseFloat(size)
    if (!size || size < 0) {
      size = 100
    }
    size = parseFloat(size) + parseFloat(size) * 0.15
    let txt = new QGdstk.text(text, size, pos, vertical, layer, 0)
    if (!txt.length) return

    let targetCell = new QGdstk.Cell(this.generateUniqueCellName('Character'))
    targetCell.add(txt)
    let cell_box = targetCell.bounding_box() //整体包围盒
    const start_x = cell_box[0][0]
    const start_y = cell_box[0][1]
    let w = cell_box[1][0] - start_x
    let h = cell_box[1][1] - start_y
    let cell_box_center = [start_x + w / 2, start_y + h / 2]
    let first_obj_box = txt[0].bounding_box() //首字符包围盒

    let trans = [-start_x, -start_y]
    if (anchor === 'o') {
      trans[0] += first_obj_box[0][0] - cell_box_center[0]
      trans[1] += first_obj_box[0][1] - cell_box_center[1]
    } else if (anchor === 's') {
      trans[0] += first_obj_box[0][0] - cell_box_center[0]
    } else if (anchor === 'n') {
      trans[0] += first_obj_box[0][0] - cell_box_center[0]
      trans[1] += -first_obj_box[1][1]
    } else if (anchor === 'e') {
      trans[0] += -w
      trans[1] += first_obj_box[0][1] - cell_box_center[1]
    } else if (anchor === 'w') {
      trans[1] += first_obj_box[0][1] - cell_box_center[1]
    } else if (anchor === 'se') {
      trans[0] += -w
    } else if (anchor === 'nw') {
      trans[1] += -h
    } else if (anchor === 'ne') {
      trans[0] += -w
      trans[1] += -h
    } else if (anchor === 'sw') {
    }

    txt.forEach(t => {
      t.translate(trans)
    })
    let label = new QGdstk.Label('', this.getBoundingBoxCenter(targetCell.bounding_box()), 'o', 0, 0, false, layer, 0)
    let obj = {
      font_size: input_size,
      text: text,
      anchor: anchor,
      layer: layer,
    }
    label.set_gds_property(3, JSON.stringify(obj)) //Text Graphics 文本图形标记 文字大小 文字内容 图层信息
    targetCell.add(label)
    //向当前cell添加文本图形
    if (addTocell) {
      // this.switchDrawFunc('selectBox')
      const ref_box = this.stage.qedaLib.addDevice(targetCell, [targetCell])
      ref_box.rotation = rotation
      ref_box.updateShape()
      ref_box.preAdd = true
      this.stage.preAddCell = ref_box
      this.stage.cell.add(ref_box)
      // this.preAddObj = ref_box
      // this.stage.cell.add(ref_box)
      // let action1 = { action: 'add', cellName: this.stage.getCurrentCellName(), objs: [ref_box] }
      // let action2 = { action: 'addCell', objs: [targetCell] }
      // this.stage.editHistory.record([action1, action2])
      this.stage.render()
      return ref_box
    } else {
      //更新文本图形
      return targetCell
    }
  }

  //根据有宽度的线生成多边形
  generateWaveGuide() {
    let line_width = 2
    let inner_width = 4
    let out_width = 4 + line_width * 2
    let radius = 20
    let path = this.stage.checked_objs[0]
    if (!path) return
    let pologons = this.waveGuide(path.shape, out_width, inner_width, radius, 0, 0)
    let addObjs = this.stage.cell.addGdsData(pologons)
    this.deleteGraphic(path)
    let action1 = { action: 'delete', cellName: this.stage.cell.name, objs: [path] }
    let action2 = { action: 'add', cellName: this.stage.cell.name, objs: addObjs }
    this.stage.editHistory.record([action1, action2])
    this.stage.render()
  }

  //生成共电波导
  waveGuide(points = [], out_width = 8, inner_width = 4, radius = 20, layer = 0, datatype = 0) {
    function add_line(p1, p2) {
      let dx = p2[0] - p1[0]
      let dy = p2[1] - p1[1]
      let x_step = dx / 2
      let y_step = dy / 2
      return [p2[0] + x_step, p2[1] + y_step]
    }
    let len = points.length
    if (len < 2) {
      return []
    }
    // let outer = new QGdstk.FlexPath(
    //   (points = points),
    //   (width = out_width),
    //   (offset = 0),
    //   (joins = 'round'),
    //   (ends = 'flush'),
    //   (bend_radius = radius),
    //   (bend_function = null),
    //   (tolerance = 1e-2),
    //   (simple_path = false),
    //   (scale_width = true),
    //   (layer = layer),
    //   (datatype = datatype)
    // )
    let outer = new QGdstk.FlexPath(points, out_width, 0, 'round', 'flush', radius, null, 1e-2, false, true, layer, datatype)
    // 延长起点和终点，以免误差
    points[0] = add_line(points[1], points[0])
    points[len - 1] = add_line(points[len - 2], points[len - 1])

    // let inner = new QGdstk.FlexPath(
    //   (points = points),
    //   (width = inner_width),
    //   (offset = 0),
    //   (joins = 'round'),
    //   (ends = 'flush'),
    //   (bend_radius = radius),
    //   (bend_function = null),
    //   (tolerance = 1e-2),
    //   (simple_path = false),
    //   (scale_width = true),
    //   (layer = layer),
    //   (datatype = datatype)
    // )
    let inner = new QGdstk.FlexPath(points, inner_width, 0, 'round', 'flush', radius, null, 1e-2, false, true, layer, datatype)
    // let res = QGdstk.boolean((operand1 = outer), (operand2 = inner), (operation = 'not'), (precision = 1e-3), (layer = layer), (datatype = datatype))
    let res = QGdstk.boolean(outer, inner, 'not', 1e-3, layer, datatype)
    return res
  }

  generateLabel(keyPoint = false, layer, txt, font_size, dirextion, createLabel) {
    if (!this.stage.currentLayer || this.stage.currentLayer.hide) return //当前图层隐藏禁止绘制
    let mouse_pos = this.getMousePostion()
    let pos = [mouse_pos.x, mouse_pos.y]
    // let font_size = 30
    let QDATA = new QGdstk.Label(txt, pos, dirextion, 0, 1, false, layer, 0)
    // alert(layer)
    if (keyPoint) {
      QDATA.layer = 0
      QDATA.text = txt
      QDATA.anchor = 's'
      let data = {
        create_label: createLabel,
      }
      QDATA.set_gds_property(1, JSON.stringify(data)) //设置关键点属性 存放关键点名称, 是否创建标签属性
    }
    // let params = {
    //   font_size,
    //   rotation: 0,
    // }
    QDATA.set_gds_property(0, JSON.stringify(font_size))
    let label = QedaGraphics.drawLabel(getLabelBox(QDATA), false, QDATA)
    label.layer = layer
    this.preAddObj = label
    this.preAddObj.preAdd = true
    // this.switchDrawFunc('selectBox')
    this.addGraphic(label)
    this.copyLabelData = QedaGraphics.copy(label, label.position)
    // this.stage.editHistory.record([{ action: 'add', cellName: this.stage.getCurrentCellName(), objs: [label] }])
  }

  //生成唯一cell名
  generateUniqueCellName(name) {
    function nanoid(size = 21) {
      let id = ''
      let i = size
      while (i--) {
        id += urlAlphabet[(Math.random() * 63) | 0]
      }
      return id
    }
    return name + '_' + nanoid(6)
  }

  //文件修改
  fileChange() {
    bus.$emit('fileChange', this.fileInfo.fileId)
  }

  //计算盒子中心点
  getBoundingBoxCenter(aabb) {
    const start_x = aabb[0][0]
    const start_y = aabb[0][1]
    let w = aabb[1][0] - start_x
    let h = aabb[1][1] - start_y
    return [start_x + w / 2, start_y + h / 2]
  }

  //对比图层新建图层
  compareFileLayers(cells) {
    let lib = new QGdstk.Library('temp', 0.000001, 1e-9)
    lib.add(cells)
    let currentFileLayers = store.state.layerDatas.filter(item => this.fileInfo.fileId === item.file_snow_id)[0].layerDatas.map(ly => ly.layerNumber)
    let layers = lib.layers_and_datatypes().map(data => data[0])
    layers.push(...lib.layers_and_texttypes().map(data => data[0]))

    let addLayers = layers.filter(layer => currentFileLayers.indexOf(layer) == -1)
    if (addLayers.length) {
      addLayers = Array.from(new Set(addLayers))

      lib.remove(cells)
      bus.$emit('batchCreateLayers', addLayers)
      this.stage.setPaints()

      this.generateFillImg('All')
    }
  }

  //根据图层名字获取图层编号
  getLayerNumByName(name) {
    let layerDatas = store.state.layerDatas.filter(item => this.fileInfo.fileId === item.file_snow_id)[0].layerDatas
    return layerDatas.filter(ly => ly.layerName === name)[0].layerNumber
  }

  deletePreAddCell() {
    if (this.preAddObj) {
      this.deleteGraphic(this.preAddObj)
      this.updateFile()

      this.syncLayerList()
    }
    if (this.stage.preAddCell) {
      if (!this.stage.preAddCell.selfDevice) {
        let cellname = this.stage.preAddCell.reference.cell.name
        let refCells = this.stage.qedaLib.cells[cellname].QCELL.dependencies(true)
        //删除cell依赖的cells
        for (let cell of refCells) {
          this.stage.qedaLib.deleteCell(cell.name)
        }
        //删除添加的cell
        this.stage.qedaLib.deleteCell(cellname)
      }

      //删除添加cell的reference
      this.stage.cell.delete(this.stage.preAddCell)
      this.stage.preAddCell = null
    }
  }

  addExtraCells() {
    let QLIB = window.QGdstk.read_gds(this.fileInfo.fileId)
    let file = this.stage.qedaLib
    QLIB.cells.forEach(cell => {
      let cellName = cell.name
      if (!file.cells[cellName]) {
        //另存为器件
        if (cell) {
          file.addExistCell(cell)
        }
      }
    })
    this.stage.cells = file.cells
  }

  getNotExportLayerList() {
    let result = []
    let layerDatas = store.state.layerDatas.filter(item => this.fileInfo.fileId === item.file_snow_id)[0].layerDatas
    layerDatas.forEach(layer => {
      if (!layer.isExport) {
        result.push([layer.layerNumber, 0])
      }
    })
    return result
  }

  resetBoardStatus() {
    if (this.stage.checkMode === 6) {
      if (this.copyData) {
        this.stage.preCutObjs.forEach(obj => (obj.preCut = false))
        this.undo()
      }
    }
    this.stage.pre_checked_obj = null
    this.stage.resetStatus()
    this.stage.checkMode = this.getCurrentCheckMode()
    // bus.$emit('cancelSidebarSelect')
    this.isCut = false
    this.copyData = null
    this.pre_copy_data = null
    this.copyDataCenter = null
    this.deletePreAddCell()
  }

  copyObjs(objs) {
    const data_size = objs.length
    const copy_data = []
    for (let j = 0; j < data_size; j++) {
      const copy = QedaGraphics.copy(objs[j], objs[j].position)
      copy_data.push(copy)
    }
    return copy_data
  }

  setToolState() {
    this.stage.myaxis.adsorpPoint = store.state.adsorptionState
    this.stage.myaxis.adsorp_precision = parseFloat(store.state.precision)
    this.stage.myaxis.angledAdsorption = store.state.boardPropertyState[0].func === 'angledAdsorption'
    this.stage.isStretch = store.state.boardPropertyState[1].func === 'lockStretch'
    if (store.state.boardPropertyState[2].func === 'overallSelection') {
      bus.$emit('overallSelection', true)
    } else {
      bus.$emit('partialSelection', true)
    }
  }

  getCurrentCheckMode() {
    if (store.state.boardPropertyState[2].func === 'overallSelection') {
      return 0
    } else {
      return 1
    }
  }
  //同步图层
  syncLayerList() {
    let layers = this.stage.cell.getLayers()
    bus.$emit('syncLayerList', layers)
  }

  async lockFile(fileInfo) {
    let lock = lockCell_api({ file_snow_id: fileInfo.fileId })
    lock.then(data => {
      if (data.code === 200000) {
        this.stage.viewMode = false
      } else {
        // Message.warning(data.message)
        this.stage.viewMode = true
      }
    })
  }
  async route3DView() {
    let proj_gns = store.state.proInfo.projectId
    let pdk_res = await getPDKInfo_api({ project_snow_id: proj_gns })
    let xst_data
    if (pdk_res.code === 200000) {
      var config = pdk_res.data.url_data

      if (config.xst) {
        // 获取drf文件内容并解析为json
        let xst_u8 = await getObjectDataFn(config.xst)

        let xst_fr = new FileReader()
        xst_fr.readAsText(new Blob([xst_u8]))
        let result = new Promise(resolve => {
          xst_fr.onload = evt => {
            resolve(evt)
          }
        })
        await result.then(evt => {
          xst_data = JSON.parse(evt.target.result) //解析工艺文件数据
        })
      }
    }
    let pathInfo = router.resolve({
      path: '/3d-view',
    })

    window['3DViewCell'] = null
    window['CrossSectionProcess'] = null
    window['3DViewCellCutBox'] = null
    if (xst_data?.CrossSectionProcess) {
      window['CrossSectionProcess'] = xst_data.CrossSectionProcess
    }
    // window['CrossSectionProcess'] = [
    //   {
    //     Step: 'deposit',
    //     Layer: null,
    //     LayerNum: null,
    //     Material: 'Si',
    //     MaterialLabel: 'Si',
    //     AngleDegree: null,
    //     Depth: 500,
    //     Color: '#000000',
    //     Comment: '',
    //   },
    //   {
    //     Step: 'deposit',
    //     Layer: null,
    //     LayerNum: null,
    //     Material: 'Al',
    //     MaterialLabel: 'Al-1',
    //     AngleDegree: null,
    //     Depth: 100,
    //     Color: '#00ff00',
    //     Comment: '',
    //   },
    //   {
    //     Step: 'etch',
    //     Layer: 'Metal1',
    //     LayerNum: 1,
    //     Material: 'Al',
    //     MaterialLabel: 'Al-1',
    //     AngleDegree: null,
    //     Depth: 100,
    //     Color: null,
    //     Comment: 'base layer',
    //   },
    //   [
    //     {
    //       Step: 'deposit',
    //       Layer: 'Junction-2',
    //       LayerNum: 15,
    //       Material: 'Al',
    //       MaterialLabel: 'Al-2',
    //       AngleDegree: null,
    //       Depth: 26,
    //       Color: '#ffff00',
    //       Comment: '',
    //     },
    //     {
    //       Step: 'deposit',
    //       Layer: 'Junction-3',
    //       LayerNum: 13,
    //       Material: 'Al',
    //       MaterialLabel: 'Al-2',
    //       AngleDegree: null,
    //       Depth: 26,
    //       Color: '#ff8000',
    //       Comment: '',
    //     },
    //   ],
    //   [
    //     {
    //       Step: 'deposit',
    //       Layer: 'Junction-1',
    //       LayerNum: 16,
    //       Material: 'Al',
    //       MaterialLabel: 'Al-3',
    //       AngleDegree: null,
    //       Depth: 69,
    //       Color: '#ff0000',
    //       Comment: '',
    //     },
    //     {
    //       Step: 'deposit',
    //       Layer: 'Junction-3',
    //       LayerNum: 13,
    //       Material: 'Al',
    //       MaterialLabel: 'Al-3',
    //       AngleDegree: null,
    //       Depth: 69,
    //       Color: '#ff8000',
    //       Comment: '',
    //     },
    //   ],
    //   {
    //     Step: 'deposit',
    //     Layer: 'Bandage',
    //     LayerNum: 14,
    //     Material: 'Al',
    //     MaterialLabel: 'Al-4',
    //     AngleDegree: null,
    //     Depth: 250,
    //     Color: '#ff0000',
    //     Comment: '',
    //   },
    //   {
    //     Step: 'deposit',
    //     Layer: 'Metal3',
    //     LayerNum: 3,
    //     Material: 'Al',
    //     MaterialLabel: 'Al-5',
    //     AngleDegree: null,
    //     Depth: 435,
    //     Color: '#0000ff',
    //     Comment: '',
    //   },
    //   {
    //     Step: 'deposit',
    //     Layer: 'Metal4',
    //     LayerNum: 5,
    //     Material: 'Al',
    //     MaterialLabel: 'Al-6',
    //     AngleDegree: 42,
    //     Depth: 635,
    //     Color: '#800080',
    //     Comment: '',
    //   },
    // ]
    if (this.stage.cell.cell3DCutBox.length) {
      let box = this.stage.cell.cell3DCutBox[0].aabb
      let w = box[1][0] - box[0][0]
      let h = box[1][1] - box[0][1]
      let center = [box[0][0] + w / 2, box[0][1] + h / 2]
      window['3DViewCellCutBox'] = { w, h, center }
    }
    let cell3dArea = null
    if (this.stage.cell.cell3DArea.length) {
      let obj = this.stage.cell.cell3DArea[0]
      cell3dArea = obj.getGdsData()
    } else {
      let aabb = this.stage.cell.QCELL.bounding_box()
      if (aabb) {
        let expand = 1000
        aabb[0][0] -= expand
        aabb[0][1] -= expand
        aabb[1][0] += expand
        aabb[1][1] += expand
        cell3dArea = new QGdstk.rectangle(aabb[0], aabb[1])
      }
    }
    if (cell3dArea) {
      let cell3D = new QGdstk.Cell('3dCell')
      let filter_polygons = []
      let aabb = cell3dArea.bounding_box()
      let cell_polygons = this.stage.cell.QCELL.get_polygons()
      let operand1 = [cell3dArea]
      for (let i = 0; i < cell_polygons.length; i++) {
        const polygon = cell_polygons[i]
        let box = polygon.bounding_box()
        if (!(box[0][0] > aabb[1][0] || box[1][0] < aabb[0][0] || box[0][1] > aabb[1][1] || box[1][1] < aabb[0][1])) {
          let res = new QGdstk.boolean(operand1, [polygon], 'and')
          res.forEach(obj => {
            obj.datatype = polygon.datatype
            obj.layer = polygon.layer
          })
          filter_polygons.push(...res)
        }
      }
      cell3D.add(filter_polygons)
      window['3DViewCell'] = this.mergeSameLayerPolygons(cell3D)
    }
    // else {
    //   window['3DViewCell'] = this.mergeSameLayerPolygons(this.stage.cell.QCELL)
    // }
    window.open(pathInfo.href, '_blank')
  }

  mergeSameLayerPolygons(targetCell) {
    let cell = new QGdstk.Cell('3dCell')
    let polygons = targetCell.get_polygons()

    let layers = this.stage.cell.getLayers()
    let igronLayers = [13, 14, 15, 16]

    layers.forEach(layer => {
      let targets = polygons.filter(polygon => polygon.layer == layer)

      if (igronLayers.indexOf(layer) != -1) {
        //合并指定图层
        if (targets.length) {
          if (targets.length > 1) {
            let res = new QGdstk.boolean([], targets, 'or', 0.001, layer, targets[0].datatype)
            cell.add(res)
          } else {
            cell.add(targets)
          }
        }
      } else {
        cell.add(targets)
      }
    })

    return cell
  }
}
