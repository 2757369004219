<template>
  <div id="stage-container">
    <!-- 图层填充缩略图 -->
    <!-- <el-upload name="gds" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :on-change="uploadFile" :auto-upload="false">
      <el-button size="small" type="primary">上传</el-button>
    </el-upload> -->
    <!-- <el-row>
      <el-button size="small" type="primary" @click="toolTest('toolAirBridge')">AirBridge</el-button>
      <el-button size="small" type="primary" @click="toolTest('toolWaveGuide')">WaveGuide</el-button>
      <el-button size="small" type="primary" @click="toolTest('toolInLinePad')">InlinePad</el-button>
      <el-button size="small" type="primary" @click="toolTest('toolTiNPad')">TiNPad</el-button>
    </el-row> -->
    <!--three.js gds文件原型测试-->
    <!-- <el-upload name="gds" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
      :on-change="uploadFile" :auto-upload="false">

      <el-button size="small" type="primary">上传版图文件</el-button>
    </el-upload> -->
    <!-- <el-dialog :title="$t('messages.tips')" :visible.sync="dialogVisible" width="30%">
      <el-checkbox v-model="mergeGraphics">合并几何数据</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox v-model="generateFill">生成多边形填充</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox v-model="labelAbs">标签绝对显示</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox v-model="antFxaa">FXAA抗锯齿</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox v-model="antSmaa">SMAA抗锯齿</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-select v-model="cellName" :placeholder="$t('messages.placeholder_0')">
        <el-option v-for="item in cellNames" :key="item" :label="item" :value="item"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('messages.cancel') }}</el-button>
        <el-button type="primary" @click="
            dialogVisible = false
            renderCell()
          ">
          {{ $t('messages.sure') }}
        </el-button>
      </span>
    </el-dialog> -->
    <!-- <el-dialog :title="$t('messages.tips')" :visible.sync="dialogVisible" width="30%">
      <el-select v-model="cellName" :placeholder="$t('messages.placeholder_0')">
        <el-option v-for="item in cellNames" :key="item" :label="item" :value="item"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('messages.cancel') }}</el-button>
        <el-button type="primary" @click="
            dialogVisible = false
            paraseQlib()
          ">
          {{ $t('messages.sure') }}
        </el-button>
      </span>
    </el-dialog> -->
    <!-- 画板 -->
    <div class="box-container" v-for="item in tabs_reactive" :key="item.containerId" :id="item.containerId"
      v-show="item.containerId == currentLayout_reactive.containerId" @mousemove="mousemoveFn">
      <div class="ruler-x">
        <canvas :id="item.containerId + 'ruler-x'" :width="currentLayout_reactive.axis.rulerX.width"
          :height="currentLayout_reactive.axis.rulerX.height"></canvas>
      </div>
      <div class="ruler-y">
        <canvas :id="item.containerId + 'ruler-y'" :width="currentLayout_reactive.axis.rulerY.width"
          :height="currentLayout_reactive.axis.rulerY.height"></canvas>
      </div>

      <div id="canvas_container" class="axis">
        <canvas :id="item.containerId + '_canvas'" class="axis" width="0" height="0"
          @contextmenu.prevent="onContextmenu()"></canvas>
      </div>
    </div>
    <DeviceAttrs></DeviceAttrs>
    <SchematicAttrs></SchematicAttrs>
    <textarea id="textInputBox" class="textInputBox" rows="1" cols="2" maxlength="20"
      style="top: 214px; left: 554px; width: 200px; height: 100px; display: none"></textarea>
  </div>
</template>
<script>
import _ from "lodash";
import "@/assets/iconfont/iconfont.css";
import { QedaLayout } from "@/utils/graphic_f32/qeda-layout";
import { QedaCellLayout } from "@/utils/graphic_cell/qeda-cell-layout";
import { LayerState } from "@/utils/graphic_cell/utils/layer-util";
import { QedaSchemaLayout } from "@/utils/graphic_schema/qeda-schema-layout";
import {
  QedaLibrary,
  QedaCell, //NOSONAR
  QedaReference, //NOSONAR
} from "@/utils/graphic_f32/qeda-graphics";
import bus from "@/components/common/bus";
import {
  saveCellFile_api, //保存文件//NOSONAR
  getCellFile_api, //获取文件
  getBlobFile,
} from "@/api/file/file.js";
import { deepClone } from "../../utils/utils.js";
import { getObjectDataFn } from "./fileList/fileUtils";
import { expand_repetition } from "./fileList/gdstk_filter.js";
import {
  getCellFromTable,
  fileExport,
  dxfExport,
  getCellIndexData,
  getOSCompleteCellData,
  schemaExport,
  getCompleteLibrary,
  convertCellDataByLayerId,
  getTopCellIndex,
  binaryDataToUTF8,
} from "./fileList/function/fileListPublicFn.js";
import { getFileLayerListFromTable } from "./rightList/layerListFn.js";
import { initTextGeo } from "@/utils/graphic_cell/utils/font-util";
import { BOARDMODE } from "../../utils/graphic_cell/const/board-status";
var AutoToolAirBridge = require("@/utils/tools/air_bridge");
const AutoTool = require("../../utils/tools/index.js");

export default {
  components: {
    DeviceAttrs: () => import("@/components/homes/DeviceAttrs.vue"),
    SchematicAttrs: () => import("@/components/homes/SchematicAttrs.vue"),
  },
  data() {
    // this.LAYER_STATE = new LayerState([
    //   { layerNum: 0, border: "B1", fill: "F1" },
    //   { layerNum: 1, border: "B1", fill: "F1" },
    //   { layerNum: 2, border: "B2", fill: "F2" },
    // ]); //图层数据管理
    this.currentLayout = null;
    this.tabs = [];
    window.QLayouts = this.tabs;
    this.fileList = [];
    return {
      dialogVisible: false,
      mergeGraphics: false,
      generateFill: true,
      labelAbs: true,
      antFxaa: false,
      antSmaa: false,
      tool: null,
      //导入gds Qlib
      Qlib: null,
      cellName: "",
      cellNames: [],
      cellId: "",
      currentLayout_reactive: null,
      cells: [],
      tabs_reactive: [],
      timer: null,
    };
  },
  created() {},
  mounted() {
    initTextGeo();
    window.addEventListener("resize", () => {
      if (!this.currentLayout) return;
      let canvas = document.getElementById(this.currentLayout.canvasId);
      if (!canvas) return;
      bus.$emit("resizeLayer", true);
    });
    //点击文件树状结构触发事件
    bus.$on("openCell", (data) => {
      if (!data) {
        return false;
      }
      this.switchTab(data);
    });
    bus.$on("closeCell", (data) => {
      let fileId = data[0].fileId;
      this.closeTab(data);
      if (
        this.tabs.filter((tab) => tab.fileInfo.fileId === fileId).length == 0
      ) {
        this.deleteFile(fileId);
      }
      bus.$emit("cellHistoryUpdate", { close: true });
    });
    bus.$on("recordProperty", (data) => {
      this.currentLayout.recordProperty("property", data, "property");
    });
    bus.$on("openAttrsDialog", (data) => {
      bus.$emit("openAttrs", this.currentLayout);
    });
    bus.$on("openSchematicAttrsDialog", (data) => {
      bus.$emit("openSchematicAttrs", this.currentLayout);
    });
    bus.$on("drcDialog", (data) => {
      bus.$emit("drcDialogOpen", this.currentLayout);
    });
    bus.$on("exportGDS", async (data) => {
      this.fileExportFunc();
      // let cellIndex = await getCellIndexData(this.$store.state.fileInfo.fileId);
      // let cell_node = this.$store.state.activeNode;
      // let lib = new Kernel.Library(this.$store.state.fileInfo.name);
      // for (let i = 0; i < cellIndex.length; i++) {
      //   let cell = await getCompleteCellData(
      //     cell_node.parent.parent.data.snow_id,
      //     this.$store.state.fileInfo.fileId,
      //     cellIndex[i].snow_id
      //   );
      //   lib.add_cell(cell);
      // }
      // fileExport(lib)
    });
    bus.$on("exportSchema", async (data) => {
      // let cellIndex = await getCellIndexData(this.$store.state.fileInfo.fileId)
      // let cell_node = this.$store.state.activeNode;

      // let schemaFile = new Kernel.SchemaFile();
      // schemaFile.name = this.$store.state.fileInfo.name;
      // for (let i = 0; i < cellIndex.length; i++) {
      //   let schema = await getCompleteCellData(cell_node.parent.parent.data.snow_id, this.$store.state.fileInfo.fileId, cellIndex[i].snow_id);
      //   schemaFile.add_schema(schema)
      // }

      // schemaExport(schemaFile);
      this.schemaExportFunc();
    });
    bus.$on("exportDXF", async (data) => {
      this.dxfExportFunc();
    });
    bus.$on("addCellToOpenedFile", (data) => {
      let fileId = data[0];
      let cellName = data[1];
      let file = this.searchFromFileList(fileId);
      if (file) {
        if (!file.cells[cellName]) {
          let QLIB = window.QGdstk.read_gds(fileId);
          expand_repetition(QLIB);
          let cell = QLIB.cells.filter((cell) => cell.name === cellName)[0];
          //已打开的tab新添加cell
          if (cell) {
            file.addExistCell(cell);
            this.stage.cells = file.cells;
          }
        }
      }
    });
    bus.$on("updateSchemaLayout", (data) => {
      this.update_currentLayout_reactive(data);
    });

    this.switchTab([
      this.$store.state.topInfo,
      this.$store.state.proInfo,
      this.$store.state.fileInfo,
      this.$store.state.cellInfo,
    ]);
    this.timer = setInterval(() => {
      bus.$emit("getCurrentLayout", this.currentLayout);
    }, 500);
  },
  destroyed() {
    //移除监听
    bus.$off("openCell");
    bus.$off("closeCell");
    bus.$off("recordProperty");
    bus.$off("openAttrsDialog");
    bus.$off("addCellToOpenedFile");
    this.fileList = [];
    this.currentLayout = null;
    this.tabs.forEach((layout) => layout.delete());
    clearInterval(this.timer);
  },
  methods: {
    onContextmenu() {
      // if (
      //   this.$store.state.cellInfo.cell_type == "3" ||
      //   this.$store.state.cellInfo.cell_type == "4" ||
      //   !this.currentLayout.STATUS.checked_objs.length ||
      //   this.currentLayout.drawingObject ||
      //   this.currentLayout.stage.editReference ||
      //   this.currentLayout.stage.checkMode === 3 ||
      //   this.currentLayout.stage.checkMode === 4 ||
      //   this.currentLayout.stage.tools.length
      // ) {
      //   return;
      // }
      if (this.currentLayout.constructor.name !== "QedaCellLayout") return; //原理图没有右键属性 只有q键属性
      if (
        !this.currentLayout.STATUS.checked_objs.length ||
        this.currentLayout.STATUS.drawingObject ||
        this.currentLayout.EDIT_REF ||
        this.currentLayout.MODE == BOARDMODE.CUT_X ||
        this.currentLayout.MODE == BOARDMODE.CUT_Y
      ) {
        return;
      }
      this.$contextmenu({
        items: [
          {
            label: this.$t("messages.Rotate"),
            onClick: () => {
              bus.$emit("rotate", true);
            },
            icon: "iconfont icon-xuanzhuan",
          },
          {
            label: this.$t("messages.copy"),
            onClick: () => {
              bus.$emit("copy", true);
            },
            icon: "iconfont icon-fuzhi",
          },
          {
            label: this.$t("messages.paste"),
            onClick: () => {
              bus.$emit("paste", true);
            },
            icon: "iconfont icon-niantie",
          },
          {
            label: this.$t("messages.delete"),
            onClick: () => {
              this.currentLayout.deleteCheckedObjs();
            },
            divided: true,
            icon: "iconfont icon-shanchu",
          },
          {
            label: this.$t("messages.Properties"),
            onClick: () => {
              bus.$emit("openAttrs", this.currentLayout);
            },
          },
        ],
        event, // 鼠标事件信息
        customClass: "custom-class", // 自定义菜单 class
        zIndex: 1000, // 菜单样式 z-index
        minWidth: 170, // 主菜单最小宽度
      });
    },
    mousemoveFn() {
      if (this.currentLayout instanceof QedaCellLayout) {
        let mouse_p = this.currentLayout.AXIS.getDisplayMousePos();
        let mousePoint = [mouse_p[0].toFixed(3), mouse_p[1].toFixed(3)];
        bus.$emit("getMousePoint", mousePoint);
        return;
      } else {
        let mouse_p = this.currentLayout.axis.getDisplayMousePos();
        let mousePoint = [mouse_p.x.toFixed(3), mouse_p.y.toFixed(3)];
        bus.$emit("getMousePoint", mousePoint);
      }
    },
    test() {
      this.currentLayout.active();
    },
    //从缓存的文件列表获取文件
    searchFromFileList(id) {
      let len = this.fileList.length;
      for (let i = 0; i < len; i++) {
        if (this.fileList[i].id === id) {
          return this.fileList[i].file;
        }
      }
      return null;
    },
    //缓存列表删除文件
    deleteFile(id) {
      let len = this.fileList.length;
      for (let i = 0; i < len; i++) {
        if (this.fileList[i].id === id) {
          this.fileList.splice(i, 1);
          return;
        }
      }
    },
    //从后台获取版图
    async getFileFromServe(fileInfo) {
      let file;
      let result = getCellFile_api({ file_snow_id: fileInfo.fileId });
      await result.then(async (res) => {
        if (res.data.file_url) {
          let unit = await getObjectDataFn(res.data.file_url);
          window.QGdstk.FS.writeFile(fileInfo.fileId, unit);
          file = new QedaLibrary(fileInfo.name, fileInfo.fileId, true);
        } else {
          file = null;
        }
      });
      return file;
    },
    //切换画板
    async switchTab(data) {
      let cellInfo = data[3];

      if (
        this.currentLayout_reactive?.cellId &&
        cellInfo.cellId == this.currentLayout_reactive.cellId
      ) {
        return; //打开正在编辑的画板
      }
      this.inActiveCurrentLayout();
      //打开原理图
      if (cellInfo.cell_type == 3) {
        this.openSchema(data);
      } else {
        this.openCell(data);
      }
    },
    //打开原理图
    openSchema(data) {
      let topInfo = data[0];
      let projectInfo = data[1];
      let fileInfo = data[2];
      let cellInfo = data[3];
      if (
        FONT.heiti &&
        FONT.songti &&
        !FONT_FACES.heiti &&
        !FONT_FACES.songti
      ) {
        this.initFontFaces();
      }
      if (
        !(FONT.heiti && FONT.songti && FONT_FACES.heiti && FONT_FACES.songti)
      ) {
      }

      this.$store.commit("getShemaBoardSettings", data[1].projectId);
      let openedTab = this.tabs.filter(
        (layout) =>
          layout.fileInfo.fileId === fileInfo.fileId &&
          layout.cellInfo.cellId === cellInfo.cellId
      );
      //已经打开过的画板
      if (openedTab.length) {
        this.update_currentLayout_reactive(openedTab[0]);
        this.currentLayout = openedTab[0];
        this.currentLayout.active();
      } else {
        // //新创建画板
        // let file = this.getSchemaFile(fileInfo.fileId);

        // let schema = this.getSchema(file, cellInfo.cellId);

        // let layout = new QedaSchemaLayout(fileInfo, cellInfo, file, schema);
        // this.update_currentLayout_reactive(layout);
        // this.currentLayout = layout;
        // this.tabs.push(layout);
        // this.update_tabs_reactive();

        //新创建画板
        // let projectFile = this.getProject(projectInfo.projectId);

        let schema = this.getSchema(cellInfo.cellId);

        let layout = new QedaSchemaLayout(
          topInfo,
          projectInfo,
          fileInfo,
          cellInfo,
          null,
          schema
        );
        this.update_currentLayout_reactive(layout);
        this.currentLayout = layout;
        this.tabs.push(layout);
        this.update_tabs_reactive();
      }
    },
    //打开版图
    openCell(data) {
      let topInfo = data[0];
      let projectInfo = data[1];
      let fileInfo = data[2];
      let cellInfo = data[3];
      let openedTab = this.tabs.filter(
        (layout) =>
          layout.fileInfo.fileId === fileInfo.fileId &&
          layout.cellInfo.cellId === cellInfo.cellId
      );
      //已经打开过的画板
      if (openedTab.length) {
        this.update_current_cell_Layout_reactive(openedTab[0]);
        this.currentLayout = openedTab[0];
        this.currentLayout.active();
      } else {
        //新打开文件
        let containerId = fileInfo.fileId + cellInfo.cellId;
        let layout_temp = {
          //构建临时画板
          fileInfo,
          cellInfo,
          containerId,
        };
        this.update_current_cell_Layout_reactive(layout_temp); //更新当前画板的vue双向绑定数据
        this.tabs.push(layout_temp); //添加版图画板
        this.update_tabs_reactive(); //更新当前画板Tabs的vue双向绑定数据

        let that = this;

        setTimeout(() => {
          const cell = getCellFromTable(cellInfo.cellId);
          const LAYER_STATE = getFileLayerListFromTable(
            fileInfo.fileId
          ).layerState;

          let layout = new QedaCellLayout(
            topInfo,
            projectInfo,
            fileInfo,
            cellInfo,
            cell,
            LAYER_STATE
          );
          that.currentLayout = layout;
          that.tabs[that.tabs.length - 1] = layout;
          this.update_current_cell_Layout_reactive(layout); //更新当前画板的vue双向绑定数据
        });

        // let layout = new QedaLayout(fileInfo, cellInfo);
        // this.update_currentLayout_reactive(layout);
        // this.currentLayout = layout;

        // this.tabs.push(layout);
        // this.update_tabs_reactive();
        // file = await this.getFileFromServe(fileInfo); //从服务端读取
        // if (!file) {
        //   //不存在文件
        //   file = new QedaLibrary(fileInfo.name, fileInfo.fileId); //本地新建
        //   file.addCell(cellInfo.name);
        // }
        // if (!file.cells[cellInfo.name]) {
        //   file.addCell(cellInfo.name);
        // }
        // this.fileList.push({ id: fileInfo.fileId, file: file });
        // setTimeout(() => layout.setFileAndCell(file, cellInfo.name));

        // this.setToolBigLibrary();
      }
    },
    setToolBigLibrary() {
      this.$store.commit("setToolBigLibrary", this.currentLayout);
    },
    //关闭画板
    closeTab(data) {
      let fileInfo = data[2];
      let cellInfo = data[3];
      let len = this.tabs.length;
      for (let i = 0; i < len; i++) {
        let tab = this.tabs[i];
        if (
          tab.fileInfo.fileId === fileInfo.fileId &&
          tab.cellInfo.cellId === cellInfo.cellId
        ) {
          tab.inActive();
          tab.deleteBoard();
          this.tabs.splice(i, 1);
          return;
        }
      }
      this.update_tabs_reactive();
    },
    //禁用画板
    inActiveCurrentLayout() {
      if (this.currentLayout) {
        this.currentLayout.inActive();
      }
    },
    //上传文件
    uploadFile() {
      let event = event || window.event; //NOSONAR
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      let that = this;
      reader.onload = function (e) {
        const blob = new Uint8Array(reader.result);
        let id = that.currentLayout.fileInfo.fileId;
        window.QGdstk.FS.writeFile(id, blob);
        // console.time("readGds")
        that.Qlib = window.QGdstk.read_gds(id);
        expand_repetition(that.Qlib);
        // console.timeEnd("readGds")

        that.cellNames = that.Qlib.cells.map((c) => c.name);
        that.cellName = that.cellNames[0];
        that.dialogVisible = true;
      };
    },
    //解析上传的Qlib
    paraseQlib() {
      this.currentLayout.parseQlibrary(this.cellName);
    },
    // 工具测试
    toolTest(name) {
      bus.$emit(name, true);
    },
    //three.js原型测试
    renderCell() {
      // this.currentLayout.STAGE.config.useMergeBufferGeometries =
      //   this.mergeGraphics;
      // this.currentLayout.STAGE.config.showFill = this.generateFill;
      // this.currentLayout.STAGE.config.renderConfig.labelAbsoulate =
      //   this.labelAbs;
      // this.currentLayout.STAGE.config.antialias.useSmaaPass = this.antSmaa;
      // this.currentLayout.STAGE.config.antialias.useFxaaPass = this.antFxaa;
      this.currentLayout.setFileAndCell(this.Qlib, this.cellName);
    },
    //获取工程文件
    // getProject(id) {

    //   return this.$store.state.projectTable.filter(
    //     (pro) => pro.projectId == id
    //   )[0].project;
    // },
    //获取原理图文件
    getSchemaFile(id) {
      // return new Kernel.test();

      //   this.$store.state.schemaFileTable,
      //   id,
      //   this.$store.state.schemaFileTable.filter((file) => file.fileId === id)
      // );
      // return this.$store.state.schemaFileTable.filter(
      //   (file) => file.fileId === id
      // )[0].fileData;

      return this.$store.state.projectTable[0].project;
    },
    //获取原理图
    getSchema(cell_id) {
      return this.$store.state.cellTable.filter(
        (cell) => cell.cellId == cell_id
      )[0].cell;
      // for (let i = 0; i < file.schemas.size; i++) {
      //   let schema = file.schemas.get(i);

      //   if (schema.snow_id === snow_id) {
      //     return schema;
      //   }
      // }
      //return project.schemas[0]; //.get(0);
    },
    initFontFaces() {
      window.FONT_FACES = {
        default: new CanvasKit.Font(null, 7), //default
        heiti: new CanvasKit.Font(
          CanvasKit.Typeface.MakeFreeTypeFaceFromData(FONT.heiti),
          7
        ), //黑体
        songti: new CanvasKit.Font(
          CanvasKit.Typeface.MakeFreeTypeFaceFromData(FONT.songti),
          7
        ), //宋体
      };
    },
    update_currentLayout_reactive(layout) {
      this.currentLayout_reactive = {
        containerId: layout.containerId,
        cellId: layout.cellInfo.cellId,
        axis: {
          rulerX: {
            width: layout.axis.rulerX.width,
            height: layout.axis.rulerX.height,
          },
          rulerY: {
            width: layout.axis.rulerY.width,
            height: layout.axis.rulerY.height,
          },
        },
      };
    },
    update_current_cell_Layout_reactive(layout) {
      this.currentLayout_reactive = {
        containerId: layout.containerId,
        cellId: layout.cellInfo.cellId,
        axis: {
          rulerX: {
            width: layout.AXIS?.rulerX.width,
            height: layout.AXIS?.rulerX.height,
          },
          rulerY: {
            width: layout.AXIS?.rulerY.width,
            height: layout.AXIS?.rulerY.height,
          },
        },
      };
    },
    getLayerData(fileID) {
      let allData = this.$store.state.fileLayerListTable;
      for (let i in allData) {
        if (fileID == allData[i].fileId) {
          return allData[i].fileLayerDatas;
        }
      }
    },
    update_tabs_reactive() {
      let res = [];
      this.tabs.forEach((tab) => {
        res.push({
          containerId: tab.containerId,
        });
      });
      this.tabs_reactive = res;
    },
    fileExportFunc: _.debounce(async function () {
      bus.$emit("screen_lock", true);
      try {
        let all_layer = this.getLayerData(this.$store.state.fileInfo.fileId);
        let export_layer = all_layer.filter((layer) => layer.isExport == true);
        let layerMap = {};
        for (let i = 0; i < export_layer.length; i++) {
          layerMap[export_layer[i].id] = export_layer[i].layerNumber;
        }
        let lib = await getCompleteLibrary(
          this.$store.state.fileInfo.fileId,
          this.$store.state.fileInfo.name,
          layerMap
        );
        fileExport(lib);
      } catch (e) {
        bus.$emit("screen_lock", false);
      }
      bus.$emit("screen_lock", false);
    }, 1000),
    dxfExportFunc: _.debounce(async function () {
      bus.$emit("screen_lock", true);
      try {
        let all_layer = this.getLayerData(this.$store.state.fileInfo.fileId);
        let export_layer = all_layer.filter((layer) => layer.isExport == true);
        let layerMap = {};
        for (let i = 0; i < export_layer.length; i++) {
          layerMap[export_layer[i].id] = export_layer[i].layerNumber;
        }
        let lib = await getCompleteLibrary(
          this.$store.state.fileInfo.fileId,
          this.$store.state.fileInfo.name,
          layerMap
        );

        dxfExport(lib);
      } catch (e) {
        bus.$emit("screen_lock", false);
      }
      bus.$emit("screen_lock", false);
    }, 1000),
    schemaExportFunc: _.debounce(async function () {
      bus.$emit("screen_lock", true);
      try {
        let cellIndex = await getCellIndexData(
          this.$store.state.fileInfo.fileId
        );
        let cell_node = this.$store.state.activeNode;

        //获取顶层节点
        let topCellNodeIdList = [];
        for (let i = 0; i < cellIndex.length; i++) {
          if (getTopCellIndex(cellIndex[i].snow_id, cellIndex)) {
            topCellNodeIdList.push(cellIndex[i].snow_id);
          }
        }

        let schemaFile = new Kernel.SchemaFile();
        schemaFile.name = this.$store.state.fileInfo.name;
        for (let i = 0; i < topCellNodeIdList.length; i++) {
          let symbol_obj = await getOSCompleteCellData(
            cell_node.parent.parent.data.snow_id,
            this.$store.state.fileInfo.fileId,
            topCellNodeIdList[i],
            false
          );

          let SchmeSymbolList = symbol_obj.depend_symbol_schemas(-1);
          SchmeSymbolList.unshift(symbol_obj);

          for (let j = 0; j < SchmeSymbolList.length; j++) {
            if (SchmeSymbolList[j].$$?.ptrType.name == "Schema*") {
              schemaFile.add_schema(SchmeSymbolList[j]);
            } else if (SchmeSymbolList[j].$$?.ptrType.name == "Symbol*") {
              schemaFile.add_symbol(SchmeSymbolList[j]);
            } else if (SchmeSymbolList[j].$$?.ptrType.name == "CapSymbol*") {
              schemaFile.add_capsymbol(SchmeSymbolList[j]);
            }
          }
        }

        schemaExport(schemaFile);
      } catch (e) {
        bus.$emit("screen_lock", false);
      }
      bus.$emit("screen_lock", false);
    }, 1000),
  },
};
</script>
<style lang="scss" scoped>
.box-container {
  display: grid;
  grid-template-columns: 22px 1fr;
  grid-template-rows: 22px 1fr;
  padding: 0px 0px 0px 0px;
  height: 100%;
}
.ruler-x {
  grid-row-start: 1;
  grid-column-start: 2;
}
.ruler-y {
  grid-row-start: 2;
  grid-column-start: 1;
  /* grid-row-end: 3; */
}
.test {
  width: 300px;
  height: 300px;
  grid-row-start: 2;
  grid-column-start: 2;
  border: 1px solid #f00;
}
.axis {
  /* grid-row-start: 2;
    grid-column-start: 2; */
  grid-row-start: 2;
  grid-column-start: 2;
  background-color: black;
  /* grid-row-end: 3; */
}
.layer {
  width: 30px;
  height: 20px;
  background-color: #f00;
  border: 1px solid #f00;
  display: inline-block;
}
#stage-container {
  height: 100%;
}
.textInputBox {
  position: fixed;
  z-index: 999;
  box-shadow: 0 0 10px 5px #666;
}
</style>
