import QedaSchemaAxis from './qeda-schema-axis'
import { QedaSchemaStage } from './qeda-schema-stage'
import { QedaSchemaGraphicsFactory } from './qeda-schema-graphics'
import { QedaGraphicType } from './qeda-schema-graphics'
import { isAdsorb } from '../pixi/qeda-pixi-data'
import bus from '@/components/common/bus'
import store from '@/store/index'
import { Message } from 'element-ui'
import {
  lockCell_api, //锁文件
  selectLockCell_api,
  unLockCell_api,
} from '@/api/file/file.js'
window.QedaSchemaGraphics = new QedaSchemaGraphicsFactory()
import {
  directionMap,
  BoardMode,
  urlAlphabet,
  checkInsideSelectBoxPoints,
  calGraphicsAABB,
  axisAdaptAABB,
  axisInitRrender,
  moveAxis,
  SchemaQuadTree,
  newInsName,
  newNetName,
  changeCursor,
  layoutDrawObject,
  layoutCompleteDraw,
  buildRenderData,
  buildLabelBox,
  buildCpLinePath,
  buildPinPath,
  buildSymbolPath,
  drawLinePoint,
  updateDrawingCpLine,
  getCtLabelText,
  updateMouseOffSet,
  checkInsideCheckedObjs,
  noRepeat,
  buildObjPath,
  copyObjs,
  moveObj,
  getNearPortPos,
  getNearPoint,
  autoConnectPort,
  autoConnectSymbolPort,
  autoRenameCplineNetName,
  getMidPoints,
  updateLinePointMouseOffSet,
  updateLinePointsByPorts,
  copy,
  minDistLineCenter,
  getCtHLNetColors,
  updateLableValue,
  curve_del_parallel,
  getSymbolInsNames,
  getCplineByNetName,
  expandAABB,
  clearSchemaStatus,
  revertCplineConnectPorts,
  revertPortConnectPorts,
} from './layout/layout-util'
import { addEventListener, removeEventListeners } from './layout/layout-events'
import { deleteCheckedObjs, rotateObjs, arrayObjs, alignmentObjs, saveFile, recordHistory, undo, redo, adaptScreen, resetBoardStatus, copyOP, pasteData, confirmPaste, deleteCutObjs, enterlowerLevel, backUpperLevel } from './layout/layout-functions'
import { getAStartPath } from './layout/layout_astar'
import { SchemaDisplayConfig } from './render-util'
import { deepClone } from '../utils'
import { validAABB } from '../graphic_cell/validator/validator'
import { getPermission } from '../../components/homes/fileList/function/fileListApiFn'
export class QedaSchemaLayout {
  constructor(topInfo, projectInfo, fileInfo, cellInfo, file, schema) {
    // alert(newInsName('ABBBC', ['AA', 'BB', 'ABBBC']))
    /*************渲染配置**************/
    this.config = new SchemaDisplayConfig()
    /*************文件信息*************/
    this.topInfo = topInfo
    this.projectInfo = projectInfo
    this.fileInfo = fileInfo
    this.cellInfo = cellInfo
    this.file_snow_id = fileInfo.fileId
    this.file = file
    this.schema = buildRenderData(schema, this.config)

    console.timeEnd('initData')
    console.time('buildTree')
    this.quadTree = new SchemaQuadTree(this.schema)
    console.timeEnd('buildTree')
    /*************容器 画布DOM ID*************/
    this.containerId = fileInfo.fileId + cellInfo.cellId
    this.canvasId = this.containerId + '_canvas'

    /*************坐标系统-渲染器-撤销恢复步骤存储器-进入下层编辑顺序*************/
    this.axis = new QedaSchemaAxis(800, 800, 1.0e-6, 1.0e-9)
    this.stage = null
    this.editHistory = new window.UndoRedojs()
    let that = this
    this.editHistory.onChange = function () {
      bus.$emit('fileChange', that.fileInfo.fileId)
      that.schema.js_obj.isSave = false
    }
    this.schemaHistory = []

    /*************画板状态*************/
    this.boardMode = BoardMode.SELECT //画板模式 0整体选择 1部分选择 2耦合线绘制 3PIN放置 4标签放置 5 阵列 6 预粘贴 7预剪切 8点对齐 9线对齐 10 z线选择模式 11谐振腔 多边形 点绘制模式
    this.drawType = 'selectBox' //绘图类型
    this.drawingObject = null //正在绘制的对象
    this.moveMod = 0 //移动模式 0 正常鼠标拖动 1 点击确认
    this.drag_count = 0 //拖动物体标志
    this.rotat_center = null //旋转中心
    this.rotation = 0 //旋转角度

    /*************复制数据*************/
    this.copyData = null //被复制数据
    this.copyDataCenter = null //被复制数据中心坐标
    this.pre_copy_data = null //ctrl-v准备复制数据到画板
    this.preCutObjs = null //ctrl-v准备剪切数据到画板
    this.ctDrawData = null //连续绘制数据 标签 PIN 符号
    this.loopText = null //循环文本
    this.ctText = [] //连续绘制文本

    /*************重叠图形切换选择相关状态*************/
    this.pre_checked_objs_id = [] //预选中图形index列表
    this.pre_checked_objs_all_id = []
    this.pre_cover_index = -1
    this.checked_objs_id = [] //选中图形index列表
    this.cover_index = -1 //当前选中图形index 根据列表循环切换

    /*************对象选择*************/
    this.pre_checked_obj = null //预选择对象
    this.checked_objs = [] //选中的对象
    this.checked_objs_count = 0 //选中对象数量
    this.preAddObj = null //预添加物体

    /*************鼠标位置*************/
    this.drawPoints = [] //鼠标起始点和终点 [start,end] 判断框选顺序
    this.movePosition = [] //记录两次移动点
    this.latestConfirmPos = null //最后一次点击的鼠标位置

    /*************包围盒*************/
    this.select_AABB = null //选择框包围盒
    this.select_AABB_points = [] //包围盒四个点，用于判断与圆相交
    this.graphicsAABB = null //画板所有图形包围盒
    this.checkedGraphicsAABB = null //画板选中图形的包围盒
    this.checkedGraphicsAABB_center = null //画板选中图形的包围盒中心点坐标

    /*************状态标志*************/
    this.viewMode = this.cellInfo.isReadOnly //只读模式
    this.active_status = true //激活状态
    this.check_able = true //图形能否被选中
    this.check_all = false //选择所有对象
    this.checked_objs_hited = false //命中选中目标之一
    this.select_mode = true //选择框包围盒 true 包围盒全选模式 false 包围盒半选模式
    this.isCut = false //剪切
    this.leftPress = false //鼠标左键长按
    this.rightPress = false //鼠标右键长按
    this.press_shift = false //按住shift
    this.press_ctrl = false //按住ctrl
    this.lineMod = false //线拐角方向
    this.lineAngle = 0 //拐角角度: 0 任意角度 -- 45 -- 90
    this.lineColor = '#FFFFFF' //线的颜色
    this.connectionTarget = null // 绘制线的连接对象 PIN SYMBOL
    this.addPoint = null //编辑线时拖动中点添加点
    this.preConnectNetLabel = null //待绑定的网络标签
    this.lastUsedColor = null //上次高亮网络使用颜色
    this.lockFileTimer = null //锁文件定时器
    this.renderTimer = null //渲染定时器
    //事件绑定
    setTimeout(() => {
      this.canvas = document.getElementById(this.canvasId)
      this.initStage()
      this.renderTimer = setInterval(() => {
        if (this.active_status) {
          if (this.stage.checkedResBoxs.length) {
            this.stage.render()
          }
        }
      })
      this.mouseWheelListener = this.onMouseWheel.bind(this)
      this.mouseMoveListener = this.mouseMove.bind(this)
      this.mouseDownListener = this.mouseDown.bind(this)
      this.mouseUpListener = this.mouseUp.bind(this)
      this.dbClickListener = this.onDbClick.bind(this)
      this.keyBoardListener = this.keyDown.bind(this)
      this.keyUpBoardListener = this.keyUp.bind(this)
      addEventListener(this, bus)
    })
    bus.$emit('cellHistoryUpdate', { cell: this.schema, avtive: true })
  }

  // 初始化坐标系统，舞台
  initStage() {
    autoConnectPort(this)
    axisInitRrender(this.axis, this.canvasId)
    this.stage = new QedaSchemaStage(this.axis, this.containerId, this.schema, this.config)
    this.quadTree.showConfig = this.stage.config.showConfig
    this.setDisplayConfig()
    // this.aStartTest()
    // this.setToolState()
    setTimeout(() => {
      this.stage.renderRulers()
      this.reRenderSchema()
      // this.stage.updateRenderObjs(this.quadTree)
      // this.stage.render()
      bus.$emit('ending', true)
    })
  }

  //激活画板
  active() {
    autoConnectPort(this)
    // autoConnectSymbolPort(this)
    buildRenderData(this.schema, this.config)
    this.setDisplayConfig()
    this.active_status = true
    if (!this.cellInfo?.isReadOnly && this.fileInfo?.teamRule !== undefined) {
      this.lockFile()
    }
    setTimeout(() => {
      this.drawType = 'selectBox'
      this.check_able = this.drawType === 'selectBox'
      addEventListener(this, bus)
      this.stage.render()
      bus.$emit('ending', true)
      bus.$emit('cellHistoryUpdate', { cell: this.schema, avtive: true })
    })
  }

  //禁用画板
  inActive() {
    if (!this.active_status) return //已经禁用
    this.active_status = false
    // window.clearInterval(this.lockFileTimer)
    // this.lockFileTimer = null
    let str = this.fileInfo.fileId + '/' + this.cellInfo.cellId
    // bus.$emit('updateFileTree', str)
    removeEventListeners(this, bus)
  }

  //删除画板
  deleteBoard() {
    bus.$off('resetName')
    window.clearInterval(this.lockFileTimer)
    window.clearInterval(this.renderTimer)
    this.lockFileTimer = null
    this.inActive()
    this.editHistory.clear()
    // this.unLockFile()
  }

  //更新鼠标碰撞包围盒
  updateMouseAABB() {
    let center = this.axis.mouse_point
    let dist = 5 / this.axis.scale
    this.stage.mouse_aabb = [
      [center.x - dist, center.y - dist],
      [center.x + dist, center.y + dist],
    ]
    if (this.pre_checked_obj) {
      this.pre_checked_obj.js_obj.preChecked = false
      this.pre_checked_obj = null
    }
    let queryConnectCpLine = (this.boardMode === BoardMode.LABEL && (this.drawingObject?.is_net || this.preConnectNetLabel)) || (this.boardMode == BoardMode.HL_NET && this.boardMode == BoardMode.RM_NET)
    if (queryConnectCpLine) {
    } else if (this.drawingObject || this.drag_count || (this.boardMode !== BoardMode.SELECT && this.boardMode !== BoardMode.HL_NET && this.boardMode !== BoardMode.RM_NET)) return
    let hits = []

    if (queryConnectCpLine) {
      //查询连接的标签
      hits = this.quadTree.queryCpLines(this.stage.mouse_aabb)
    } else {
      hits = this.quadTree.queryByAABB(this.stage.mouse_aabb)
    }

    let all_len = hits.length
    // this.pre_checked_objs_id = hits.map(hit => hit.js_obj.tree_id)
    // this.updatePreCoverIndex(true)
    if (all_len) {
      hits.sort((a, b) => b.js_obj.tree_id - a.js_obj.tree_id) //排序
      let hits_no_checked = hits.filter(hit => !hit.checked) //过滤未选中
      let index = null
      let no_checked_len = hits_no_checked.length
      if (!no_checked_len) return
      //没有重叠默认第一项
      index = 0

      //重叠状态下判断，重叠状态有选中对象
      if (this.cover_index > -1) {
        index = this.cover_index + 1

        //返回第一项
        if (index > all_len - 1 && hits[0]) {
          index = 0
        } else {
          for (let i = index; i < all_len; i++) {
            if (!hits[index].checked) {
              index = i
              break
            }
            index++
          }
        }
      }

      if (index !== null && hits[index]) {
        this.pre_checked_obj = hits[index]
        this.pre_checked_obj.js_obj.preChecked = true
      }
    }
  }

  getMousePosArray() {
    let pos = this.getMousePostion()
    return [pos.x, pos.y]
  }
  //返回鼠标在画板坐标
  getMousePostion() {
    this.connectionTarget = null
    this.updateMouseAABB()
    let pos = this.axis.mouse_point
    this.axis.angled_dsorp_special_point = null
    //绘制线 拖动点 判断附近吸附点（除了拖动线）
    if (this.boardMode === BoardMode.CP_LINE || (this.stage.editCpLine && !this.stage.editCpLine.js_obj.checked_points_index?.length && this.drag_count)) {
      let hit = getNearPortPos(this, [pos.x, pos.y], this.stage.mouse_aabb)
      if (hit) {
        this.connectionTarget = hit
        let hitPos = hit.port.pos
        this.axis.angled_dsorp_special_point = hitPos
        pos = { x: hitPos[0], y: hitPos[1] }
      } else {
        let hitPoint = getNearPoint(this, [pos.x, pos.y])
        if (hitPoint) {
          this.connectionTarget = hitPoint.target
          let hitPos = hitPoint.point
          this.axis.angled_dsorp_special_point = hitPos
          pos = { x: hitPos[0], y: hitPos[1] }
        }
      }
    }
    return pos
  }

  mouseDown(e) {
    // if (this.viewMode) return
    const shiftKey = e.shiftKey
    const ctrlKey = e.ctrlKey
    // e.preventDefault()
    if (!this.axis.mouse_point) return //舞台初始化完成

    // if (this.moveMod === 0) {
    //   this.updateMoveMode()
    // }
    // if (this.moveMod === 2) {
    //   this.updateMoveMode()
    // }
    // let mouse_pos = this.getMousePostion()
    // if (this.axis.adsorpPoint && this.axis.hit_point) {
    //   let hit_point = this.axis.hit_point
    //   mouse_pos = { x: hit_point[0], y: hit_point[1] }
    // }
    // this.checked_objs_hited = this.checkInsideCheckedObjs(mouse_pos)
    // this.onMouseDownPool(e, mouse_pos)
    this.axis.onMouseDown(e)
    this.axis.setDrag(e.button !== 0, e.button)
    let temp_checked_objs = this.checked_objs
    if (e.button === 0) {
      // if (this.boardMode === 6) {
      //   //粘贴
      //   //重置状态
      //   const data_size = this.pre_copy_data?.length
      //   if (this.isCut) {
      //     this.deleteCutObjs()
      //   }
      //   for (let i = 0; i < data_size; i++) {
      //     this.pre_copy_data[i].resetStatus()
      //   }
      //   this.pre_copy_data = null
      // }
      // if (this.preAddObj) {
      //   this.preAddObj.preAdd = false
      //   //放置后记录
      //   this.editHistory.record([{ action: 'add', cellName: this.stage.getCurrentCellName(), objs: [this.preAddObj] }])
      //   this.preAddObj.checked = false
      //   this.preAddObj = null
      // }
      this.leftPress = true

      let mouse_pos = this.getMousePosArray()
      //正在绘制
      if (this.drawingObject || this.preConnectNetLabel) {
        //两点描述的工具图形 符号 PIN 标签
        if (this.boardMode === BoardMode.SELECT || this.boardMode === BoardMode.PIN || this.boardMode === BoardMode.LABEL) {
          layoutCompleteDraw(this)
          return
        }
        //完成耦合线绘制
        if (this.boardMode === BoardMode.CP_LINE) {
          drawLinePoint(this, this.drawingObject, mouse_pos, this.latestConfirmPos, this.lineMod, this.lineAngle, true)
          if (this.connectionTarget && this.drawingObject.line.points.length > 1) {
            layoutCompleteDraw(this)
          } else {
            this.latestConfirmPos = this.drawingObject.line.points.at(-1)
          }

          return
        }
      } else {
        if (this.boardMode === BoardMode.SELECT) {
          //编辑线的顶点
          if (this.stage.editCpLine) {
            this.checked_objs_hited = checkInsideCheckedObjs(this)
            updateLinePointMouseOffSet(this, mouse_pos)
          } else if (!shiftKey && !ctrlKey) {
            //整体移动
            this.checked_objs_hited = checkInsideCheckedObjs(this)
            if (!this.checked_objs_hited) {
              this.checked_objs.forEach(obj => {
                obj.js_obj.pre_checked_obj = false
                obj.js_obj.checked = false
                obj.js_obj.mouse_offset = []
              })
              this.checked_objs = []
              this.updateCheckedObjsAABB()
            } else {
              updateMouseOffSet(this.checked_objs, mouse_pos)
            }
          }
        } else if (this.boardMode === BoardMode.CP_LINE) {
          //开始下一根耦合线绘制
          this.newCpLine()
          drawLinePoint(this, this.drawingObject, mouse_pos, this.latestConfirmPos, this.lineMod, this.lineAngle, true)
          this.latestConfirmPos = this.drawingObject.line.points.at(-1)
        } else if (this.boardMode === BoardMode.MOVE) {
          if (!this.latestConfirmPos) {
            this.latestConfirmPos = mouse_pos //点击移动第一个点
            updateMouseOffSet(this.checked_objs, mouse_pos)
          } else {
            this.latestConfirmPos = null
            this.switchBoardMode(BoardMode.SELECT)
            autoRenameCplineNetName(this, temp_checked_objs)
            autoConnectSymbolPort(this, temp_checked_objs)
            autoConnectPort(this)
            bus.$emit('cancelSidebarSelect')
            bus.$emit('escSchema', true)
          }
        } else if (this.boardMode === BoardMode.HL_NET) {
          if (this.pre_checked_obj) {
            let result = getCtHLNetColors(this.lastUsedColor)
            this.lastUsedColor = result.color
            let net_name = this.pre_checked_obj.net_name
            getCplineByNetName(this.schema, net_name).forEach(cpl => {
              cpl.js_obj.highlight = true
              cpl.js_obj.highlight_color = result.color
              cpl.js_obj.highlight_rgb = result.rgb
            })
          }
        } else if (this.boardMode === BoardMode.RM_NET) {
          if (this.pre_checked_obj) {
            let net_name = this.pre_checked_obj.net_name
            getCplineByNetName(this.schema, net_name).forEach(cpl => {
              cpl.js_obj.highlight = false
              cpl.js_obj.highlight_color = null
              cpl.js_obj.highlight_rgb = null
            })
          }
        }
      }
    }
    if (e.button === 2) {
      this.rightPress = true
      if (this.boardMode == BoardMode.CP_LINE) {
        layoutCompleteDraw(this)
      }
    }
  }

  mouseMove(e) {
    // if (this.viewMode) return
    e.preventDefault()
    if (!e.movementX && !e.movementY) {
      //判断鼠标未移动，避免长按左键触发mousemove
      return
    }
    if (this.axis.left_drag && this.moveMod === 2) {
      this.updateMoveMode() //旋转模式 更新坐标
    }
    // this.onMouseMovePool(e)
    this.axis.onMouseMove(e)
    this.stage.renderRulers()
    //中建拖动移动画板
    if (this.axis.drag) {
      this.stage.updateAABB()
      this.stage.updateRenderObjs(this.quadTree)
    }

    let mouse_pos = this.getMousePosArray()
    if (this.preAddObj) {
      movePosByMouse(this, this.preAddObj, mouse_pos)
    }
    if (this.boardMode === BoardMode.PASTE) {
      //预粘贴
      if (!this.pre_copy_data) return
      const data_size = this.pre_copy_data.length
      for (let i = 0; i < data_size; i++) {
        moveObj(this, this.pre_copy_data[i], mouse_pos)
      }
    }
    //绘制图形
    layoutDrawObject(this)
    this.updateFlyLines()
    this.updateEditCpLine()
    this.stage.render()
  }

  mouseUp(e) {
    // if (this.viewMode) return
    const shiftKey = e.shiftKey
    const ctrlKey = e.ctrlKey
    // this.onMouseUpPool(e)
    // this.updateCoverIndex()

    this.axis.onMouseUp(e)
    this.axis.setDrag(false, null)
    this.updateMoveMode()
    if (this.moveMod === 2) {
      //旋转模式清除记录
      this.resetMoveMode()
    }
    const mode = this.boardMode

    //左键松开
    if (e.button === 0) {
      if (mode === BoardMode.SELECT) {
        if (this.drawingObject) {
          layoutCompleteDraw(this)
        } else if (!this.drag_count) {
          let hits = this.quadTree.queryByAABB(this.stage.mouse_aabb)
          hits.sort((a, b) => b.js_obj.tree_id - a.js_obj.tree_id)
          this.checked_objs_id = hits.map(hit => hit.js_obj.tree_id)
          this.updateCoverIndex()

          //   hits,
          //   hits.map(hit => hit.js_obj.tree_id),
          //   this.cover_index
          // )
          if (hits.length) {
            let hit = this.cover_index == -1 ? hits[0] : hits[this.cover_index]
            //反选
            if (ctrlKey) {
              hit.js_obj.checked = false
              this.checked_objs = this.checked_objs.filter(obj => obj !== hit)
              this.updateCheckedObjsAABB()
            } else if (shiftKey) {
              //多选
              if (this.checked_objs.indexOf(hit) == -1) {
                hit.js_obj.checked = true
                this.checked_objs.push(hit)
              }
            } else {
              this.checked_objs.forEach(obj => (obj.js_obj.checked = false))
              hit.js_obj.checked = true
              this.checked_objs = [hit]
              this.updateCheckedObjsAABB()
            }
          } else {
            this.checked_objs.forEach(obj => (obj.js_obj.checked = false))
            this.checked_objs = []
            this.updateCheckedObjsAABB()
          }
          this.updateMouseAABB()
        } else if (this.drag_count) {
          if (this.connectionTarget && this.stage.editCpLine) {
            // this.schema.connect(this.connectionTarget.port, this.stage.editCpLine)
            autoRenameCplineNetName(this, [this.stage.editCpLine])
            autoConnectPort(this, true)
          } else {
            autoRenameCplineNetName(this, this.checked_objs)
            autoConnectSymbolPort(this, this.checked_objs)
            autoConnectPort(this)
          }
          //拖动物体结束 循环线，自动连接物体
          // if (this.stage.editCpLine) {
          //   //更新线的原始长度
          //   this.stage.editCpLine.js_obj.originLength = this.stage.editCpLine.line.points.length
          // }
          updateLinePointsByPorts(this, this.checked_objs, this.schema)
        }
      } else if (this.boardMode === BoardMode.PASTE) {
        autoRenameCplineNetName(this, this.pre_copy_data)
        autoConnectSymbolPort(this, this.pre_copy_data)
        confirmPaste(this)
        autoConnectPort(this)
      } else if (this.boardMode === BoardMode.SYMBOL && this.drawingObject) {
        layoutCompleteDraw(this)
      }
      if (this.stage.editCpLine) {
        this.stage.editCpLine.line.swap(curve_del_parallel(this.stage.editCpLine.line.points))
        buildCpLinePath(this.stage.editCpLine)
      }

      this.leftPress = false
      this.drag_count = 0
    }
    //右键松开
    if (e.button === 2) {
      //自适应框
      if (this.drawAdaptRect && this.drawingObject) {
        layoutCompleteDraw(this)
      }
      this.rightPress = false
    }
    this.axis.angled_dsorp_point = null
    this.updateEditCpLine()
    this.stage.flyLines = null
    this.stage.render()
  }

  keyDown(e) {
    //判断鼠标聚焦在画板以外的文本框
    if (e.target?.tagName == 'INPUT') return //e.target.tagName == 'INPUT'
    const key = e.key.toLowerCase()

    if (document.getElementById('textInputBox').style.display == 'block') return
    let can_trigger = true //是否触发功能
    //判断弹窗是否打开
    document.querySelectorAll('div[aria-label]').forEach(obj => {
      if (obj.getAttribute('role') == 'dialog' && obj.parentNode.style.display !== 'none') can_trigger = false
    })
    if (!can_trigger) return
    if (e.key === 'f') {
      adaptScreen(this)
      return
    }

    if (e.shiftKey && key === 'b') {
      backUpperLevel(this)
      return
    }
    if (e.shiftKey && key === 'x') {
      enterlowerLevel(this)
      return
    }
    if (e.ctrlKey && (key === 'c' || key === 'x')) {
      copyOP(this, key === 'x')
      return
    }
    if (e.shiftKey && key === 'v') {
      this.onMouseWheel({ deltaY: -100 }, true)
      return
    }

    if (e.ctrlKey && key === 'a') {
      e.preventDefault()
      // let aabb = expandAABB(this.schema.bounding_box, 1) //原理图包围盒可能不包含引脚的名字标签
      this.checked_objs = this.quadTree.queryByAABB([
        [-10000000, -10000000],
        [10000000, 10000000],
      ])

      this.checked_objs.forEach(obj => (obj.js_obj.checked = true))
      this.updateCheckedObjsAABB()
      this.stage.render()
      return
    }

    if (this.viewMode) return
    // if (e.key === 'enter') {
    //   let inputBox = document.getElementById('textInputBox')
    //   inputBox.value = ''
    //   inputBox.style.display = 'none'
    //   return
    // }

    moveAxis(e, this.stage, this.axis, this.quadTree)
    this.press_shift = e.shiftKey
    this.press_ctrl = e.ctrlKey

    if (key === 'd') {
      this.switchBoardMode(BoardMode.MOVE)
      return
    }

    // if (e.ctrlKey && key === 's') {
    //   e.preventDefault()
    //   //保存
    //   bus.$emit('saveFile', true)
    //   this.save()
    //   return
    // }

    if (e.ctrlKey && key === 'v') {
      //粘贴
      pasteData(this)
      // this.switchBoardMode(BoardMode.SELECT)
      return
    }

    if (e.ctrlKey && key === 'z') {
      //撤销
      undo(this)
      // //剪切中途撤销
      // if (this.isCut && this.preCutObjs?.length) {
      //   this.isCut = false
      //   this.editHistory.redoStack.pop()
      //   this.preCutObjs.forEach(obj => (obj.preCut = false))
      //   this.preCutObjs = null
      // }

      return
    }

    if (e.ctrlKey && key === 'y') {
      //恢复
      redo(this)
      return
    }

    if (key === 'v') {
      //区域放大
      this.switchBoardMode(BoardMode.SELECT)
      this.drawType = 'adaptRect'
      return
    }
    if (this.press_ctrl) {
      updateDrawingCpLine(this)
      return
    }

    if (key === 'delete') {
      //删除
      deleteCheckedObjs(this)
      return
    }

    //完成绘制
    if (key === 'enter') {
      if (this.drawingObject) {
        layoutCompleteDraw(this)
      }
      return
    }

    if (key === 'escape') {
      this.switchBoardMode(BoardMode.SELECT)
      bus.$emit('escSchema', true)
      this.stage.checkedResBoxs = []
      // e.target.blur()
      // this.stage.clearTools()
      // if (this.drawingObject) {
      //   this.deleteGraphic(this.drawingObject)
      // }
      // this.axis.angled_dsorp_special_point = null
      // this.axis.angled_dsorp_point = null
      // this.drawingObject = null
      // this.drawPoints = []
      // this.ctDrawData = null
      // this.deletePreAddCell()
      return
    }

    if (key === 'e') {
      bus.$emit('selectCouplingLine', true)
      return
    }
    // 打开弹窗修改属性

    if (key === 'q' && e.shiftKey) {
      if (this.boardMode === BoardMode.CP_LINE) {
        bus.$emit('openCouplingLine', true)
      } else if (this.boardMode === BoardMode.PIN) {
        bus.$emit('openCreatePin', true)
      } else if (this.boardMode === BoardMode.LABEL) {
        bus.$emit('openLabel', true)
      }
      return
    }

    //高亮网络模式
    if (e.shiftKey && key == 'h') {
      bus.$emit('moveHighlight', true)
      this.switchBoardMode(BoardMode.RM_NET)
      return
    }
    if (key == 'h') {
      bus.$emit('netHighlight', true)
      this.switchBoardMode(BoardMode.HL_NET)
      return
    }
    if (key === 'p') {
      bus.$emit('openCreatePin', true)
      return
    }
    if (key === 'l') {
      bus.$emit('openLabel', true)
      return
    }

    // switch (e.key) {
    //   case '1':
    //     this.config.switchFont()
    //     break
    //   case '2':
    //     this.config.switchFont('kaiti')
    //     break
    //   case '3':
    //     this.config.switchFont('songti')
    //     break
    //   case '4':
    //     this.config.switchFont('special1')
    //     break
    //   case '5':
    //     changeCursor(this.canvasId, 'select')
    //     break
    //   case '6':
    //     changeCursor(this.canvasId, 'move')
    //     break
    // }
    // this.stage.render()
  }

  keyUp(e) {
    if (this.viewMode) return
    this.press_shift = false
    this.press_ctrl = false
  }

  // 鼠标滚轮缩放
  onMouseWheel(e, scale_center = false) {
    this.axis.onMouseWheel(e, scale_center)
    this.updateMouseAABB()
    this.config.onMouseWheel(this.axis.scale)
    this.stage.updateAABB()
    this.stage.updateRenderObjs(this.quadTree)
    this.stage.renderRulers()
    this.stage.render()
  }

  // 双击事件
  onDbClick(e) {
    if (this.drag_count) return
    if (this.checked_objs.length == 1 && this.checked_objs[0].$$?.ptrType.name == 'Label*') {
      let label = this.checked_objs[0]
      let inputBox = document.getElementById('textInputBox')
      inputBox.value = label.text
      inputBox.style.left = e.clientX + 'px'
      inputBox.style.top = e.clientY + 'px'
      inputBox.style.display = 'block'
      inputBox.focus()
      let that = this
      inputBox.onblur = function () {
        if (inputBox.value !== '' && inputBox.value !== label.text) {
          updateLableValue(that, label, inputBox.value)
        }
        inputBox.style.display = 'none'
      }
    }

    // if (window.getSelection)
    //   //清除选中元素 防止影响mouseMove执行
    //   window.getSelection().removeAllRanges()
    // else if (document.selection) document.selection.empty()
    // this.render()
  }
  updateCoverIndex() {
    //计算重叠状态下选择图形下标
    const checked_length = this.checked_objs_id.length
    const max_index = checked_length - 1
    if (checked_length > 1) {
      // if(this.drag_objs) return
      this.cover_index += 1
      if (this.cover_index > max_index) this.cover_index = 0
    } else {
      this.cover_index = -1
    }
    this.checked_objs_id = []
  }

  updatePreCoverIndex(move = false) {
    //计算重叠状态下选择图形下标
    const checked_length = this.pre_checked_objs_id.length
    const max_index = checked_length - 1

    if (checked_length > 0) {
      // this.pre_cover_index = this.cover_index + 1
      // if (this.pre_cover_index > max_index) this.pre_cover_index = 0
      // if (move) this.pre_cover_index = 0
      this.pre_cover_index = 0
    } else {
      this.pre_cover_index = -1
    }
    this.pre_checked_objs_id = []
    this.pre_checked_objs_all_id = []
  }

  updatePreCheckedObjsId(id, checked) {
    if (!this.pre_checked_objs_all_id.includes(id)) {
      this.pre_checked_objs_all_id.push(id)
      this.pre_checked_objs_all_id.sort((a, b) => b - a)
    }

    if (checked) return

    if (!this.pre_checked_objs_id.includes(id)) {
      this.pre_checked_objs_id.push(id)
      this.pre_checked_objs_id.sort((a, b) => b - a)
    }
  }

  canPreCheked(id) {
    if (this.pre_checked_objs_id.length < 1) return true
    if (this.cover_index > 0) {
      let index = this.cover_index + 1
      if (index > this.pre_checked_objs_all_id.length - 1) index = 0
      return this.pre_checked_objs_all_id[index] === id
    }
    return this.pre_checked_objs_id[this.pre_cover_index] === id
  }

  //更新点击移动相关状态
  updateMoveMode() {
    let mouse_pos = this.getMousePostion()
    let pos = [mouse_pos.x, mouse_pos.y]
    //整体移动，计算45度吸附角度
    if (this.moveMod === 0) {
      this.movePosition[0] = pos
    }
    if (this.moveMod === 1) {
      //点击移动模式判断，清除鼠标位置记录

      this.movePosition.push(pos)
      if (this.movePosition.length === 1) {
        if (this.boardMode === 0) {
          this.recordHistory('transform')
        } else if (this.boardMode === 1) {
          this.recordHistory('shape')
        }
      }
      if (this.movePosition.length > 1) {
        this.resetMoveMode()
      }
    }
    if (this.moveMod === 2) {
      //旋转模式判断，清除鼠标位置记录
      if (this.movePosition.length) {
        this.movePosition[1] = pos
      } else {
        this.movePosition.push(pos)
      }
    }
  }

  resetMoveMode() {
    bus.$emit('cancelSidebarSelect')
    this.moveMod = 0
    this.movePosition = []
  }

  // updateSelectObjByAABB(aabb, mode) {
  //   this.select_mode = mode
  //   this.select_AABB = aabb
  //   const minX = this.select_AABB[0][0]
  //   const minY = this.select_AABB[0][1]
  //   const maxX = this.select_AABB[1][0]
  //   const maxY = this.select_AABB[1][1]
  //   this.select_AABB_points = [
  //     { x: minX, y: minY },
  //     { x: minX, y: maxY },
  //     { x: maxX, y: minY },
  //     { x: maxX, y: maxY },
  //   ]
  //   this.stage.render()
  // }

  //框选
  pickObjsBySelectBox(aabb, flag) {
    let checkeds = this.quadTree.queryByAABB(aabb, flag)

    //反选
    if (this.press_ctrl) {
      checkeds.forEach(obj => (obj.js_obj.checked = false))
      this.checked_objs = this.checked_objs.filter(obj => !checkeds.includes(obj))
    } else if (this.press_shift) {
      //多选
      let checkeds = this.quadTree.queryByAABB(aabb, flag)
      checkeds.forEach(item => (item.js_obj.checked = true))
      this.checked_objs.push(...checkeds)
      this.checked_objs = noRepeat(this.checked_objs) //去重
    } else {
      this.checked_objs.forEach(item => (item.js_obj.checked = false))
      this.checked_objs = checkeds
      this.checked_objs.forEach(item => (item.js_obj.checked = true))
    }
    this.updateCheckedObjsAABB()

    this.updateEditCpLine()
  }

  //画板自适应aabb包围盒
  adaptAABB(target_aabb) {
    if (!validAABB(target_aabb)) return
    axisAdaptAABB(this.axis, this.stage, target_aabb)
    this.stage.updateAABB()
    this.stage.updateRenderObjs(this.quadTree)
    this.axis.render()
    this.stage.renderRulers()
    this.stage.render()
  }

  //切换画板状态
  switchBoardMode(mode) {
    let beforeMode = this.boardMode
    this.boardMode = mode
    this.resetBoardStatus(beforeMode, mode)
    this.check_able = mode === BoardMode.SELECT
    this.updateEditCpLine()
    this.stage.render()
  }

  //切换画板状态后重置数据
  resetBoardStatus(beforeMode, toMode) {
    if (this.drawingObject) {
      if (beforeMode === BoardMode.SYMBOL) {
        this.drawingObject
      }
      this.drawingObject.js_obj?.path?.delete()
      this.delete(this.drawingObject)
      this.drawingObject = null
    }
    if (this.preConnectNetLabel) {
      this.preConnectNetLabel.js_obj?.path?.delete()
      this.delete(this.preConnectNetLabel)
      this.preConnectNetLabel = null
    }
    this.drawType = 'selectBox'
    this.lineMod = false
    this.latestConfirmPos = null
    this.ctDrawData = null
    this.loopText = null
    this.ctText = []
    this.drag_count = 0
    //清除预粘贴对象
    if (this.pre_copy_data) {
      this.pre_copy_data.forEach(obj => this.delete(obj))
    }

    // if (toMode !== BoardMode.MOVE) { //移动前后不清空状态
    if (!(toMode == BoardMode.MOVE || beforeMode == BoardMode.MOVE)) {
      this.resetCheckStatus()
      // if (this.checked_objs.length) {
      //   this.checked_objs.forEach(obj => (obj.js_obj.checked = false))
      //   this.checked_objs = []
      //   this.updateCheckedObjsAABB()
      // }
      // // this.stage.editCpLine = null
    }

    this.pre_copy_data = null
    this.stage.editCpLine = null
    this.stage.flyLines = null
    this.stage.preAddObjs = []
    this.preConnectNetLabel = null
  }

  //清除选中对象状态
  resetCheckStatus() {
    this.checked_objs.forEach(obj => {
      obj.js_obj.checked = false
      obj.js_obj.preChecked = false
      obj.js_obj.preCut = false
    })
    this.checked_objs = []
    this.stage.editCpLine = null
    clearSchemaStatus(this.schema)
    this.updateCheckedObjsAABB()
  }
  newCpLine() {
    let line = new Kernel.Line()
    let cpLine = new Kernel.CpLine()
    cpLine.line = line
    cpLine.color = this.lineColor
    this.add(cpLine)
    this.drawingObject = cpLine
  }

  newPin(input, type) {
    // let input = 'Pin_1,Pin_2,Pin_3,Pin_4,Pin_5,Pin_6,Pin_7'
    this.loopText = input
    this.ctText = input.split(',')
    let text = getCtLabelText(this.ctText)
    if (text == null) {
      this.switchBoardMode(BoardMode.SELECT)
      return
    }
    let pinTypeMap = {
      input: Kernel.PinIO.In,
      output: Kernel.PinIO.Out,
      IO: Kernel.PinIO.InOut,
    }
    let pinType = pinTypeMap[type]
    // let pin = new Kernel.create_pin()
    let pinIns = new Kernel.pin() //new Kernel.Pin(pin)
    var label = new Kernel.Label()
    label.anchor = Kernel.Anchor.Centra
    // label.pos = this.getMousePosArray()
    label.text = text
    label.size = this.config.default_font_size
    label.color = '#2761a3'
    pinIns.name = label
    pinIns.pos = this.getMousePosArray()
    pinIns.io = pinType
    this.add(pinIns)
    this.drawingObject = pinIns
    this.ctDrawData = pinIns
  }

  newLabel(loopText, font, anchor, size, color, is_net = false) {
    this.loopText = loopText //'A,B,C,D,E,F,G'
    this.ctText = this.loopText.split(',')
    let text = getCtLabelText(this.ctText)
    if (text == null) {
      this.switchBoardMode(BoardMode.SELECT)
      return
    }
    var label = new Kernel.Label()
    label.pos = this.getMousePosArray()
    label.text = text
    label.size = size //this.config.default_font_size
    label.is_net = is_net
    label.font = font
    label.anchor = anchor
    label.color = color
    this.add(label)
    this.drawingObject = label
    this.ctDrawData = label
  }

  newSymbol(symbolIns) {
    if (!symbolIns) {
      let file = new Kernel.test()
      let symbol = file.symbols.get(0)
      symbolIns = new Kernel.SymbolIns(symbol)
    }
    symbolIns.ins_name = symbolIns.symbol.short_name
    symbolIns.pos = this.getMousePosArray()
    // this.add(symbolIns)
    buildSymbolPath(symbolIns)
    this.drawingObject = symbolIns
    this.stage.preAddObjs = [symbolIns]
  }

  //原理图添加数据
  add(obj, target, updateFileTree = true, reRender = true) {
    let schema = target ? target : this.schema
    if (obj instanceof Kernel.Label) {
      buildLabelBox(obj, this.config)
      schema.add_label(obj)
      this.quadTree.addNode(obj)
    } else if (obj instanceof Kernel.Pin) {
      revertPortConnectPorts(obj, schema)
      obj.name.text = obj.name.text //newInsName(obj.name.text, this.getPinNames())
      buildPinPath(obj, this.config)

      schema.add_pin(obj)
      this.quadTree.addNode(obj)
      // this.quadTree.addNode(obj.name)
    } else if (obj instanceof Kernel.SymbolIns) {
      revertPortConnectPorts(obj, schema)
      //重命名
      if (obj.symbol) {
        obj.ins_name = newInsName(obj.symbol.short_name, getSymbolInsNames(this.schema))
      }

      buildSymbolPath(obj)
      schema.add_symbol_ins(obj)
      if (reRender) {
        bus.$emit('reRenderSchemaBoard')
      }

      this.quadTree.addNode(obj)
      if (updateFileTree) {
        bus.$emit('updateFileTree', obj.symbol)
      }
    } else if (obj instanceof Kernel.CpLine) {
      revertCplineConnectPorts(obj, schema)
      if (obj.net_name === '') {
        obj.net_name = newNetName(this)
      }
      buildCpLinePath(obj)

      schema.add_cp_line(obj)
      this.quadTree.addNode(obj)
    }
  }

  //原理图删除数据
  delete(obj, target) {
    if (!obj) return
    let schema = target ? target : this.schema

    if (obj instanceof Kernel.Label) {
      // let labels = schema.labels
      // labels.delete(obj)
      schema.remove_label(obj)
    } else if (obj instanceof Kernel.Pin) {
      // let pins = schema.pins
      // pins.delete(obj)
      schema.remove_pin(obj)
    } else if (obj instanceof Kernel.SymbolIns) {
      this.schema.remove_symbol_ins(obj)

      // for (let i = 0; i < this.schema.capsymbol_ins.size; i++) {

      // }
      bus.$emit('refreshCellRef', this.schema.snow_id)
    } else if (obj instanceof Kernel.CpLine) {
      // let cp_lines = schema.cp_lines
      // cp_lines.delete(obj)
      schema.remove_cp_line(obj)
    }
    this.quadTree.removeNode(obj)
    // if (obj.ins_name) {
    //   this.quadTree.removeNode(obj.ins_name)
    // }
  }

  //记录历史
  record(type, func) {
    recordHistory(this, type, func)
  }

  updateCheckedObjsAABB() {
    this.checkedGraphicsAABB = null
    this.checkedGraphicsAABB_center = null
    this.checked_objs.forEach(obj => {
      let aabb = obj.bounding_box
      this.checkedGraphicsAABB = calGraphicsAABB(this.checkedGraphicsAABB, aabb)
    })
    if (this.checkedGraphicsAABB) {
      //计算选中物体包围盒中心点坐标
      const w = this.checkedGraphicsAABB[1][0] - this.checkedGraphicsAABB[0][0]
      const h = this.checkedGraphicsAABB[1][1] - this.checkedGraphicsAABB[0][1]
      this.checkedGraphicsAABB_center = [this.checkedGraphicsAABB[0][0] + w / 2, this.checkedGraphicsAABB[0][1] + h / 2]
    }
  }

  //重构四叉树
  rebuildQuadTree() {
    this.quadTree = new SchemaQuadTree(this.schema)
  }

  //回到原点
  returnOrigin() {
    this.axis.setCenter()
    this.axis.updateValue()
    this.reRenderBoard()
  }

  //重新渲染画板刻度尺
  reRenderBoard() {
    this.axis.render()
    this.config.onMouseWheel(this.axis.scale)
    this.stage.updateAABB()
    this.stage.renderRulers()
    this.stage.render()
  }

  //重新渲染版图
  reRenderSchema() {
    let aabb = this.schema.bounding_box

    //自适应渲染
    if (aabb && aabb[0][0] < aabb[1][0]) {
      this.adaptAABB(aabb)
    } else {
      //默认缩放平移到原点
      this.returnOrigin()
    }
  }

  //更新编辑模式的耦合线
  updateEditCpLine() {
    if (this.checked_objs.length == 1 && this.checked_objs[0] instanceof Kernel.CpLine) {
      let editCpLine = this.checked_objs[0]
      editCpLine.line.js_obj.midPoints = getMidPoints(editCpLine.line.points)
      this.stage.editCpLine = editCpLine
    } else {
      this.stage.editCpLine = null
      if (this.stage.editCpLine) {
        this.stage.editCpLine.js_obj.checked = false
      }
    }
  }

  clearCheckedObjs() {
    this.checked_objs.forEach(obj => {
      obj.js_obj.preChecked = false
      obj.js_obj.checked = false
    })
    this.checked_objs = []
    this.updateCheckedObjsAABB()
  }

  clearCheckedObj(obj) {
    let index = this.checked_objs.indexOf(obj)
    for (let i = 0; i < this.checked_objs.length; i++) {
      const checked = this.checked_objs[i]
      if (obj.$$.ptr == checked.$$.ptr) {
        this.checked_objs.splice(index, 1)
        obj.js_obj.preChecked = false
        obj.js_obj.checked = false
      }
    }
  }

  getPinNames() {
    let res = []
    for (let i = 0; i < this.schema.pins.length; i++) {
      res.push(this.schema.pins[i].name.text)
    }
    return res
  }

  aStartTest() {
    let start = [100, 500]
    let end = [1000, 1000]
    let res = getAStartPath(this.quadTree, start, end)
    let points = res.points
    if (!points.length) {
      points = [start, end]
    }
    let path = new CanvasKit.Path()
    // points.unshift(start)
    // points.push(end)
    if (points.length) {
      let len = points.length
      for (let i = 0; i < len; i++) {
        let p = points[i]
        if (!i) {
          path.moveTo(p[0], p[1])
        } else {
          path.lineTo(p[0], p[1])
        }
      }
    }

    res.path = path
    res.start = start
    res.end = end
    this.stage.aStartDebug = res
  }

  updateFlyLines() {
    if (this.drag_count) {
      if (this.checked_objs.length) {
        let labels = this.checked_objs.filter(obj => obj.belong_to && obj.belong_to.$$?.ptrType.name !== 'Schema*' && !obj.belong_to.js_obj.checked)
        let flyLines = []
        if (labels.length) {
          labels.forEach(label => {
            let p1 = label.js_obj.center
            let p2

            if (label.belong_to.$$?.ptrType.name == 'CpLine*') {
              p2 = minDistLineCenter(p1, label.belong_to.line.points)
            } else {
              p2 = label.belong_to.js_obj.center
            }
            flyLines.push([p1, p2])
          })

          let path = new CanvasKit.Path()
          flyLines.forEach(line => {
            path.moveTo(line[0][0], line[0][1])
            path.lineTo(line[1][0], line[1][1])
          })
          this.stage.flyLines = path
        }
      }
    }
  }

  setDisplayConfig() {
    if (Object.keys(store.state.currentSchemaBoardSettings).length) {
      let setting = store.state.currentSchemaBoardSettings

      this.stage.config.colors.backgroundColor = setting.value_backgroundColor
      this.stage.config.colors.gridColor = setting.value_gridColor
      this.stage.config.showGrid = setting.gridVisible === '1'
      this.stage.config.gridSize = setting.gridSize
      let p = parseFloat(setting.adsorption)
      this.axis.adsorp_precision = p
    } else {
    }
  }

  reRenderSchemaData() {
    buildRenderData(this.schema, this.config)
    this.stage.render()
  }

  async lockFile(cellId) {
    if (!cellId) {
      cellId = this.schema.snow_id
    }
    let lock = lockCell_api({ file_snow_id: cellId, snow_team_id: this.topInfo.teamId })
    lock.then(data => {
      if (data.code === 200000) {
        this.viewMode = false
      } else {
        // Message.warning(data.message)
        // alert(546)
        this.viewMode = true
      }
    })
  }
  async unLockFile(schemas) {
    // await this.sendMessage({ func: 'CLOSE' })
    if (schemas && Array.isArray(schemas) && schemas.length) {
      for (let i = 0; i < schemas.length; i++) {
        await unLockCell_api({ file_snow_id: schemas[i].snow_id })
      }
    } else {
      await unLockCell_api({ file_snow_id: this.schema.snow_id })
    }
  }

  async canAccessFile(id) {
    let data = await selectLockCell_api(id)
    if (data.code === 200000) {
      if (data.message == 'not lock') {
        return true
      } else if (data.message == 'locked') {
        Message.error(`${i18n.t('messages.code_405025')}`)
        return false
      }
    }
    return false
  }

  //存在编辑权限
  async hasCellPermission(cellId) {
    if (this.cellInfo.teamRule) {
      let gns = `gns://${this.topInfo.teamId}/${this.projectInfo.projectId}/${this.fileInfo.fileId}/${cellId}`
      const res = await getPermission(gns)

      if (res.data?.permission?.indexOf('编辑')) {
        return true
      }
      Message.error(`${i18n.t('messages.userCellPermission')}`)
      return false
    }
    return true
  }
  updateCheckedObjs() {
    // this.checked_objs
    // this.eche
  }
}
