import { deepClone } from '../../utils'
import i18n from '@/common/lang/i18n'
import {
  SchemaQuadTree,
  BoardMode,
  buildObjPath,
  buildRenderData,
  updateMouseOffSet,
  copy,
  getMouseOffSet,
  aabbCenter,
  updateLinePointsByPorts,
  copyObjs,
  deepCopyObjs,
  autoConnectPort,
  buildCpLinePath,
  getBoxCenter,
  autoAddCplinePortPoints,
  mirrorObj,
  newInsName,
  getSymbolInsNames,
  reNameCopyData,
  mergeNearPoints,
  hasCircle,
  fixedPoints,
  autoConnectSymbolPort,
  recordCplineConnectPorts,
} from './layout-util'
import { hexToRgb } from '../render-util'
import bus from '@/components/common/bus'
import { saveCellFn } from '../../../components/homes/fileList/function/fileListPublicFn'
const recordOrder = {
  'CpLine*': 0,
  'Pin*': 1,
  'Label*': 2,
  'SymbolIns*': 3,
  undefined: 4,
}
//修改顺序Pin必现在Label之前
const historyOrder = {
  'CpLine*': 0,
  'Pin*': 1,
  'SymbolIns*': 2,
  'Label*': 3,
  undefined: 4,
}
//框选 多选
export function selectObjByAABB(obj) {
  // if (this.select_AABB) {
  //   //鼠标拖动生成选择框
  //   if (this.boardMode === 0) {
  //     //整体选择
  //     if (!obj.checked) {
  //       //判断未选中的图形
  //       obj.checked = this.checkInsideSelectBoxOver(obj)
  //     }
  //   } else if (this.boardMode === 1) {
  //     if (obj.isRefBox || obj.type === QedaGraphicType.LABEL || obj.type === QedaGraphicType.KEYPOINT || obj.type === QedaGraphicType.RULER) return
  //     //部分选择
  //     if (this.checkInsideSelectBoxPart(obj.aabb, true)) {
  //       //包围盒剔除
  //       obj.checked_points_index.push(...checkInsideSelectBoxPoints(this.select_AABB, obj.shape, obj.type === QedaGraphicType.PATH, obj.type === QedaGraphicType.CIRCLE)) //计算点
  //       obj.checked_points_index = Array.from(new Set(obj.checked_points_index)) //去重
  //       obj.checked_points_index.sort((a, b) => a - b) //排序
  //       obj.updateCheckedPaths()
  //       obj.updateLines()
  //     }
  //   }
  // }
  // if (this.check_all) {
  //   //全选
  //   if (this.boardMode === 0) {
  //     //整体选择
  //     obj.hited = true
  //     obj.checked = true
  //   } else if (this.boardMode === 1) {
  //     //部分选择
  //     obj.checked_points_index = obj.shape.map((p, index) => index)
  //     obj.updateCheckedPaths()
  //     obj.updateLines()
  //   }
  // } else if (obj.checked || obj.checked_points_index.length) {
  //   //只有左键拖动才固定画板
  //   this.checked_objs.push(obj)
  //   if (!this.checkedGraphicsAABB) {
  //     //赋初值
  //     this.checkedGraphicsAABB = [
  //       [obj.aabb[0][0], obj.aabb[0][1]],
  //       [obj.aabb[1][0], obj.aabb[1][1]],
  //     ]
  //   }
  //   calGraphicsAABB(this.checkedGraphicsAABB, obj.aabb)
  // }
}

//向原理图添加对象
export function addObj(LAYOUT, obj) {}
//向原理图删除对象
export function deleteObj(LAYOUT, obj) {}

//删除选中图形
export function deleteCheckedObjs(LAYOUT) {
  const data_size = LAYOUT.checked_objs.length
  recordCplineConnectPorts(LAYOUT.checked_objs, LAYOUT.schema)
  let editRef = false
  let records = []
  for (let i = 0; i < data_size; i++) {
    let deleteObj = LAYOUT.checked_objs[i]
    if (deleteObj.$$?.ptrType.name == 'CpLine*') {
      let labels_size = deleteObj.labels.length
      let labels = deleteObj.labels
      for (let i = 0; i < labels_size; i++) {
        const label = labels[i]
        if (!label.js_obj.checked) {
          LAYOUT.delete(label)
          records.push(label)
        }
      }
    }
    if (deleteObj.$$?.ptrType.name == 'Pin*' || deleteObj.$$?.ptrType.name == 'SymbolIns*') {
      let port_size = deleteObj.ports.length
      let ports = deleteObj.ports
      for (let i = 0; i < port_size; i++) {
        const port = ports[i]
        let res = LAYOUT.schema.query_port(port)

        res.forEach(obj => {
          //删除看不见的耦合线 一个点
          let cpl = obj[0]
          let aabb = cpl.bounding_box
          if (JSON.stringify(aabb[0]) == JSON.stringify(aabb[1]) && records.filter(obj => obj.$$.ptr == cpl.$$.ptr).length == 0) {
            LAYOUT.delete(cpl)
            records.push(cpl)
          }
        })
      }
    }
    if (deleteObj.$$?.ptrType.name == 'Label*' && deleteObj.belong_to?.$$?.ptrType.name == 'Pin*' && !deleteObj.belong_to.js_obj.checked) {
      continue //引脚标签无法单独删除
    }
    deleteObj.js_obj.checked = false
    deleteObj.js_obj.preChecked = false
    LAYOUT.delete(deleteObj)
    records.push(deleteObj)
  }
  if (records.length) {
    LAYOUT.editHistory.record([{ action: 'delete', schema: LAYOUT.schema, objs: records }])
    LAYOUT.resetCheckStatus()
    LAYOUT.stage.render()
    LAYOUT.checked_objs = []
  }
}

//对齐
export function alignmentObjs(LAYOUT, alignType) {
  const data_size = LAYOUT.checked_objs.length
  if (data_size <= 1) return

  LAYOUT.checked_objs.sort((a, b) => historyOrder[a.$$?.ptrType.name] - historyOrder[b.$$?.ptrType.name])
  //记录标签当前位置，Pin改pos后其name label的pos会自动改变需要提前记录
  LAYOUT.checked_objs.forEach(obj => {
    if (obj.$$?.ptrType.name == 'Label*') {
      obj.js_obj.recordPos = obj.pos
      obj.js_obj.recordBoundingBox = obj.bounding_box
    }
  })
  LAYOUT.updateCheckedObjsAABB()
  LAYOUT.record('transform', 'align')
  const checkedCplines = []
  const data = LAYOUT.checked_objs
  // const center = LAYOUT.checkedGraphicsAABB_center
  const aabb = LAYOUT.checkedGraphicsAABB
  const minX = aabb[0][0]
  const minY = aabb[0][1]
  const maxX = aabb[1][0]
  const maxY = aabb[1][1]
  // let alignType = '上对齐' //params[0]
  let first_center = aabbCenter(data[0].bounding_box)
  let objs_minX = first_center[0]
  let objs_minY = first_center[1]
  let centerPosoffsets = []
  if (alignType === '竖向中线对齐' || alignType === '横向中线对齐') {
    for (let i = 0; i < data_size; i++) {
      //查询最小横竖中线
      let center = aabbCenter(data[i].bounding_box)
      let pos = center
      if (data[i].pos) pos = data[i].pos
      centerPosoffsets.push([pos[0] - center[0], pos[1] - center[1]])
      if (objs_minX > center[0]) {
        objs_minX = center[0]
      }
      if (objs_minY > center[1]) {
        objs_minY = center[1]
      }
    }
  }
  for (let i = 0; i < data_size; i++) {
    const obj = data[i]
    let isLine = obj instanceof Kernel.CpLine
    let p = isLine ? aabbCenter(obj.bounding_box) : obj.pos
    if (obj.js_obj.recordPos) {
      p = obj.js_obj.recordPos
    }
    let bounding_box = obj.bounding_box
    if (obj.js_obj.recordBoundingBox) {
      bounding_box = obj.js_obj.recordBoundingBox
    }

    switch (alignType) {
      case '左对齐': //左对齐
        p[0] = minX + p[0] - bounding_box[0][0]
        break
      case '右对齐': //右对齐
        p[0] = maxX - bounding_box[1][0] + p[0]
        break
      case '下对齐': //下对齐
        p[1] = minY + p[1] - bounding_box[0][1]
        break
      case '上对齐': //上对齐
        p[1] = maxY - bounding_box[1][1] + p[1]
        break
      case '竖向中线对齐': //中垂线对对齐
        p[0] = objs_minX + centerPosoffsets[i][0]
        break
      case '横向中线对齐': //中水平线对齐
        p[1] = objs_minY + centerPosoffsets[i][1]
        break
    }
    if (isLine) {
      checkedCplines.push(obj)
      let center = aabbCenter(obj.bounding_box)
      let trans = [p[0] - center[0], p[1] - center[1]]
      let points = obj.line.points
      points.forEach(pos => {
        pos[0] += trans[0]
        pos[1] += trans[1]
      })
      obj.line.swap(points)
    } else {
      obj.pos = p
    }

    buildObjPath(obj, LAYOUT.config)
    LAYOUT.quadTree.updateNode(obj)
  }
  updateLinePointsByPorts(LAYOUT, data, LAYOUT.schema)
  autoAddCplinePortPoints(LAYOUT, checkedCplines)
  autoConnectPort(LAYOUT)
  LAYOUT.stage.render()
  return
}

//翻转
export function flip(LAYOUT, flip = 'X') {
  const length = LAYOUT.checked_objs.length
  if (!length) return
  LAYOUT.updateCheckedObjsAABB()
  let checkedCplines = []
  let fromDatas = []
  let toDatas = []
  const X = LAYOUT.checkedGraphicsAABB_center[0]
  let params = [
    [X, 0],
    [X, 1],
  ]
  if (flip === 'Y') {
    const Y = LAYOUT.checkedGraphicsAABB_center[1]
    params = [
      [0, Y],
      [1, Y],
    ]
  }
  let newtLables = []
  for (let i = 0; i < length; i++) {
    const obj = LAYOUT.checked_objs[i]
    if (obj instanceof Kernel.CpLine) {
      checkedCplines.push(obj)
      fromDatas.push(obj.line.points)
      toDatas.push(null)
      obj.line.mirror(params[0], params[1])
      for (let k = 0; k < obj.labels.length; k++) {
        const netLabel = obj.labels[k]
        if (netLabel.js_obj.checked) continue
        netLabel.mirror(params[0], params[1])
        newtLables.push(netLabel)
      }
    } else {
      fromDatas.push(null)
      toDatas.push(null)
      // if (obj.belong_to?.js_obj.checked) continue
      if (obj.belong_to?.$$?.ptrType.name === 'Pin*' && obj.belong_to?.js_obj.checked) {
        continue
      }
      // if (obj.belong_to?.$$?.ptrType.name === 'CpLine*' && obj.belong_to?.js_obj.checked) {
      //   continue
      // }
      mirrorObj(obj, params)
    }
    buildObjPath(obj, LAYOUT.config)
    LAYOUT.quadTree.updateNode(obj)
  }
  autoAddCplinePortPoints(LAYOUT, checkedCplines)
  updateLinePointsByPorts(LAYOUT, LAYOUT.checked_objs, LAYOUT.schema)
  mergeNearPoints(checkedCplines)
  // autoConnectPort(LAYOUT)
  LAYOUT.editHistory.record([{ action: 'mirror', schema: LAYOUT.schema, objs: LAYOUT.checked_objs.concat(newtLables), fromDatas, toDatas, from: params, to: null }])
  LAYOUT.stage.render()
}

//阵列
export function arrayObjs(LAYOUT, params) {
  // params = [4, 4, 100, 0, 0, 100]
  const data_size = LAYOUT.checked_objs.length
  if (!data_size) return
  LAYOUT.updateCheckedObjsAABB()

  const data = LAYOUT.checked_objs
  const start = LAYOUT.checkedGraphicsAABB_center //起始点
  const start_x = start[0]
  const start_y = start[1]
  const offset = [] //保存偏移量
  for (let i = 0; i < data_size; i++) {
    offset.push(getMouseOffSet(data[i], start))
  }

  //输入参数
  const row = params[0]
  const clo = params[1]
  const row_vector = { x: params[2], y: params[3] }
  const col_vector = { x: params[4], y: params[5] }
  const positions = []
  for (let i = 0; i < row; i++) {
    for (let j = 0; j < clo; j++) {
      let x = start_x + j * col_vector.x + i * row_vector.x
      let y = start_y + i * row_vector.y + j * col_vector.y
      positions.push([x, y])
    }
  }

  positions.shift() //删除被复制项
  const size = positions.length
  let record_data = []
  for (let i = 0; i < size; i++) {
    for (let j = 0; j < data_size; j++) {
      const copy_data = []
      let off = offset[j]
      let p_new = []
      let obj = data[j]
      if (obj.$$?.ptrType.name === 'Label*' && obj.belong_to) {
        continue
      }

      if (obj instanceof Kernel.CpLine) {
        let points = obj.line.points
        let p_start = points[0]
        for (let k = 0; k < points.length; k++) {
          let p = points[k]
          p = [positions[i][0] - off[k][0], positions[i][1] - off[k][1]]
          p_new.push(p)
        }
        let copyCpl = copy(data[j], p_new)
        let p_after = copyCpl.line.points[0]
        let offCpl = [p_after[0] - p_start[0], p_after[1] - p_start[1]]
        copy_data.push(copyCpl)
        // let center = getBoxCenter(copyCpl.bounding_box)
        for (let k = 0; k < copyCpl.labels.length; k++) {
          const netLabel = copyCpl.labels[k]
          let x = netLabel.pos[0]
          let y = netLabel.pos[1]
          //保证网络标签相对线的位置不变
          // netLabel.pos = [center[0] + x - start[0], center[1] + y - start[1]]
          netLabel.pos = [x + offCpl[0], y + offCpl[1]]
          copy_data.push(netLabel)
        }
      } else {
        p_new = [positions[i][0] - off[0], positions[i][1] - off[1]]
        copy_data.push(copy(data[j], p_new))
      }
      copy_data.forEach(cpData => {
        LAYOUT.add(cpData, null, false, false)
        record_data.push(cpData)
      })
    }
  }
  LAYOUT.editHistory.record([{ action: 'add', schema: LAYOUT.schema, objs: record_data }])
  autoConnectPort(LAYOUT)
  LAYOUT.stage.render()

  bus.$emit('ending', true)
}

export function copyOP(LAYOUT, isCut) {
  if (!LAYOUT.check_able || !LAYOUT.checked_objs.length) return
  //复制 剪切
  // LAYOUT.copyData = copyObjs(LAYOUT.checked_objs)
  // LAYOUT.updateCheckedObjsAABB()
  // LAYOUT.copyDataCenter = deepClone(LAYOUT.checkedGraphicsAABB_center)
  let copyData = copyObjs(LAYOUT.checked_objs)
  LAYOUT.updateCheckedObjsAABB()
  let copyDataCenter = deepClone(LAYOUT.checkedGraphicsAABB_center)

  window.SCHEMABOARD.copyData = { data: copyData, center: copyDataCenter, fileId: LAYOUT.fileInfo.fileId }
  LAYOUT.isCut = isCut
  if (LAYOUT.isCut) {
    LAYOUT.preCutObjs = LAYOUT.checked_objs
  }
}
//预粘贴
export function prePasteData(LAYOUT) {
  let data = LAYOUT.copyData
  let start = LAYOUT.copyDataCenter //起始点

  const data_size = data.length
  const mouse_pos = LAYOUT.getMousePosArray()
  if (!start) return
  const offset = [] //保存偏移量
  for (let i = 0; i < data_size; i++) {
    offset.push(getMouseOffSet(data[i], start))
  }

  const pre_copy_data = []
  for (let j = 0; j < data_size; j++) {
    const copy_data = []
    let obj = data[j]
    if (obj.$$?.ptrType.name === 'Label*' && obj.belong_to && obj.belong_to.$$?.ptrType.name !== 'Schema*') {
      continue
    }
    let p_new = []
    let off = offset[j]
    if (obj instanceof Kernel.CpLine) {
      let points = obj.line.points
      for (let i = 0; i < points.length; i++) {
        let p = points[i]
        p = [mouse_pos[0] - off[i][0], mouse_pos[1] - off[i][1]]
        p_new.push(p)
      }
      let copyCpl = copy(data[j], p_new)
      copy_data.push(copyCpl)
      for (let k = 0; k < copyCpl.labels.length; k++) {
        const netLabel = copyCpl.labels[k]
        let x = netLabel.pos[0]
        let y = netLabel.pos[1]
        //保证网络标签相对线的位置不变
        netLabel.pos = [mouse_pos[0] + x - start[0], mouse_pos[1] + y - start[1]]
        copy_data.push(netLabel)
      }
    } else {
      // if (obj.$$?.ptrType.name == 'SymbolIns*') {
      //   obj.ins_name = newInsName(obj.symbol.short_name, getSymbolInsNames(LAYOUT.schema))
      // }
      p_new = [mouse_pos[0] - off[0], mouse_pos[1] - off[1]]
      copy_data.push(copy(data[j], p_new))
    }

    copy_data.forEach(cp_data => {
      updateMouseOffSet([cp_data], mouse_pos)
      // copy_data.preAdd = true
      // copy_data.checked = true
      // LAYOUT.add(cp_data)
      buildObjPath(cp_data, LAYOUT.stage.config)
      pre_copy_data.push(cp_data)
    })
  }
  // LAYOUT.editHistory.record([{ action: 'add', schema: LAYOUT.schema, objs: pre_copy_data }])
  LAYOUT.pre_copy_data = pre_copy_data
  LAYOUT.stage.preAddObjs = pre_copy_data
}

//粘贴
export function pasteData(LAYOUT) {
  if (!LAYOUT.check_able) return
  LAYOUT.copyData = window.SCHEMABOARD.copyData.data
  LAYOUT.copyDataCenter = window.SCHEMABOARD.copyData.center
  let copyCrossFile = window.SCHEMABOARD.copyData.fileId !== LAYOUT.fileInfo.fileId
  if (copyCrossFile) {
    LAYOUT.copyData = deepCopyObjs(LAYOUT, window.SCHEMABOARD.copyData.data)
    // reNameCopyData(LAYOUT, LAYOUT.copyData)
  }
  if (LAYOUT.copyData) {
    if (LAYOUT.pre_copy_data) {
      return
    }
    //判断环
    if (hasCircle(LAYOUT.schema, LAYOUT.copyData)) {
      bus.$emit('errDialog', i18n.t('messages.callClosedLoop'))
      return
    }
    LAYOUT.resetCheckStatus()
    if (LAYOUT.isCut) {
      LAYOUT.preCutObjs.forEach(obj => (obj.js_obj.preCut = true))
    }
    // resetCheckStatus(LAYOUT)
    prePasteData(LAYOUT)
    // this.switchBoardMode(BoardMode.PASTE)
    LAYOUT.boardMode = BoardMode.PASTE
    LAYOUT.stage.editCpLine = null
    LAYOUT.stage.render()
  }
}

//删除剪切数据
export function deleteCutObjs(LAYOUT) {
  // if (!LAYOUT.pre_copy_data?.length) {
  if (!LAYOUT.preCutObjs?.length) {
    //中途撤销
    LAYOUT.preCutObjs?.forEach(obj => (obj.js_obj.preCut = false))
    return
  }
  const data_size = LAYOUT.preCutObjs.length
  let record = []
  for (let i = 0; i < data_size; i++) {
    let obj = LAYOUT.preCutObjs[i]
    LAYOUT.delete(obj)
    obj.js_obj.preCut = false
    record.push(obj)
  }
  // LAYOUT.editHistory.undoStack.at(-1).push({ action: 'delete', schema: LAYOUT.schema, objs: record })
  // LAYOUT.editHistory.record([{ action: 'delete', schema: LAYOUT.schema, objs: record }])
  // LAYOUT.stage.render()
  return { action: 'delete', schema: LAYOUT.schema, objs: record }
}

//重置选中状态
export function resetCheckStatus(LAYOUT) {
  let cut = LAYOUT.isCut
  const data_size = LAYOUT.checked_objs.length
  LAYOUT.preCutObjs = []
  for (let i = 0; i < data_size; i++) {
    const obj = LAYOUT.checked_objs[i]
    obj.js_obj.preChecked = false
    obj.js_obj.checked = false
    if (cut) {
      obj.js_obj.preCut = true
    }
  }
  LAYOUT.stage.render()
}

//确认粘贴
export function confirmPaste(LAYOUT) {
  let deletRecord
  if (LAYOUT.isCut) {
    deletRecord = deleteCutObjs(LAYOUT)
  }
  if (LAYOUT.pre_copy_data) {
    const symbolIns = LAYOUT.pre_copy_data.filter(obj => obj.$$?.ptrType.name == 'SymbolIns*')
    let crossData = window.SCHEMABOARD.copyData.fileId !== LAYOUT.fileInfo.fileId
    if (crossData && symbolIns.length) {
      bus.$emit('updateFileTree', symbolIns)
    }
    LAYOUT.pre_copy_data.forEach(obj => {
      if (crossData) {
        LAYOUT.add(obj, null, false)
      } else {
        LAYOUT.add(obj)
      }
    })
    bus.$emit('updateSchemaFileTree', true)
    let records = [{ action: 'add', schema: LAYOUT.schema, objs: LAYOUT.pre_copy_data }]
    if (deletRecord) {
      records.push(deletRecord)
    }

    LAYOUT.editHistory.record(records)
  }
  LAYOUT.pre_copy_data = null
  LAYOUT.switchBoardMode(BoardMode.SELECT)
}
//保存
export function saveFile(LAYOUT) {
  // if (!this.file_snow_id) return
  // let id = this.stage.qedaLib.fileID
  // let qedaLib = this.stage.qedaLib
  // let lib = qedaLib.QLIB
  // lib.write_gds(id)
  // let file = window.QGdstk.FS.readFile(id)
  // var formData = new FormData() //新建表单对象
  // formData.append('file_snow_id', this.file_snow_id)
  // let layers = []
  // let layerDatas = store.state.layerDatas.filter(item => this.fileInfo.fileId === item.file_snow_id)[0].layerDatas
  // layerDatas.forEach(obj =>
  //   layers.push({
  //     border: obj.border,
  //     borderColor: obj.borderColor,
  //     color: obj.color,
  //     isExport: obj.isExport,
  //     layerName: obj.layerName,
  //     layerNumber: obj.layerNumber,
  //     lock: obj.lock,
  //     hide: obj.hide,
  //     shape: obj.shape,
  //   })
  // )
  // formData.append('layer_infos', JSON.stringify(layers))
  // formData.append('file', new Blob([file])) //把文件二进制对象添加到表单对象里
  // let save = saveCellFile_api(formData)
  // save.then(data => {
  //   if (data.code === 200000) {
  //     bus.$emit('saveFileSuccessToFile', true)
  //     bus.$emit('saveFileSuccess', this.fileInfo.fileId)
  //   } else {
  //     Message.error(data.message)
  //   }
  // })
}
//撤销
export function undo(LAYOUT) {
  LAYOUT.resetCheckStatus()
  if (LAYOUT.boardMode == BoardMode.PASTE || LAYOUT.drawingObject) return
  // this.stage.pre_checked_obj = null
  // this.drawingObject = null
  // this.pre_copy_data = null
  // this.copyData = null
  // this.copyDataCenter = null
  // this.stage.preCutObjs.forEach(obj => (obj.preCut = false))
  let undos = LAYOUT.editHistory.undo()
  let undo_len = undos?.length
  if (!undo_len) return
  recordCplineConnectPorts(LAYOUT.schema.cp_lines, LAYOUT.schema)
  let order = { delete: 0, add: 1, transform: 2, mirror: 3, property: 4, text: 5 }
  undos.sort((a, b) => order[a.action] - order[b.action])
  //有些操作同时带有多个操作

  // let modSymbolIns = []
  for (let i = 0; i < undo_len; i++) {
    undoHistorys(LAYOUT, undos[i])
    // undos[i].objs.forEach(obj => {
    //   if (obj.$$?.ptrType.name == 'SymbolIns*') {
    //     modSymbolIns.push(obj)
    //   }
    // })
  }
  // this.fileChange()
  LAYOUT.resetCheckStatus()
  LAYOUT.updateEditCpLine()
  // if (modSymbolIns.length) {
  //   alert(modSymbolIns.length)
  //   autoConnectSymbolPort(LAYOUT, modSymbolIns)
  // }
  // autoConnectPort(LAYOUT)
  LAYOUT.stage.render()
  return
}
//恢复
export function redo(LAYOUT) {
  LAYOUT.resetCheckStatus()
  if (LAYOUT.boardMode == BoardMode.PASTE || LAYOUT.drawingObject) return
  let redos = LAYOUT.editHistory.redo()
  let redo_len = redos?.length
  if (!redo_len) return
  if (redo_len) {
    recordCplineConnectPorts(LAYOUT.schema.cp_lines, LAYOUT.schema)
    let order = { add: 0, delete: 1, transform: 2, mirror: 3, property: 4, text: 5 }
    redos.sort((a, b) => order[a.action] - order[b.action])

    for (let i = 0; i < redo_len; i++) {
      redoHistorys(LAYOUT, redos[i])
    }
    LAYOUT.resetCheckStatus()
    LAYOUT.updateEditCpLine()
    // autoConnectPort(LAYOUT)
    LAYOUT.stage.render()
  }
  return
}

//切换绘制状态
export function switchDrawFunc(data) {
  // if (this.stage.checked_objs.length) {
  //   // if (this.stage.rotat_center && this.axis.left_drag) {
  //   if (this.axis.left_drag) {
  //     this.axis.left_drag = false
  //     this.undo()
  //   } else if (this.stage.moveMod === 1 && this.stage.movePosition.length) {
  //     this.stage.moveMod = 0
  //     this.stage.movePosition = []
  //     this.undo()
  //   }
  // }
  // this.resetBoardStatus()
  // this.stage.rotation = 0
  // this.stage.rotat_center = null
  // this.last_mouse_pos = null
  // this.drawType = data
  // this.check_able = data === 'selectBox'
  // if (this.check_able && this.stage.moveMod === 0) {
  //   // bus.$emit('cancelSidebarSelect')
  // }
  // if (!this.check_able) {
  //   //重置所有选中物体状态
  //   let length = this.stage.checked_objs.length
  //   for (let i = 0; i < length; i++) {
  //     this.stage.checked_objs[i].resetStatus()
  //   }
  //   this.stage.render()
  // }
  // if (this.drawingObject) {
  //   // this.drawingObject.delete()
  //   this.deleteGraphic(this.drawingObject)
  //   this.drawingObject = null
  //   this.stage.render()
  // }
}

//生成标签
export function generateLabel(keyPoint = false, layer, txt, font_size, dirextion, createLabel) {
  // let mouse_pos = this.getMousePostion()
  // let pos = [mouse_pos.x, mouse_pos.y]
  // // let font_size = 30
  // let QDATA = new QGdstk.Label(txt, pos, dirextion, 0, 1, false, layer, 0)
  // // alert(layer)
  // if (keyPoint) {
  //   QDATA.layer = 0
  //   QDATA.text = txt
  //   QDATA.anchor = 's'
  //   let data = {
  //     create_label: createLabel,
  //   }
  //   QDATA.set_gds_property(1, JSON.stringify(data)) //设置关键点属性 存放关键点名称, 是否创建标签属性
  // }
  // // let params = {
  // //   font_size,
  // //   rotation: 0,
  // // }
  // QDATA.set_gds_property(0, JSON.stringify(font_size))
  // let label = QedaSchemaGraphics.drawLabel(getLabelBox(QDATA), false, QDATA)
  // label.layer = layer
  // this.preAddObj = label
  // this.preAddObj.preAdd = true
  // // this.switchDrawFunc('selectBox')
  // this.addGraphic(label)
  // this.copyLabelData = QedaSchemaGraphics.copy(label, label.position)
  // // this.editHistory.record([{ action: 'add', cellName: this.stage.getCurrentCellName(), objs: [label] }])
}

//重置画板状态
export function resetBoardStatus() {
  // if (this.boardMode === 6) {
  //   if (this.copyData) {
  //     this.stage.preCutObjs.forEach(obj => (obj.preCut = false))
  //     this.undo()
  //   }
  // }
  // this.stage.pre_checked_obj = null
  // this.stage.resetStatus()
  // this.boardMode = this.getCurrentboardMode()
  // this.isCut = false
  // this.copyData = null
  // this.pre_copy_data = null
  // this.copyDataCenter = null
  // this.deletePreAddCell()
}
//复制数据
// export function copyObjs(objs) {
//   // const data_size = objs.length
//   // const copy_data = []
//   // for (let j = 0; j < data_size; j++) {
//   //   const copy = QedaSchemaGraphics.copy(objs[j], objs[j].position)
//   //   copy_data.push(copy)
//   // }
//   // return copy_data
// }

export function lockFile(fileInfo) {
  // let lock = lockCell_api({ file_snow_id: fileInfo.fileId })
  // lock.then(data => {
  //   if (data.code === 200000) {
  //     this.viewMode = false
  //   } else {
  //     // Message.warning(data.message)
  //     this.viewMode = true
  //   }
  // })
}

//自适应屏幕
export function adaptScreen(LAYOUT) {
  LAYOUT.updateCheckedObjsAABB()
  LAYOUT.graphicsAABB = LAYOUT.schema.bounding_box
  if (!LAYOUT.checkedGraphicsAABB && !LAYOUT.graphicsAABB) return
  LAYOUT.adaptAABB(LAYOUT.checkedGraphicsAABB ? LAYOUT.checkedGraphicsAABB : LAYOUT.graphicsAABB)
}

//添加耦合线
export function addCpLine(LAYOUT, params) {
  // let schema = LAYOUT.schema
  // let line = new CpLine()
  // schema.add(line)
}

//添加PIN
export function addPin(LAYOUT, params) {
  // let schema = LAYOUT.schema
  // let pin = new Pin()
  // LAYOUT.add(pin)
}

//添加标签
export function addLabel(LAYOUT, params) {
  let schema = LAYOUT.schema
  var label = new Kernel.Label()
  let x = Math.random() * 100
  let y = Math.random() * 100
  label.pos = [x, y]
  label.text = `标签${Math.random()}`
  schema.labels.push(label)
}

//添加符号
export function addSymbol(LAYOUT, params) {
  // let schema = LAYOUT.schema
  // let symbol = new symbol()
  // schema.add(symbol)
}

//记录历史
export function recordHistory(LAYOUT, type, func) {
  // //记录单个物体属性
  // if (type === 'property') {
  //   let from = []
  //   let to = []
  //   let qdata = null
  //   if (obj.QDATA?.$$?.ptrType.name === 'Label*') {
  //     qdata = obj.QDATA.copy()
  //   }
  //   from.push({
  //     type: obj.type,
  //     transform: obj.copyTransform(),
  //     local_points: obj.gds_data_local.get_points(),
  //     r: [obj.r[0], obj.r[1]],
  //     width: obj.width,
  //     radius: obj.radius,
  //     layer: obj.layer,
  //     QDATA: qdata,
  //   })
  //   to.push(null)
  //   this.editHistory.record([{ action: type, cellName: this.cell.name, objs: [obj], from: from, to: to }])
  //   return
  // }
  LAYOUT.checked_objs = LAYOUT.checked_objs.sort(obj => recordOrder[obj.$$?.ptrType.name] - recordOrder[obj.$$?.ptrType.name])
  let len = LAYOUT.checked_objs.length
  let cpls = [] //相关联的线
  let labels = [] //相关联的标签
  if (type === 'transform') {
    LAYOUT.checked_objs.forEach(obj => {
      if (obj instanceof Kernel.Pin || obj instanceof Kernel.SymbolIns) {
        for (let i = 0; i < obj.ports.length; i++) {
          let port = obj.ports[i]
          let res = LAYOUT.schema.query_port(port)
          res.forEach(item => {
            if (!item[0].js_obj.checked) {
              cpls.push(item[0])
            }
          })
        }
      }
      if (obj.$$?.ptrType.name === 'CpLine*') {
        for (let j = 0; j < obj.labels.length; j++) {
          const label = obj.labels[j]
          if (!label.js_obj.checked) {
            labels.push(label)
          }
        }
      }
    })
    cpls = Array.from(new Set(cpls))
  }
  if (!len) return
  let targets = []
  let from = []
  let to = []
  for (let i = 0; i < len; i++) {
    let obj = LAYOUT.checked_objs[i]
    if (type === 'transform') {
      // if (obj.$$?.ptrType.name === 'Label*' && obj.belong_to?.$$?.ptrType.name === 'Pin*' && LAYOUT.checked_objs.filter(item => item.$$.ptr == obj.belong_to.$$.ptr).length) {
      //   continue
      // }
      targets.push(obj)
      if (obj instanceof Kernel.CpLine) {
        from.push({ pos: deepClone(obj.line.points) }) //拷贝所有点坐标
      } else {
        from.push({ pos: [obj.pos[0], obj.pos[1]], angle: obj.angle }) //拷贝pos rot
      }
      to.push(null)
    } else if (type === 'shape') {
      // targets.push(obj)
      // from.push({
      //   //拷贝数据
      //   type: obj.type,
      //   transform: obj.copyTransform(),
      //   local_points: obj.gds_data_local.get_points(),
      //   r: [obj.r[0], obj.r[1]],
      //   width: obj.width,
      //   radius: obj.radius,
      // })
      // to.push(null)
    }
  }
  cpls.forEach(cpl => {
    targets.push(cpl)
    from.push({ pos: deepClone(cpl.line.points) }) //拷贝所有点坐标
    to.push(null)
  })
  labels.forEach(label => {
    targets.push(label)
    from.push({ pos: [label.pos[0], label.pos[1]], angle: label.angle }) //拷贝pos rot
    to.push(null)
  })
  LAYOUT.editHistory.record([{ action: type, schema: LAYOUT.schema, objs: targets, from: from, to: to }])
}

//撤销
function undoHistorys(LAYOUT, history) {
  let undo_objs = history.objs
  let action_objs_len = undo_objs.length
  let isDelete = history.action === 'delete'
  let isAdd = history.action === 'add'
  let isTransform = history.action === 'transform'
  let isMirror = history.action === 'mirror'
  let isPropertyUpdate = history.action === 'property'
  let isTextUpdate = history.action === 'text'

  const schema = history.schema
  undo_objs.forEach(obj => {
    if (obj.$$?.ptrType.name == 'Label*') {
      obj.js_obj.recordPos = obj.pos
    }
    // if (obj.$$?.ptrType.name == 'CpLine*') {
    //   obj.js_obj.recordPoints = obj.line.points
    // }
  })

  for (let i = 0; i < action_objs_len; i++) {
    let obj = undo_objs[i]
    if (obj.isRefBox) {
      editRef = true
    }
    if (isDelete) {
      //判断反向操作 delete->add add->delete
      LAYOUT.add(obj, schema)
    } else if (isAdd) {
      LAYOUT.delete(obj, schema)
    } else if (isTransform) {
      if (obj instanceof Kernel.CpLine) {
        history.to[i] = { pos: deepClone(obj.line.points) } //拷贝所有点坐标
        obj.line.swap(deepClone(history.from[i].pos))
      } else {
        // if (obj.$$?.ptrType.name === 'Label*') {
        //   let parent = obj.belong_to
        //   if (parent && parent.$$?.ptrType.name === 'Pin*' && undo_objs.filter(item => item.$$.ptr == parent.$$.ptr).length) {
        //     continue
        //   }
        // }
        if (obj.js_obj.recordPos) {
          history.to[i] = { pos: [obj.js_obj.recordPos[0], obj.js_obj.recordPos[1]], angle: obj.angle } //撤销前记录当前位置
        } else {
          history.to[i] = { pos: [obj.pos[0], obj.pos[1]], angle: obj.angle } //撤销前记录当前位置
        }

        obj.angle = history.from[i].angle
        obj.pos = history.from[i].pos
      }

      buildObjPath(obj, LAYOUT.config)
      LAYOUT.quadTree.updateNode(obj)
    } else if (isMirror) {
      let params = history.from
      if (obj instanceof Kernel.CpLine) {
        // obj.line.mirror(params[0], params[1])
        history.toDatas[i] = obj.line.points
        obj.line.swap(history.fromDatas[i])
      } else {
        if (obj.$$?.ptrType.name === 'Pin*') {
          if (undo_objs.filter(item => item.$$.ptr == obj.name.$$.ptr).length) {
            mirrorObj(obj.name, params)
          }
        }
        mirrorObj(obj, params)
      }
      buildObjPath(obj, LAYOUT.config)
      LAYOUT.quadTree.updateNode(obj)
    } else if (isPropertyUpdate) {
      let from = history.from[i]
      let nowProperty = {}
      if (obj.$$?.ptrType.name == 'CpLine*') {
        nowProperty.color = obj.color
        nowProperty.net_name = obj.net_name
        obj.color = from.color
        obj.net_name = from.net_name
        obj.js_obj.rgb = hexToRgb(obj.color).map(v => v / 255)
      } else if (obj.$$?.ptrType.name == 'Label*') {
        nowProperty.color = obj.color
        nowProperty.net_name = obj.net_name
        nowProperty.anchor = obj.anchor
        nowProperty.size = obj.size
        nowProperty.font = obj.font
        nowProperty.text = obj.text
        obj.color = from.color
        obj.net_name = from.net_name
        obj.anchor = from.anchor
        obj.size = from.size
        obj.font = from.font
        obj.text = from.text

        buildObjPath(obj, LAYOUT.config)
        LAYOUT.quadTree.updateNode(obj)
      } else if (obj.$$?.ptrType.name == 'Pin*') {
        nowProperty.name = obj.name.text
        nowProperty.io = obj.io
        obj.name.text = from.name
        obj.io = from.io
        buildObjPath(obj.name, LAYOUT.config)
        LAYOUT.quadTree.updateNode(obj.name)
      } else if (obj.$$?.ptrType.name == 'SymboIns*') {
        nowProperty.ins_name = obj.ins_name
        obj.ins_name = from.ins_name
      }

      history.to[i] = nowProperty
    } else if (isTextUpdate) {
      if (obj.$$?.ptrType.name == 'CpLine*') {
        obj.net_name = history.from[i]
      } else {
        obj.text = history.from[i]
        buildObjPath(obj, LAYOUT.config)
        LAYOUT.quadTree.updateNode(obj)
      }
    }
  }
  setTimeout(() => {
    updateLinePointsByPorts(LAYOUT, undo_objs, schema)
  })
}
//恢复
function redoHistorys(LAYOUT, history) {
  let redo_objs = history.objs
  // let historyOrder = {
  //   'CpLine*': 0,
  //   'Pin*': 1,
  //   'SymbolIns*': 2,
  //   'Label*': 3,
  //   undefined: 4,
  // }
  // redo_objs.sort((a, b) => historyOrder[a.$$?.ptrType.name] - historyOrder[b.$$?.ptrType.name])
  let action_objs_len = redo_objs.length
  let isDelete = history.action === 'delete'
  let isAdd = history.action === 'add'
  let isTransform = history.action === 'transform'
  let isMirror = history.action === 'mirror'
  let isPropertyUpdate = history.action === 'property'
  let isTextUpdate = history.action === 'text'
  const schema = history.schema

  for (let i = 0; i < action_objs_len; i++) {
    let obj = redo_objs[i]
    if (obj.isRefBox) {
      editRef = true
    }
    if (isAdd) {
      LAYOUT.add(obj, schema)
    } else if (isDelete) {
      LAYOUT.delete(obj, schema)
    } else if (isTransform) {
      if (obj instanceof Kernel.CpLine) {
        obj.line.swap(history.to[i].pos)
      } else {
        // if (obj.$$?.ptrType.name === 'Label*') {
        //   let parent = obj.belong_to
        //   if (parent && parent.$$?.ptrType.name === 'Pin*' && redo_objs.filter(item => item.$$.ptr == parent.$$.ptr).length) {
        //     continue
        //   }
        // }
        let transform = history.to[i]
        obj.angle = transform.angle
        obj.pos = transform.pos
      }

      buildObjPath(obj, LAYOUT.config)
      LAYOUT.quadTree.updateNode(obj)
    } else if (isMirror) {
      let params = history.from
      if (obj instanceof Kernel.CpLine) {
        obj.line.mirror(params[0], params[1])
        obj.line.swap(history.toDatas[i])
      } else {
        if (obj.$$?.ptrType.name === 'Pin*') {
          if (redo_objs.filter(item => item.$$.ptr == obj.name.$$.ptr).length) {
            mirrorObj(obj.name, params)
          }
        }
        mirrorObj(obj, params)
      }
      buildObjPath(obj, LAYOUT.config)
      LAYOUT.quadTree.updateNode(obj)
    } else if (isPropertyUpdate) {
      let to = history.to[i]
      if (obj.$$?.ptrType.name == 'CpLine*') {
        obj.color = to.color
        obj.net_name = to.net_name
        obj.js_obj.rgb = hexToRgb(obj.color).map(v => v / 255)
      } else if (obj.$$?.ptrType.name == 'Label*') {
        obj.color = to.color
        obj.net_name = to.net_name
        obj.anchor = to.anchor
        obj.size = to.size
        obj.font = to.font
        obj.text = to.text
        buildObjPath(obj, LAYOUT.config)
        LAYOUT.quadTree.updateNode(obj)
      } else if (obj.$$?.ptrType.name == 'Pin*') {
        obj.name.text = to.name
        obj.io = to.io
        buildObjPath(obj.name, LAYOUT.config)
        LAYOUT.quadTree.updateNode(obj.name)
      } else if (obj.$$?.ptrType.name == 'SymboIns*') {
        obj.ins_name = to.ins_name
      }
    } else if (isTextUpdate) {
      if (obj.$$?.ptrType.name == 'CpLine*') {
        obj.net_name = history.to[i]
      } else {
        obj.text = history.to[i]
        buildObjPath(obj, LAYOUT.config)
        LAYOUT.quadTree.updateNode(obj)
      }
    }
  }
  setTimeout(() => {
    updateLinePointsByPorts(LAYOUT, redo_objs, schema)
  })
}

//旋转
export function rotateObjs(LAYOUT, flag) {
  let len = LAYOUT.checked_objs.length
  let newtLables = []
  let checkedCplines = []
  let a = (flag * (90 * Math.PI)) / 180 //弧度
  if (len) {
    LAYOUT.record('transform')
    LAYOUT.updateCheckedObjsAABB()
    let rotat_center = LAYOUT.checkedGraphicsAABB_center

    // alert(rotat_center)
    if (len == 1) {
      //旋转单个物体
      let obj = LAYOUT.checked_objs[0]
      if (obj instanceof Kernel.CpLine) {
        checkedCplines.push(obj)
        const temp = new QGdstk.Polygon(obj.line.points)
        temp.rotate(a, rotat_center)
        obj.line.swap(fixedPoints(temp.get_points()))
        for (let k = 0; k < obj.labels.length; k++) {
          const netLabel = obj.labels[k]
          if (netLabel.js_obj.checked) continue
          netLabel.rotate(a, rotat_center, false)
          buildObjPath(netLabel, LAYOUT.config)
          LAYOUT.quadTree.updateNode(netLabel)
          newtLables.push(netLabel)
        }
      } else {
        obj.angle += a
        obj.pos = fixedPoints(obj.pos)
      }

      buildObjPath(obj, LAYOUT.config)
      LAYOUT.quadTree.updateNode(obj)
    } else {
      //旋转多个物体
      LAYOUT.checked_objs.forEach(obj => {
        if (obj instanceof Kernel.CpLine) {
          checkedCplines.push(obj)
          const temp = new QGdstk.Polygon(obj.line.points)
          temp.rotate(a, rotat_center)
          obj.line.swap(fixedPoints(temp.get_points()))
          for (let k = 0; k < obj.labels.length; k++) {
            const netLabel = obj.labels[k]
            if (netLabel.js_obj.checked) continue
            netLabel.rotate(a, rotat_center, false)
            buildObjPath(netLabel, LAYOUT.config)
            LAYOUT.quadTree.updateNode(netLabel)
            newtLables.push(netLabel)
          }
        } else {
          let rotateFlag = true
          if (obj.$$?.ptrType.name === 'Label*') {
            let parent = obj.belong_to
            if (parent && parent.$$?.ptrType.name === 'Pin*' && parent.js_obj.checked) {
              rotateFlag = false
            }
          }
          if (rotateFlag) {
            if (obj.$$?.ptrType.name === 'SymbolIns*') {
              obj.rotate(a, rotat_center)
            } else {
              obj.rotate(a, rotat_center, false)
            }
            obj.pos = fixedPoints(obj.pos)
          }
        }
        buildObjPath(obj, LAYOUT.config)
        LAYOUT.quadTree.updateNode(obj)
      })
    }
    autoAddCplinePortPoints(LAYOUT, checkedCplines)
    updateLinePointsByPorts(LAYOUT, LAYOUT.checked_objs, LAYOUT.schema)
    mergeNearPoints(checkedCplines)
    // autoConnectPort(LAYOUT)
    LAYOUT.stage.render()
  }
}

//进入下层
export async function enterlowerLevel(LAYOUT) {
  // if (LAYOUT.viewMode) return
  if (LAYOUT.checked_objs.length === 1 && LAYOUT.checked_objs[0]?.symbol instanceof Kernel.CapSymbol) {
    // LAYOUT.checked_objs[0].resetStatus()
    LAYOUT.checked_objs.forEach(obj => {
      obj.js_obj.checked = false
      obj.js_obj.preChecked = false
    })
    let enter_schema = LAYOUT.checked_objs[0].symbol.schema
    if (!enter_schema) {
      return
    }
    let canedit = await LAYOUT.hasCellPermission(enter_schema.snow_id)
    if (!canedit) {
      return
    }
    let canAccess = await LAYOUT.canAccessFile(enter_schema.snow_id)
    if (!canAccess) {
      return
    }
    let history = {
      dx: LAYOUT.axis.dx,
      dy: LAYOUT.axis.dy,
      scale: LAYOUT.axis.scale,
      schema: LAYOUT.schema,
      // quadTree: LAYOUT.quadTree,
    }
    LAYOUT.schemaHistory.push(history) //添加记录
    LAYOUT.checked_objs = []
    LAYOUT.schema = enter_schema
    buildRenderData(enter_schema, LAYOUT.config)
    LAYOUT.quadTree = new SchemaQuadTree(LAYOUT.schema)
    LAYOUT.quadTree.showConfig = LAYOUT.stage.config.showConfig
    LAYOUT.stage.schema = LAYOUT.schema
    autoConnectPort(LAYOUT)
    LAYOUT.reRenderSchema()
  }
}

//返回上层
export async function backUpperLevel(LAYOUT) {
  // if (LAYOUT.viewMode) return
  if (LAYOUT.schemaHistory.length) {
    let lastHistory = LAYOUT.schemaHistory.at(-1)
    // let canAccess = await LAYOUT.canAccessFile(lastHistory.schema.snow_id)
    // if (!canAccess) {

    //   return
    // }
    await forceSaveCells([LAYOUT.schema], LAYOUT, true)
    await LAYOUT.unLockFile([LAYOUT.schema]) //解锁当前文件
    let history = LAYOUT.schemaHistory.pop() //读取记录
    LAYOUT.switchBoardMode(BoardMode.SELECT)
    LAYOUT.schema = history.schema
    buildRenderData(LAYOUT.schema, LAYOUT.config)
    LAYOUT.stage.schema = LAYOUT.schema
    LAYOUT.axis.scale = history.scale
    LAYOUT.axis.dx = history.dx
    LAYOUT.axis.dy = history.dy
    LAYOUT.checked_objs.forEach(obj => {
      obj.js_obj.preChecked = false
      obj.js_obj.checked = false
      obj.js_obj.preCut = false
    })
    LAYOUT.checked_objs = []
    LAYOUT.quadTree = new SchemaQuadTree(LAYOUT.schema)
    LAYOUT.quadTree.showConfig = LAYOUT.stage.config.showConfig
    LAYOUT.reRenderBoard()
  }
}

//返回最上层
export async function backTop(LAYOUT) {
  // if (LAYOUT.viewMode) return
  if (LAYOUT.schemaHistory.length) {
    let history = LAYOUT.schemaHistory[0] //读取第一条记录
    // let canAccess = await LAYOUT.canAccessFile(history.schema.snow_id)
    // if (!canAccess) {

    //   return
    // }
    let needForceSaveCells = [LAYOUT.schema]
    LAYOUT.schemaHistory.forEach((history, i) => {
      if (i) {
        needForceSaveCells.push(history.schema)
      }
    })
    await forceSaveCells(needForceSaveCells, LAYOUT, true)
    // await LAYOUT.unLockFile(needForceSaveCells) //解锁当前文件
    LAYOUT.switchBoardMode(BoardMode.SELECT)
    LAYOUT.schema = history.schema
    buildRenderData(LAYOUT.schema, LAYOUT.config)
    LAYOUT.stage.schema = LAYOUT.schema
    LAYOUT.axis.scale = history.scale
    LAYOUT.axis.dx = history.dx
    LAYOUT.axis.dy = history.dy
    LAYOUT.checked_objs.forEach(obj => {
      obj.js_obj.preChecked = false
      obj.js_obj.checked = false
      obj.js_obj.preCut = false
    })
    LAYOUT.schemaHistory = []
    LAYOUT.checked_objs = []
    LAYOUT.quadTree = new SchemaQuadTree(LAYOUT.schema)
    LAYOUT.quadTree.showConfig = LAYOUT.stage.config.showConfig
    LAYOUT.reRenderBoard()
  }
}

//强制保存器件
async function forceSaveCells(cells, LAYOUT, needUnLock = false) {
  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i]
    const cell_gns = getCellGns(cell, LAYOUT)
    await saveCellFn(cell.snow_id, cell_gns, needUnLock)
  }
}

function getCellGns(cell, LAYOU) {
  const topInfo = LAYOU.topInfo
  const projectInfo = LAYOU.projectInfo
  const fileInfo = LAYOU.fileInfo
  if (topInfo.teamId) {
    return `gns://${topInfo.teamId}/${projectInfo.projectId}/${fileInfo.fileId}/${cell.snow_id}`
  } else {
    return `gns://${projectInfo.projectId}/${fileInfo.fileId}/${cell.snow_id}`
  }
}
