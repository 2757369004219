import { BoardMode, resizeLayer, getCtLabelText, buildLabelBox, buildPinPath, buildSymbolPath, buildCpLinePath, newSymbolName, newShortName, deepFileRename, drawLinePoint, buildRenderData, SchemaQuadTree } from './layout-util'
import { addCpLine, addPin, addLabel, addSymbol, rotateObjs, flip, alignmentObjs, enterlowerLevel, backUpperLevel, backTop, arrayObjs, undo, redo, copyOP, pasteData } from './layout-functions'
import { downloadFileData } from '@/components/homes/fileList/function/objectStorageFn'
import { binaryDataToUTF8 } from '@/components/homes/fileList/function/fileListPublicFn'
import { object } from 'underscore'
//原理图画板添加监听事件
export function addEventListener(LAYOUT, bus) {
  resizeLayer(LAYOUT)
  //页面缩放
  bus.$on('resizeLayer', data => {
    resizeLayer(LAYOUT)
  })

  if (!LAYOUT.cellInfo.isReadOnly) {
    // 定时器锁文件
    if (LAYOUT.fileInfo.teamRule !== undefined && !LAYOUT.lockFileTimer) {
      let that = LAYOUT
      LAYOUT.lockFileTimer = setInterval(() => {
        that.lockFile()
      }, 30000) //每60秒一次
      LAYOUT.lockFile()
    }
  }
  LAYOUT.canvas.addEventListener('mousewheel', LAYOUT.mouseWheelListener)
  LAYOUT.canvas.addEventListener('mousemove', LAYOUT.mouseMoveListener)
  LAYOUT.canvas.addEventListener('mouseup', LAYOUT.mouseUpListener)
  LAYOUT.canvas.addEventListener('mousedown', LAYOUT.mouseDownListener)
  LAYOUT.canvas.addEventListener('dblclick', LAYOUT.dbClickListener)
  document.addEventListener('keydown', LAYOUT.keyBoardListener)
  document.addEventListener('keyup', LAYOUT.keyUpBoardListener)
  //订阅外部事件
  //切换工具
  // bus.$on('func', data => {
  //   if (LAYOUT.viewMode) return

  //   LAYOUT.switchDrawFunc(data)
  // })

  // //整体选择
  // bus.$on('overallSelection', data => {
  //   if (LAYOUT.viewMode) return
  //   if (LAYOUT.boardMode === 1) {
  //     LAYOUT.stage.checked_objs.forEach(obj => {
  //       obj.switchOverallSelection()
  //     })
  //   }
  //   LAYOUT.switchDrawFunc('selectBox')
  //   LAYOUT.boardMode = 0
  //   LAYOUT.stage.resetStatus()
  //   LAYOUT.stage.render()
  // })

  // //部分选择
  // bus.$on('partialSelection', data => {
  //   if (LAYOUT.viewMode) return
  //   if (LAYOUT.boardMode === 0) {
  //     LAYOUT.stage.checked_objs.forEach(obj => {
  //       obj.switchPartialSelection()
  //     })
  //   }
  //   LAYOUT.switchDrawFunc('selectBox')
  //   LAYOUT.boardMode = 1
  //   LAYOUT.stage.resetStatus()
  //   LAYOUT.stage.render()
  // })

  //剪切
  bus.$on('cut', data => {
    if (LAYOUT.viewMode) return
    copyOP(LAYOUT, true)
  })

  //复制
  bus.$on('copy', data => {
    // if (LAYOUT.viewMode) return
    copyOP(LAYOUT, false)
  })

  //粘贴
  bus.$on('paste', data => {
    if (LAYOUT.viewMode) return
    pasteData(LAYOUT)
  })

  //撤销
  bus.$on('undo', data => {
    if (LAYOUT.viewMode) return
    undo(LAYOUT)
  })

  //恢复
  bus.$on('redo', data => {
    if (LAYOUT.viewMode) return
    redo(LAYOUT)
  })

  //移动
  bus.$on('move', data => {
    if (LAYOUT.viewMode) return
    LAYOUT.switchBoardMode(BoardMode.MOVE)
  })

  //顺时针旋转
  bus.$on('RotatedClockwise', data => {
    if (LAYOUT.viewMode) return
    rotateObjs(LAYOUT, -1)
  })
  //逆时针旋转
  bus.$on('RotatedCounterclockwise', data => {
    if (LAYOUT.viewMode) return
    rotateObjs(LAYOUT, 1)
  })
  //水平翻转
  bus.$on('FlipHorizontal', data => {
    if (LAYOUT.viewMode) return
    if (LAYOUT.boardMode !== BoardMode.SELECT) return //整体选择模式才能翻转
    flip(LAYOUT)
  })
  //垂直翻转
  bus.$on('FlipVertical', data => {
    if (LAYOUT.viewMode) return
    if (LAYOUT.boardMode !== BoardMode.SELECT) return //整体选择模式才能翻转
    flip(LAYOUT, 'Y')
  })

  //阵列
  bus.$on('matrix', data => {
    if (LAYOUT.viewMode) return
    data = data.map(num => Number(num))

    arrayObjs(LAYOUT, data)
  })

  //对齐
  bus.$on('alignLeft', data => {
    if (LAYOUT.viewMode) return
    if (LAYOUT.boardMode === BoardMode.SELECT) alignmentObjs(LAYOUT, '左对齐')
  })

  //对齐
  bus.$on('alignRight', data => {
    if (LAYOUT.viewMode) return
    if (LAYOUT.boardMode === BoardMode.SELECT) alignmentObjs(LAYOUT, '右对齐')
  })
  //对齐
  bus.$on('alignTop', data => {
    if (LAYOUT.viewMode) return
    if (LAYOUT.boardMode === BoardMode.SELECT) alignmentObjs(LAYOUT, '上对齐')
  })
  //对齐
  bus.$on('alignBottom', data => {
    if (LAYOUT.viewMode) return
    if (LAYOUT.boardMode === BoardMode.SELECT) alignmentObjs(LAYOUT, '下对齐')
  })
  //对齐
  bus.$on('alignHorizontalCenter', data => {
    if (LAYOUT.viewMode) return
    if (LAYOUT.boardMode === BoardMode.SELECT) alignmentObjs(LAYOUT, '横向中线对齐')
  })
  //对齐
  bus.$on('alignVerticalCenter', data => {
    if (LAYOUT.viewMode) return
    if (LAYOUT.boardMode === BoardMode.SELECT) alignmentObjs(LAYOUT, '竖向中线对齐')
  })
  //进入下层
  bus.$on('enterlowerLevel', data => {
    // if (LAYOUT.viewMode) return
    enterlowerLevel(LAYOUT)
  })
  //返回上层
  bus.$on('backUpperLevel', data => {
    // if (LAYOUT.viewMode) return
    backUpperLevel(LAYOUT)
  })

  //返回最上层
  bus.$on('backTop', data => {
    // if (LAYOUT.viewMode) return
    backTop(LAYOUT)
  })

  // // 添加器件
  // bus.$on('addDevice', res => {
  //   if (LAYOUT.viewMode) return
  // })

  // //吸附
  // bus.$on('adsorption', data => {
  //   LAYOUT.axis.adsorpPoint = data
  // })

  //画板放大
  bus.$on('boardAmplification', data => {
    let e = { deltaY: -1 }
    LAYOUT.onMouseWheel(e, true)
  })
  //画板缩小
  bus.$on('boardReduction', data => {
    let e = { deltaY: 1 }
    LAYOUT.onMouseWheel(e, true)
  })
  //画板自适应
  bus.$on('boardAdaptation', data => {
    let e = { key: 'f' }
    LAYOUT.keyDown(e)
  })
  //自适应
  bus.$on('objectAdaptation', data => {
    let e = { key: 'f' }
    LAYOUT.keyDown(e)
  })
  //区域放大
  bus.$on('regionalAmplification', data => {
    LAYOUT.switchBoardMode(BoardMode.SELECT)
    LAYOUT.drawType = 'adaptRect'
  })

  //打开画板设计
  bus.$on('boardOption', data => {
    let obj = {
      backgroundColor: LAYOUT.stage.backgroundColor,
      gridColor: LAYOUT.stage.gridColor,
      showgrid: LAYOUT.stage.showGrid,
      adsorption: LAYOUT.axis.adsorp,
      adsorp_precision: LAYOUT.axis.adsorp_precision,
    }
    bus.$emit('openBoardOption', obj)
  })

  //接收画板设计参数
  bus.$on('boardDesign', data => {
    LAYOUT.stage.config.colors.backgroundColor = data.value_backgroundColor
    LAYOUT.stage.config.colors.gridColor = data.value_gridColor
    LAYOUT.stage.config.showGrid = data.gridVisible === '1'
    LAYOUT.stage.config.gridSize = data.gridSize
    // LAYOUT.axis.adsorp = Number(10)
    //LAYOUT.axis.gridSize = showGrid(data.gridSize)
    let p = parseFloat(data.adsorption)
    // if (p == 0) p = 0.01
    LAYOUT.axis.adsorp_precision = p
    LAYOUT.stage.render()
  })

  //隐藏显示标签
  bus.$on('displayLabel', data => {
    let config = LAYOUT.stage.config
    config.showConfig.instanceText = data.showCoordinateInstanceName
    config.showConfig.symbolText = data.showCoordinateLabelName
    config.showConfig.parameterText = data.showCoordinateKeyParameter
    config.showConfig.pinName = data.showCoordinatePinName
    config.showConfig.portName = data.showCoordinatePortName
    config.showConfig.netName = data.showCoordinateNetLable
    config.showConfig.normalLabel = data.showCoordinateOtherLabel

    for (let i = 0; i < LAYOUT.checked_objs.length; i++) {
      const obj = LAYOUT.checked_objs[i]

      if (!config.showConfig.pinName && obj.belong_to?.$$?.ptrType.name === 'Pin*') {
        obj.js_obj.checked = false
        LAYOUT.checked_objs.splice(i--, 1)
      }
      if (!config.showConfig.netName && obj.belong_to?.$$?.ptrType.name === 'CpLine*') {
        obj.js_obj.checked = false
        LAYOUT.checked_objs.splice(i--, 1)
      }
      if (!config.showConfig.normalLabel && obj.$$?.ptrType.name === 'Label*') {
        obj.js_obj.checked = false
        LAYOUT.checked_objs.splice(i--, 1)
      }
    }
    LAYOUT.stage.render()
  })

  // //获取吸附精度
  // bus.$on('getPrecision', data => {
  //   LAYOUT.axis.adsorp_precision = parseFloat(data)
  // })

  //创建耦合线
  bus.$on('addLine', data => {
    if (LAYOUT.viewMode) return
    let angleMap = {
      '90°': 90,
      '135°': 45,
      '0°': 0,
    }
    let beforeAngle = LAYOUT.lineAngle
    LAYOUT.lineAngle = angleMap[data.couplingLineAngle]
    LAYOUT.lineColor = data.couplingLineColor
    if (LAYOUT.boardMode === BoardMode.CP_LINE) {
      if (LAYOUT.drawingObject) {
        LAYOUT.drawingObject.color = LAYOUT.lineColor
        if (beforeAngle == 0 && LAYOUT.lineAngle !== beforeAngle) {
          //任意角度切换至90或135度
          const currentPoints = LAYOUT.drawingObject.line.points
          if (currentPoints.length > 1) {
            currentPoints.pop()
            LAYOUT.drawingObject.line.swap(currentPoints)
          }
          drawLinePoint(LAYOUT, LAYOUT.drawingObject, LAYOUT.getMousePosArray(), LAYOUT.latestConfirmPos, LAYOUT.lineMod, LAYOUT.lineAngle)
        } else if (LAYOUT.lineAngle == 0 && beforeAngle && LAYOUT.lineAngle !== beforeAngle) {
          //90或135度切换至任意角度
          const currentPoints = LAYOUT.drawingObject.line.points
          if (currentPoints.length > 1) {
            currentPoints.push(LAYOUT.getMousePosArray())
            LAYOUT.drawingObject.line.swap(currentPoints)
          }
          drawLinePoint(LAYOUT, LAYOUT.drawingObject, LAYOUT.getMousePosArray(), LAYOUT.latestConfirmPos, LAYOUT.lineMod, LAYOUT.lineAngle)
        }
        buildCpLinePath(LAYOUT.drawingObject)
      }
    } else {
      LAYOUT.switchBoardMode(BoardMode.CP_LINE)
      LAYOUT.newCpLine()
    }
    LAYOUT.stage.render()
  })
  //创建PIN
  bus.$on('addPin', data => {
    if (LAYOUT.viewMode) return
    if (data.pinName.split(',').filter(text => text == '').length) return //输入','bug
    LAYOUT.switchBoardMode(BoardMode.PIN)
    LAYOUT.newPin(data.pinName, data.pinType)
  })

  //调用封装符号 同文件调用
  bus.$on('addSelfSymbol', data => {
    if (LAYOUT.viewMode) return
    LAYOUT.switchBoardMode(BoardMode.SYMBOL)
    data.ins_name = data.symbol.short_name
    LAYOUT.newSymbol(data)
    // bus.$emit('updateFileTree', true)
  })

  //添加符号 不同文件调用
  bus.$on('addNewSymbol', data => {
    if (LAYOUT.viewMode) return
    LAYOUT.switchBoardMode(BoardMode.SYMBOL)

    LAYOUT.newSymbol(data)
    // let names = []
    // let cap_symbols = LAYOUT.file.capsymbol_ins
    // let cap_symbols_size = LAYOUT.file.capsymbol_ins.length
    // for (let i = 0; i < cap_symbols_size; i++) {
    //   const symbol = cap_symbols[i]
    //   names.push(symbol.name)
    // }
    // let symbols = LAYOUT.file.symbols
    // let symbols_size = LAYOUT.file.symbols.length
    // for (let i = 0; i < symbols_size; i++) {
    //   const symbol = symbols[i]
    //   names.push(symbol.name)
    // }
    // let schemas = LAYOUT.file.schemas
    // let schemas_size = LAYOUT.file.schemas.length

    // for (let i = 0; i < schemas_size; i++) {
    //   const schema = schemas[i]
    //   names.push(schema.name)
    // }
    // //官方符号
    // if (data.symbol.is_official_symbol) {
    //   data.symbol.name = newSymbolName(data.symbol.name, names)
    //   data.symbol.short_name = newShortName(data.symbol.name, data.symbol.short_name)
    //   data.ins_name = data.symbol.short_name

    //   LAYOUT.newSymbol(data)
    // } else {
    //   //封装符号
    //   data.ins_name = data.symbol.short_name
    //   deepFileRename(data, names)
    //   LAYOUT.newSymbol(data)
    // }
  })

  //创建标签
  bus.$on('addLabel', data => {
    if (LAYOUT.viewMode) return
    let fontFamilyMap = {
      黑体: 'heiti',
      宋体: 'songti',
      heiti: 'heiti',
      songti: 'songti',
    }

    if (!FONT[fontFamilyMap[data.fontFamily]]) {
      return
    }
    LAYOUT.switchBoardMode(BoardMode.LABEL)
    let directionMap = {
      o: Kernel.Anchor.Centra,
      n: Kernel.Anchor.Up,
      s: Kernel.Anchor.Down,
      w: Kernel.Anchor.Left,
      e: Kernel.Anchor.Right,
      nw: Kernel.Anchor.LeftUp,
      ne: Kernel.Anchor.RightUp,
      sw: Kernel.Anchor.LeftDown,
      se: Kernel.Anchor.RightDown,
    }
    LAYOUT.newLabel(data.formText, fontFamilyMap[data.fontFamily], directionMap[data.referencePoint], Number(data.formWeight), data.color, data.networkLabel == 1)
  })

  //高亮网络
  bus.$on('HighlightNetwork', data => {
    bus.$emit('netHighlight', true)
    LAYOUT.switchBoardMode(BoardMode.HL_NET)
    return
  })

  //移除高亮
  bus.$on('RemoveHighlights', data => {
    bus.$emit('moveHighlight', true)
    LAYOUT.switchBoardMode(BoardMode.RM_NET)
    return
  })

  //记录原理图对象属性
  bus.$on('recordSchemaProperty', data => {
    let records = []
    let from = []
    let to = []
    for (let i = 0; i < data.length; i++) {
      const obj = data[i]
      const nowProperty = {}
      if (obj.$$?.ptrType.name == 'CpLine*') {
        nowProperty.color = obj.color
        nowProperty.net_name = obj.net_name
      } else if (obj.$$?.ptrType.name == 'Label*') {
        nowProperty.color = obj.color
        nowProperty.net_name = obj.net_name
        nowProperty.anchor = obj.anchor
        nowProperty.size = obj.size
        nowProperty.font = obj.font
        nowProperty.text = obj.text
      } else if (obj.$$?.ptrType.name == 'Pin*') {
        nowProperty.name = obj.name.text
        nowProperty.io = obj.io
      } else if (obj.$$?.ptrType.name == 'SymboIns*') {
        nowProperty.ins_name = obj.ins_name
      }
      records.push(obj)
      from.push(nowProperty)
      to.push(null)
    }
    LAYOUT.editHistory.record([{ action: 'property', schema: LAYOUT.schema, objs: records, from: from, to: to }])
    return
  })

  //检查原理图
  bus.$on('checkSchema', data => {
    let start = new Date().Format('yyyy-MM-dd hh:mm:ss')
    let hang_net_check_result = data[0].selector != 1 ? LAYOUT.schema.hang_net_check() : {}
    let hang_pin_check_result = data[2].selector != 1 ? LAYOUT.schema.hang_pin_check() : []
    let pin_net_check = data[1].selector != 1 ? LAYOUT.schema.pin_net_check() : []
    let hang_symbol_check_result = data[3].selector != 1 ? LAYOUT.schema.hang_symbol_check() : []

    //test
    // let binarayObj = LAYOUT.schema.dump_file(true, -1)
    // let utf8 = binaryDataToUTF8(binarayObj)
    // downloadFileData(utf8[0].data_u8, 'jiancha', 'txt')
    // let config = data.slice(4).map(obj => {
    //   if (obj.selector != 1) {
    //     return true
    //   } else {
    //     return false
    //   }
    // })
    let symbol_connect_check_result = data[4].selector != 1 ? LAYOUT.schema.symbol_connect_check() : []

    let result = { hang_net_check_result, hang_pin_check_result, pin_net_check, hang_symbol_check_result, symbol_connect_check_result }
    result.start = start
    result.end = new Date().Format('yyyy-MM-dd hh:mm:ss')
    result.schema = LAYOUT.schema
    result.config = data

    bus.$emit('checkSchemaResult', result)
  })

  bus.$on('clickCheckedObj', data => {
    let aabb = data.bounding_box
    if (Array.isArray(data.ptr)) {
      aabb = data.ptr[0].bounding_box
    } else {
      aabb = data.ptr.bounding_box
    }
    LAYOUT.adaptAABB(aabb)
    LAYOUT.stage.clock = 0
    LAYOUT.stage.checkedResBoxs = [aabb]
  })

  bus.$on('reRenderSchemaBoard', data => {
    LAYOUT.reRenderSchemaData()
  })
  bus.$on('reRenderReplaceBoard', data => {
    LAYOUT.reRenderSchemaData()
    LAYOUT.rebuildQuadTree()
  })
  //下层调用器件重新渲染
  bus.$on('notSaveCellReRender', data => {
    if (LAYOUT.schema.snow_id == data) {
      buildRenderData(LAYOUT.schema, LAYOUT.config)
      LAYOUT.quadTree = new SchemaQuadTree(LAYOUT.schema)
      LAYOUT.quadTree.showConfig = LAYOUT.stage.config.showConfig
      LAYOUT.stage.schema = LAYOUT.schema
      LAYOUT.reRenderSchema()
    }
  })
}

//取消监听
export function removeEventListeners(LAYOUT, bus) {
  LAYOUT.canvas.removeEventListener('mousewheel', LAYOUT.mouseWheelListener)
  LAYOUT.canvas.removeEventListener('mousemove', LAYOUT.mouseMoveListener)
  LAYOUT.canvas.removeEventListener('mouseup', LAYOUT.mouseUpListener)
  LAYOUT.canvas.removeEventListener('mousedown', LAYOUT.mouseDownListener)
  LAYOUT.canvas.removeEventListener('dblclick', LAYOUT.dbClickListener)
  document.removeEventListener('keydown', LAYOUT.keyBoardListener)
  document.removeEventListener('keyup', LAYOUT.keyUpBoardListener)
  bus.$off('resizeLayer')
  bus.$off('save')
  bus.$off('cut')
  bus.$off('copy')
  bus.$off('paste')
  bus.$off('undo')
  bus.$off('redo')
  bus.$off('move')
  bus.$off('RotatedClockwise')
  bus.$off('RotatedCounterclockwise')
  bus.$off('alignVerticalCenter')
  bus.$off('FlipHorizontal')
  bus.$off('FlipVertical')
  bus.$off('matrix')
  bus.$off('alignLeft')
  bus.$off('alignRight')
  bus.$off('alignTop')
  bus.$off('alignBottom')
  bus.$off('alignHorizontalCenter')
  bus.$off('alignVerticalCenter')
  bus.$off('enterlowerLevel')
  bus.$off('backUpperLevel')
  bus.$off('backTop')
  bus.$off('boardAmplification')
  bus.$off('boardReduction')
  bus.$off('boardAdaptation')
  bus.$off('objectAdaptation')
  bus.$off('regionalAmplification')
  bus.$off('boardOption')
  bus.$off('boardDesign')
  bus.$off('displayLabel')
  bus.$off('addLine')
  bus.$off('addPin')
  bus.$off('addNewSymbol')
  bus.$off('addLabel')
  bus.$off('addSelfSymbol')
  bus.$off('HighlightNetwork')
  bus.$off('RemoveHighlights')
  bus.$off('recordSchemaObjsProperty')
  bus.$off('clickCheckedObj')
  bus.$off('reRenderSchemaBoard')
  bus.$off('reRenderReplaceBoard')
  bus.$off('notSaveCellReRender')
}
